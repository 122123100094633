import moment from "moment";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

function GoldPrice() {
  const Liverate = useSelector((state: any) => state.Liverate);
  return (
    // <div>
    <div className="GoldPrice-cover">
      <Container fluid className="Offers-box">
        <div className="HomeScreen-GoldPrice">
          <div className="HomeScreen_text1">
            TODAY’S RETAIL GOLD JEWELLERY PRICE
          </div>
          <div className="HomeScreen_text2">
            {moment(new Date()).format("DD-MM-YYYY")} - RATE IN AED PER GM
          </div>
          <br />
          <Row className="justify-content-center">
            {Liverate?.liverate?.map((item: any) => (
              <Col xs={12} sm={4} className="col-xl gy-2" key={item?.name}>
                <div className="HomeScreen-GoldPriceBox">
                  {item?.name} -{" "}
                  <b style={{ color: "#b8821f", fontSize: 20 }}>{item?.ask} </b>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </div>
    // </div>
  );
}

export default GoldPrice;
