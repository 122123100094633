import React, { useState } from "react";
import "./styles.scss";
import { Button, Input, Popover, Form, notification } from "antd";
import { AiOutlineMessage } from "react-icons/ai";
import { POST } from "../../utils/apiCalls";
import API from "../../config/API";
import { useTranslation } from "react-i18next";

const FloatingButton = () => {
  const { t } = useTranslation();
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [notificationApi, contextHolder] = notification.useNotification();
  const handlePopoverVisibleChange = (visible: any) => {
    setPopoverVisible(visible);
  };

  const OnFinsh = async (values: any) => {
    try {
      let url = API.ENQUIRY_CREATE;
      let Obj = {
        email: values?.email,
        message: values?.message,
      };
      let response: any = await POST(url, Obj);
      if (response.status) {
        notificationApi.success({ message: `${t("succesfully_added")}` });
        setPopoverVisible(false);
      }
    } catch (error) {
      console.error("error");
    }
  };
  const content = (
    <div className="popoverInnerContainer">
      <Form onFinish={OnFinsh}>
        <Form.Item name="email">
          <Input size="large" placeholder={t("Enter_Email")} required />
        </Form.Item>
        <Form.Item name="message">
          <Input.TextArea
            required
            size="large"
            rows={5}
            placeholder={t("Type_your_message")}
          />
        </Form.Item>
        <div>
          <Button
            type="primary"
            className="send-button"
            htmlType="submit"
            size="large"
            style={{ width: "60%" }}
          >
            {t("Send")}
          </Button>
        </div>
      </Form>
    </div>
  );
  return (
    <div className="floating-button-container">
      {contextHolder}
      <Popover
        title={
          <span className="floating-txt1">
            {t("Chat_with_us")}
          </span>
        }
        className="popover-main"
        placement="topRight"
        content={content}
        trigger="click"
        open={popoverVisible}
        onOpenChange={handlePopoverVisibleChange}
      >
        <button className="floating-button">
          <AiOutlineMessage size={25} />
        </button>
      </Popover>
    </div>
  );
};

export default FloatingButton;
