import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  Space,
  notification,
} from "antd";
import { COMPRESS_IMAGE, GET, PUT } from "../../utils/apiCalls";
import { CorporateRegisterType } from "../../shared/types/types";
import { useSelector } from "react-redux";
import API from "../../config/API";
import AdminLoading from "../components/AdminLoading";
import NoData from "../../components/noData";
import PageHeader from "../components/PageHeader";
import LocationPicker from "../../screens/sellerRegister/component/LocationPicker";
import moment from "moment";
import ImagePicker from "../components/ImagePicker";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import PrefixSelector from "../../components/prefixSelector";

function ManageStore() {
  const [sellerDetails, setSellerDetails] = useState<any>();
  const [location, setLocation] = useState<any>();
  const [Notifications, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const [openPicker, setOpenPicker] = useState(false);
  const [form] = Form.useForm();
  const [page, setPage] = useState<number>(1);
  const [store, setStore] = useState<CorporateRegisterType[]>([]);
  const [file, setFile] = useState<any>(null);
  const [businessType, setBusinessType] = useState([]);
  const Auth = useSelector((state: any) => state.User);
  const fileInputRef = useRef(null);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const cropperRef = useRef<ReactCropperElement>(null);
  const [isImageCropped, setIsImageCropped] = useState(false);

  const getSellerDetails = async (currentPage: number = page) => {
    setLoading(true);
    try {
      const url = API.CORPORATE_STORE_GETSELLERINFO;
      const data: any = await GET(url, null);
      if (data?.status) {
        setSellerDetails(data?.data);
        setLocation({
          lat: data?.data?.lat,
          lng: data?.data?.long,
        });
        setStore(data?.data);
      }
    } catch (err: any) {
      Notifications["error"]({
        message: "Something went wrong",
        description: err.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCrop = async () => {
    if (cropperRef.current) {
      const croppedData = cropperRef.current.cropper
        .getCroppedCanvas({
          fillColor: "#fff",
        })
        .toDataURL();
      setCroppedImage(croppedData);
      setCropModalOpen(false);
      setIsImageCropped(true);
    }
  };

  useEffect(() => {
    loadbusinessType();
  }, []);

  const loadbusinessType = async () => {
    try {
      let url = API.BUSINESS_TYPE;
      let response: any = await GET(url, null);
      if (response.status) {
        setBusinessType(response.data);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const OnFinsh = async (item: any) => {
    try {
      setLoading(true);
      let imageUrl: any;
      if (croppedImage) {
        const ImageBlob = await fetch(croppedImage).then((r) => r.blob());
        let name = moment(new Date()).unix();
        let file = new File([ImageBlob], name + "N.jpg");
        imageUrl = await COMPRESS_IMAGE(file);
      }

      const obj = {
        email: item.email,
        phone: item.phone,
        business_location: `${item.business_location.lat} ${item.business_location.lng}`,
        business_address: item.business_address,
        business_type: item.business_type,
        trn_number: item.trn_number,
        trade_lisc_no: item.trade_lisc_no,
        store_name: item.store_name,
        logo_upload: imageUrl?.url,
        upscs: item.upscs,
        manufacture: item.manufacture,
        status: item.status,
        lat: location.lat,
        long: location.lng,
        business_types: item?.business_types,
      };

      const url = API.CORPORATE_SELLER_UPDATE;
      const response: any = await PUT(url, obj);
      if (response?.status) {
        Notifications["success"]({ message: "Successfully updated" });
      } else {
        Notifications["error"]({ message: response?.message ?? "" });
      }
    } catch (error) {
      Notifications["error"]({ message: "Something went wrong." });
    } finally {
      setLoading(false);
    }
  };
  const array = Array.isArray(businessType)
    ? businessType.map((item: any) => ({
        ...item,
        value: item.name,
      }))
    : [];
  const handleChange = (value: string[]) => {
    console.log(value);
  };
  useEffect(() => {
    getSellerDetails();
  }, []);

  let content;

  if (loading) {
    content = <AdminLoading />;
  } else if (sellerDetails) {
    content = (
      <>
        <div className=" m-3">
          <div className="fw-bold fs-4">Personal Details</div>
          <Form
            form={form}
            onFinish={OnFinsh}
            initialValues={{
              code: "+91",
              email: sellerDetails.email,
              phone: sellerDetails.phone,
              trn_number: sellerDetails.trn_number,
              trade_lisc_no: sellerDetails.trade_lisc_no,
              // business_type: sellerDetails.business_type,
              business_location: `${sellerDetails.lat} ${sellerDetails.long}`,
              business_address: sellerDetails.business_address,
              seller_name: sellerDetails.seller_name,
              store_name: sellerDetails.store_name,
              status: sellerDetails.status,
              manufacture: sellerDetails.manufacture,
              upscs: sellerDetails.upscs,
              logo_upload: sellerDetails.logo_upload,
            }}
          >
            <Row className="mt-2">
              <Col md="4">
                <div className="fw-bold ">Email</div>
                <Form.Item
                  name={"email"}
                  rules={[
                    { required: true, message: "email is required" },
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col md="4">
                <div className="fw-bold ">Phone</div>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Input your phone number!",
                    },
                  ]}
                >
                  <InputNumber
                    addonBefore={<PrefixSelector />}
                    style={{ width: "100%" }}
                    size="large"
                    type="number"
                  />
                </Form.Item>
              </Col>
            </Row>
            <div className="fw-bold mt-3 fs-4">Business Details</div>
            <Row className="mt-2">
              <Col md="4">
                <div className="fw-bold ">Trn Number</div>
                <Form.Item
                  name="trn_number"
                  rules={[
                    {
                      required: true,
                      message: "Trn number is required",
                    },
                  ]}
                >
                  <Input size="large" disabled />
                </Form.Item>
              </Col>
              <Col md="4">
                <div className="fw-bold ">Trade Liscence No</div>
                <Form.Item
                  name="trade_lisc_no"
                  rules={[
                    {
                      required: true,
                      message: "Licencs number is required",
                    },
                  ]}
                >
                  <Input size="large" disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md="4">
                <div className="fw-bold ">Business Type</div>
                <Form.Item name="business_types">
                  <Select
                    mode="multiple"
                    allowClear
                    size="large"
                    style={{ width: "100%" }}
                    placeholder="Please select"
                    defaultValue={sellerDetails.business_types ?? []}
                    onChange={handleChange}
                    options={array}
                    optionRender={(option: any) => (
                      <Space>
                        <span role="img" aria-label={option?.data?.name}></span>
                        {option?.data?.name}
                      </Space>
                    )}
                  />
                  {/* <Select size="large">
                    {businessType?.map((item: any) => {
                      return (
                        <Select.Option key={item.id} value={item.name}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                  </Select> */}
                </Form.Item>
              </Col>
              <Col md="4">
                <div className="fw-bold ">Locate your Business</div>
                <Form.Item
                  name="business_location"
                  rules={[
                    {
                      required: true,
                      message: "Please Loacte your Business",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    onClick={(e) => {
                      setOpenPicker(true);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <div className="fw-bold ">Business Location</div>
                <Form.Item
                  name="business_address"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Business Location",
                    },
                  ]}
                >
                  <Input.TextArea size="large" rows={3} />
                </Form.Item>
              </Col>
            </Row>
            <div className="fw-bold mt-3 fs-4">Store Details</div>
            <Row className="mt-2">
              <Col md="4">
                <Row className="mt-2">
                  <div className="fw-bold ">Business Name</div>
                  <Form.Item
                    name="store_name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Business Name",
                      },
                    ]}
                  >
                    <Input size="large" />
                  </Form.Item>
                  <div className="fw-bold ">
                    Are you Manufacture or brand owner (or agent or
                    representiative of the brand) for the products you want to
                    sell on {API.NAME}
                  </div>
                  <Form.Item name="manufacture">
                    <Radio.Group>
                      <Radio value="Yes">Yes</Radio>
                      <Radio value="No">No</Radio>
                      <Radio value="Both">Both</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <div className="fw-bold ">
                    Do you have Universal product code (UPSCs) for all your
                    products ?
                  </div>
                  <Form.Item name="upscs">
                    <Radio.Group>
                      <Radio value="Yes">Yes</Radio>
                      <Radio value="No">No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Row>
              </Col>
              <Col md="4">
                <div className="fw-bold mt-2">Upload Store Logo</div>
                <Form.Item name="logo_upload">
                  <ImagePicker
                    size="large"
                    onChange={(file: any) => {
                      setFile(file);
                      setCropModalOpen(true);
                      setIsImageCropped(false);
                    }}
                    fileURL={croppedImage ?? sellerDetails?.logo_upload ?? ""}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Button
              size="large"
              loading={loading}
              type="primary"
              htmlType="submit"
              style={{ width: "30%" }}
            >
              Submit
            </Button>
            {cropModalOpen ? (
              <Modal
                title="Crop Image"
                open={cropModalOpen}
                onOk={handleCrop}
                onCancel={() => setCropModalOpen(false)}
                footer={false}
              >
                <input
                  ref={fileInputRef}
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    const selectedFile = e.target.files?.[0];
                    if (selectedFile) {
                      setFile({
                        file: selectedFile,
                        url: URL.createObjectURL(selectedFile),
                      });
                      setCropModalOpen(true);
                    }
                  }}
                />
                <Cropper
                  ref={cropperRef}
                  src={file?.url}
                  style={{ height: 300, width: "100%" }}
                  aspectRatio={1 / 1}
                  guides={true}
                />
                <div style={{ textAlign: "center", marginTop: 16 }}>
                  <Button
                    style={{ marginRight: 16 }}
                    onClick={() => {
                      if (fileInputRef.current) {
                        (fileInputRef.current as any).click();
                      }
                    }}
                  >
                    Choose Another Image
                  </Button>
                  <Button type="primary" onClick={handleCrop}>
                    Crop Image
                  </Button>
                  <div className="mb-2"></div>
                </div>
              </Modal>
            ) : null}
          </Form>
        </div>
        {openPicker ? (
          <LocationPicker
            location={location}
            visible={openPicker}
            onCancel={() => setOpenPicker(false)}
            onChange={(value: any) => {
              setLocation(value);
              form.setFieldValue(
                "business_location",
                `${value.lat} ${value.lng}`
              );
            }}  
          />
        ) : null}
      </>
    );
  } else {
    content = <NoData />;
  }

  return (
    <>
      {contextHolder}
      <PageHeader title="Manage Store" />
      {content}
    </>
  );
}

export default ManageStore;
