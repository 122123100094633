import React from "react";
import "../styles.scss";
import { useNavigate } from "react-router";
import { useEffect, useRef, useState } from "react";
import { MdArrowBack, MdOutlineArrowForward } from "react-icons/md";
function SubCategoryList(props: any) {
  const navigation = useNavigate();
  const [hasScrollBar, setHasScrollBar] = useState(false);
  const [rightButtonClicked, setRightButtonClicked] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const scroll = (ratio: number) => {
    if (ref.current) {
      const currentScrollLeft = ref.current.scrollLeft;
      ref.current.scrollLeft += ratio;

      if (ratio > 0 && !rightButtonClicked) {
        setRightButtonClicked(true);
      }

   if (ratio < 0 && rightButtonClicked && ref.current.scrollLeft <= 0) {
        setRightButtonClicked(false);
      }
    }
  };

  useEffect(() => {
    function updateState() {
    const el = ref.current;
      el &&
        setHasScrollBar(el.scrollWidth > el.getBoundingClientRect().width + 50);
    }
    updateState();
    window.addEventListener("resize", updateState);
    return () => window.removeEventListener("resize", updateState);
  }, []);

  function updateState() {
    const el = ref.current;
    el && setHasScrollBar(el.scrollWidth > el.getBoundingClientRect().width);
  }

  return (
    <div className="Horizontal-Pscroll">
      <div className="Horizontal-Pscroll position-relative">
        <div className="Horizontal-PscrollBox position-relative" ref={ref}>
          {props?.data?.map((item: any, index: number) => (
            <div
              key={index}
              className="homescreen-category-item"
              onClick={() => {
                navigation(
                  `/products/category?id=${window.btoa(item._id)}&type=${
                    item.name
                  }`
                );
              }}
            >
              <img src={item.image} alt="" />
              <div>{item.name}</div>
            </div>
          ))}
        </div>
    <>
      {rightButtonClicked && (
        <button
          className="Horizontal-btn1 position-absolute slider-btn-left"
          style={{ marginTop: 10 }}
          onClick={() => scroll(-300)}
        >
          <MdArrowBack />
        </button>
      )}
      <button
        className="Horizontal-btn2 slider-btn-right position-absolute"
        style={{ marginTop: 10 }}
        onClick={() => scroll(300)}
      >
        <MdOutlineArrowForward />
      </button>
        </>
      </div>
    </div>
  );
}

export default SubCategoryList;
