import { Row, Col } from "react-bootstrap";
import SaleMessage from "../../components/saleMessage";
import bestPrice from "../../assets/images/bestprice.png";
import SubscribeNewsletter from "../../components/subscribeNewsletter";
import PageHeader from "../../components/pageHeader";
import React, { useEffect } from "react";
import API from "../../config/API";
import { useTranslation } from "react-i18next";

function BestPrice() {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="Screen-box">
      <div>
        <SaleMessage />
      </div>
      <Row className="ftscreen-fnt mx-2">
        <PageHeader text={t("best_price_guarantee")} />
        <Col md={12}>
          <img
            className="img-responsive w-100 px-md-4"
            src={bestPrice}
            alt="Discount prices on various products"
          />
        </Col>
        <h4 className="text-center mt-5">{t("best_price_guarantee")} ({t("BPG")})</h4>
        <h5 className="mt-4">{t("WHAT_IS")} {t("best_price_guarantee")} ({t("BPG")}) {t("PROGRAM")}</h5>
        <div className="d-flex p-2" style={{ textAlign: "justify" }}>
        {t("BPG_is_a_unique_program_offered_by")} {API.NAME} {t("to_help_you_shop_confidently_while_we_check_the_prices_on_your_behalf")} {t("If_you_find_a_lower_price_at_another_retailer")} ({t("excluding_web_stores")}) {t("of_the_power_retailers")} {t("we_match_the_price")} {t("And_thats_our_promise")}
        </div>
        <div className="d-flex p-2 mt-1" style={{ textAlign: "justify" }}>
        {t("Purchase_of_the_product_from")} {API.NAME} {t("is_mandatory_to_claim_difference_under_BPG")} {t("The_difference_can_be_claimed_within_three_days_from_date_of_purchase_of_the_product.")}
        </div>
        <h5 className="mt-4">{t("WHICH_PRICES_DO_WE_MATCH")}</h5>
        <div className="d-flex p-2" style={{ textAlign: "justify" }}>
        {t("We_match_prices_publicly")}
        </div>
        <h5 className="mt-4">{t("WHO_ARE_THE_POWER_RETAILERS")}</h5>
        <div>
          <ul className="bullet-list">
            <li>{t("Axiom")}</li>
            <li>{t("Carrefour")}</li>
            <li>{t("Emax")}</li>
            <li>{t("Lulu")}</li>
          </ul>
        </div>

        <h5 className="mt-4">
        {t("WHAT_IF_A_COMPETITOR_IS")}
        </h5>
        <div className="d-flex p-2" style={{ textAlign: "justify" }}>
        {t("If_the_price_of_the")}
        </div>
        <h5 className="mt-4">{t("HOW_DO_I_CLAIM_THE")}</h5>
        <div className="d-flex p-2" style={{ textAlign: "justify" }}>
        {t("To_claim_the")}
        </div>
        <div className="d-flex p-2 mt-1" style={{ textAlign: "justify" }}>
        {t("Invoice_copy")} {API.NAME}
        </div>
        <div className="d-flex p-2 mt-1" style={{ textAlign: "justify" }}>
        {t("Proof_of_the_lower_price")}
        </div>
        <div className="d-flex p-2 mt-1" style={{ textAlign: "justify" }}>
        {t("You_need_not_worry")}
        </div>
        <div>
          <ul className="bullet-list">
            <li>
              <strong>{t("OPTION_1")}</strong> {t("EMAIL_US")} {API.CONTACT_MAIL}
            </li>
            <li>
              <strong>{t("OPTION_2")}</strong> {t("CALL_US")} 6238780530
            </li>
            <li>
              <strong>{t("OPTION_3")}</strong> {t("YOU_MAY_VISIT_ANY_OF_OUR")} {API.NAME} {t("STORES_ALONG_WITH")} {API.NAME} {t("INVOICE_AND_PROOF")}
            </li>
          </ul>
        </div>
        <div className="d-flex p-2 mt-1" style={{ textAlign: "justify" }}>
        {t("We_will_verify_the")}
        </div>
        <h5 className="mt-4">
        {t("WHAT_IS_THE_POLICY_ON")}
        </h5>
        <div className="d-flex p-2" style={{ textAlign: "justify" }}>
        {API.NAME} {t("reserves_the_right")}
        </div>
        <div className="d-flex p-2 mt-1" style={{ textAlign: "justify" }}>
        {API.NAME} {t("reserves_the_right_to_modify")}
        </div>
        <div className="d-flex p-2 mt-1" style={{ textAlign: "justify" }}>
        {API.NAME} {t("reserves_the_right_to_cancel")}
        </div>
      </Row>
      <div className="mt-5">
        <SubscribeNewsletter />
      </div>
    </div>
  );
}
export default BestPrice;
