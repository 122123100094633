import React, { useEffect, useMemo, useState } from "react";
import PageHeader from "../components/PageHeader";
import { Col, Row } from "react-bootstrap";
import API from "../../config/API";
import { GET } from "../../utils/apiCalls";
import "./style.scss";
import {
  Avatar,
  Button,
  Form,
  List,
  Modal,
  Pagination,
  message,
  notification,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { storePos } from "../../redux/slices/posSlice";
import Loading from "../../components/loading";
import { Radio } from "antd";
import lodash, { divide } from "lodash";

import {
  IoIosAddCircleOutline,
  IoIosRemoveCircleOutline,
  IoMdAdd,
} from "react-icons/io";
import { MdDeleteOutline } from "react-icons/md";
import Search from "antd/es/input/Search";
import NoData from "../../components/noData";
import useToggle from "../../shared/hook/useToggle";

function POSScreen() {
  const [notificationApi, contextHolder] = notification.useNotification();
  const [products, setProducts] = useState<any[]>([]);
  const [meta, setMeta] = useState<any>({});
  const POS = useSelector((state: any) => state.POS?.pos);
  const dispatch = useDispatch();
  const Settings = useSelector((state: any) => state.Settings.Settings);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [categories, setCategories] = useState<any[]>([]);
  const User = useSelector((state: any) => state.User.user?.data);
  const iconSize = 20;
  const [store, setStore] = useState<any>({});
  const [page, setPage] = useState(1);
  const pageSize = 30;
  const [variantModal, toggleVariant] = useToggle(false);
  const [category, setCategory] = useState<any>("");
  const [searching, setSearching] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [variantList, setVariantList] = useState<any>();
  const [selectedProduct, setSelectedProduct] = useState<any>();
  const [form] = Form.useForm();
  const [productVaraintList, setProductVariantList] = useState<any>();
  const getProducts = async (
    current: number,
    categ: number | string = "",
    search: string = ""
  ) => {
    setLoading(true);
    const url =
      API.STORE_SEARCH_PRODUCTS_ALL +
      `storeId=${
        User?.store_id
      }&order=DESC&price=ASC&page=${current}&take=${pageSize}${
        categ ? `&categoryId=${categ}` : ""
      }${search ? `&search=${search}` : ""}`;
    try {
      const response: any = await GET(url, null);
      if (response.status) {
        setProducts(response.data);
        setMeta(response.meta);
      } else {
        notificationApi.error({ message: response.message ?? "" });
      }
    } catch (err) {
      notificationApi.error({ message: "Something went wrong" });
    } finally {
      setLoading(false);
    }
  };

  const getStoreDetails = async () => {
    const url = API.STORE_SEARCH_GETINFO + User?.store_id;
    try {
      const response: any = await GET(url, null);
      if (response.status) {
        setStore(response.data?.store);
        setCategories(response.data?.category);
      } else {
        throw new Error(response.message);
      }
    } catch (err) {
    } finally {
      setLoading2(false);
    }
  };
  const onSearch = async (value: string) => {
    if (value) {
      setSearching(true);
      await getProducts(page, category, value);
      setSearching(false);
    }
  };
  const selectVariant = (item: any) => {
    let variant: any = {};
    let productVariant: any = [];
    setSelectedProduct(item);
    item.productVariant?.forEach((variantItem: any) => {
      productVariant.push({ id: variantItem.id });
      variantItem.combination.forEach((com: any) => {
        const index = productVariant.length - 1;
        productVariant[index] = {
          ...productVariant[index],
          [com?.variant]: com?.value,
        };
        if (variant[com.variant]) {
          if (!variant[com.variant].includes(com.value)) {
            variant[com.variant].push(com.value);
          }
        } else {
          variant[com.variant] = [com.value];
        }
      });
    });
    setVariantList(variant);
    setProductVariantList(productVariant);
    toggleVariant(true);
  };
  const addProductToPos = (item: any) => {
    const exist = POS?.findIndex(
      (ite: any) =>
        ite?._id == item?._id &&
        (item?.variantId ? item?.variantId == ite?.variantId : true)
    );
    if (exist != -1) {
      const obj = {
        ...POS[exist],
        quantity: POS[exist]?.quantity + 1,
      };
      const array = [...POS];
      array.splice(exist, 1, obj);
      dispatch(storePos(array));
    } else {
      const prod = {
        ...item,
        quantity: 1,
      };
      const array = [...POS, prod];
      dispatch(storePos(array));
    }
  };
  const clearPos = () => {
    dispatch(storePos([]));
  };
  const removeItem = (item: any) => {
    const index = POS?.findIndex(
      (ite: any) =>
        ite?._id == item?._id &&
        (item?.variantId ? item?.variantId == ite?.variantId : true)
    );
    if (index != -1) {
      const array = [...POS];
      array.splice(index, 1);
      dispatch(storePos(array));
    }
  };
  const increaseCount = (item: any) => {
    const newArray = POS?.map((ite: any) => {
      if (
        ite?._id == item?._id &&
        (item?.variantId ? item?.variantId == ite?.variantId : true)
      ) {
        return { ...ite, quantity: ite.quantity + 1 };
      } else {
        return { ...ite };
      }
    });
    dispatch(storePos(newArray));
  };
  const decreaseCount = (item: any) => {
    const newArray = POS?.map((ite: any) => {
      if (
        ite?._id == item?._id &&
        (item?.variantId ? item?.variantId == ite?.variantId : true)
      ) {
        return { ...ite, quantity: ite.quantity == 1 ? 1 : ite.quantity - 1 };
      } else {
        return { ...ite };
      }
    });
    dispatch(storePos(newArray));
  };
  const getTotalPrice = () => {
    const total: number = POS.reduce(
      (sum: number, item: any) =>
        sum + Number(item.quantity * item.retail_rate),
      0
    );
    setTotalPrice(total);
  };
  useMemo(() => {
    getTotalPrice();
  }, [POS]);
  const changePage = async (page: number) => {
    await getProducts(page);
    setPage(page);
  };
  useEffect(() => {
    getProducts(1);
    getStoreDetails();
  }, []);
  const handleVariantSelection = (values: any) => {
    const foundObject = lodash.find(productVaraintList, (item) => {
      const { id, ...properties } = item;
      return lodash.isEqual(properties, values);
    });
    if (foundObject) {
      let productWithVariant = {
        ...selectedProduct,
        variantId: foundObject.id,
      };
      const foundVariant = selectedProduct?.productVariant.find(
        (item: any) => foundObject.id == item.id
      );
      if (foundVariant && foundVariant?.image) {
        productWithVariant = {
          ...productWithVariant,
          variantImg: foundVariant?.image,
        };
      }
      productWithVariant = {
        ...productWithVariant,
        variantName: Object.values(values).join(","),
      };

      addProductToPos(productWithVariant);
      form.resetFields();
      toggleVariant(false);
    } else {
      notificationApi.error({
        message:
          "Sorry, the selected product variant is not available. Please choose another variant.",
      });
    }
  };
  return (
    <div className="Admin-box">
      {contextHolder}
      <PageHeader title={`POS`} second={"All orders"}>
        <Search
          placeholder="Search Products"
          allowClear
          enterButton="Search"
          size="middle"
          onSearch={onSearch}
          loading={searching}
        />
      </PageHeader>
      {loading2 ? null : (
        <div className="d-flex gap-2 pos-subcategories mt-2 ms-2">
          <div
            style={{ cursor: "pointer", whiteSpace: "nowrap" }}
            className={`pos-tags px-3 align-self-center text-bold ${
              category == "" ? "active" : ""
            }`}
            key={4444}
            onClick={() => {
              getProducts(page, "");
              setCategory("");
            }}
          >
            {"All"}
          </div>
          {categories?.map((item: any, index: number) => (
            <div
              style={{ cursor: "pointer", whiteSpace: "nowrap" }}
              className={`pos-tags px-3 align-self-center text-bold ${
                item._id == category ? "active" : ""
              }`}
              key={index}
              onClick={() => {
                getProducts(page, item._id);
                setCategory(item._id);
              }}
            >
              {item?.name}
            </div>
          ))}
        </div>
      )}

      {loading ? (
        <Loading />
      ) : products.length ? (
        <Row className="my-3 mx-0">
          <Col md="8">
            <Row className="g-2">
              {products.map((item: any, key: number) => (
                <Col lg="3" md="4" sm="6" key={key}>
                  <div className="product-card-pos border pb-2 h-100">
                    <div className="img-container">
                      <img src={item?.image} alt="" className="img" />
                    </div>
                    <div className="px-2 product-card-pos-title-card">
                      <p className="product-card-pos-title">
                        {item.name ?? ""}
                      </p>{" "}
                      <div className="d-flex justify-content-between">
                        {" "}
                        <p className="price fw-bold">
                          {Settings?.currency}
                          {item.retail_rate ?? 0}
                        </p>
                        <Button
                          type="primary"
                          size="small"
                          onClick={() => {
                            item?.productVariant?.length
                              ? selectVariant(item)
                              : addProductToPos(item);
                          }}
                        >
                          Add
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
          <Col md="4">
            <div style={{ position: "sticky", top: "0px" }}>
              {POS.length ? <h5>{`${POS.length ?? 0} Items`}</h5> : null}
              <List
                itemLayout="vertical"
                dataSource={POS}
                renderItem={(item: any, index) => (
                  <List.Item
                    className="pos-screen-pos-list-items"
                    actions={[
                      <a key="decrease" onClick={() => decreaseCount(item)}>
                        <IoIosRemoveCircleOutline color="red" size={iconSize} />
                      </a>,
                      <a key="increase" onClick={() => increaseCount(item)}>
                        <IoIosAddCircleOutline color="green" size={iconSize} />
                      </a>,
                      <a
                        key="list-loadmore-more"
                        onClick={() => removeItem(item)}
                      >
                        <MdDeleteOutline size={iconSize} />
                      </a>,
                    ]}
                  >
                    <List.Item.Meta
                      style={{ marginBottom: 0 }}
                      avatar={
                        <Avatar
                          size={40}
                          src={item?.variantImg ?? item.image ?? ""}
                        />
                      }
                      title={item.name ?? ""}
                      description={
                        <div>
                          <div>
                            {item?.variantName
                              ? `(${item?.variantName})`
                              : null}
                          </div>

                          <div className="pos-selected-item-list-discription">
                            {" "}
                            {item.description?.length > 40
                              ? item.description.slice(0, 40) + "..."
                              : item.description}
                          </div>
                          <br />
                          <div className="d-flex justify-content-between">
                            <span className="fw-bold">
                              {Settings?.currency}
                              {Number(item.retail_rate * item.quantity)}
                            </span>
                            {"  "}
                            <span>{item.quantity ?? ""} item</span>
                          </div>
                        </div>
                      }
                    />
                  </List.Item>
                )}
              />

              {POS.length ? (
                <>
                  <div className="fw-medium">
                    TotalPrice: {Settings?.currency} {totalPrice}
                  </div>
                  <Button type="primary" block onClick={() => clearPos()}>
                    Order
                  </Button>
                </>
              ) : null}
            </div>
          </Col>
        </Row>
      ) : (
        <NoData text1={"No Products Available"} />
      )}
      <div className="d-flex justify-content-center mt-3">
        <Pagination
          current={page || 1}
          pageSize={pageSize || 10}
          total={meta?.itemCount || 0}
          defaultCurrent={1}
          responsive={true}
          defaultPageSize={pageSize || 10}
          disabled={false}
          hideOnSinglePage={true}
          onChange={(current: any, size: any) => {
            changePage(current);
          }}
        />
      </div>
      <Modal
        title="Select Variant"
        centered
        open={variantModal}
        okButtonProps={{ htmlType: "submit" }}
        onOk={() => form.submit()}
        onCancel={() => {
          form.resetFields();
          toggleVariant(false);
        }}
      >
        {variantList ? (
          <Form
            onFinish={handleVariantSelection}
            form={form}
            requiredMark={false}
          >
            {Object.keys(variantList).map((item: any) => (
              <>
                <Form.Item
                  label={item}
                  name={item}
                  rules={[
                    {
                      required: true,
                      message: `Please choose a ${item.toLowerCase()} for the product!`,
                    },
                  ]}
                >
                  <Radio.Group buttonStyle="solid">
                    {variantList[item].map((value: string) => (
                      <Radio.Button value={value}>{value}</Radio.Button>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </>
            ))}
          </Form>
        ) : (
          <></>
        )}
      </Modal>
    </div>
  );
}

export default POSScreen;
