import React from "react";
import { useTranslation } from "react-i18next";

function SummaryCard(props: any) {
  const { t } = useTranslation();
  return (
    <div className="SummaryCard">
      <table>
        <tr>
          <td>
            <b>{t("Full_Name")}</b>
          </td>
          <td>
            : {props?.data?.firstname}&nbsp;{props?.data?.lastname}
          </td>
        </tr>
        <tr>
          <td>
            <b>{t("Phone_Number")}</b> &nbsp;
          </td>
          <td>
            : {props?.data?.code}&nbsp;{props?.data?.phone}
          </td>
        </tr>
        <tr>
          <td>
            <b>{t("Email_Address")}</b>
          </td>
          <td>: {props?.data?.email}</td>
        </tr>
        <tr>
          <td>
            <b>{t("Username")}</b>
          </td>
          <td>: {props?.data?.username}</td>
        </tr>
        <tr>
          <td>
            <b>{t("Password")}</b>
          </td>
          <td>: ******</td>
        </tr>
      </table>
    </div>
  );
}
export default SummaryCard;
