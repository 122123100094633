import SaleMessage from "../../components/saleMessage";
import SubscribeNewsletter from "../../components/subscribeNewsletter";
import ExpressIcon from "../../assets/images/EDbadge.png";
import EDfilter from "../../assets/images/EDfilter.png";
import PageHeader from "../../components/pageHeader";
import { useEffect } from "react";
import React from "react";
import API from "../../config/API";
function TermsandCond() {
  return (
    <div className="Screen-box">
      <div
        style={{
          fontWeight: 700,
          fontSize: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        Ooops! We Are Under Maintanance
      </div>
    </div>
  );
}
export default TermsandCond;
