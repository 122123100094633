import { Button } from "antd";
import React from "react";
import { Row, Col, Container } from "react-bootstrap";
function Offers(props: any) {
  return (
    <Container fluid className="Offers-box">
      <Row >
        {props?.data?.map((item: any) => {
          return (
            <Col sm={6} xs={12}>
              <div className="Offers-img" style={{ backgroundImage: `url(${item?.image})` }}>
                <div className="Offers-txt1">{item?.title}</div>
                <br/>
                <Button style={{color:"#fff",backgroundColor:"transparent"}} size="large">See More</Button>
              </div>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
}

export default Offers;
