import { Container, Row } from "react-bootstrap";
import SaleMessage from "../../components/saleMessage";
import SubscribeNewsletter from "../../components/subscribeNewsletter";
import WarrantyImg from "../../assets/images/warrantyimage.png";
import WarrantyCheck from "../../assets/images/noticeboard.jpg";
import Logo from "../../config/logo.png";
import FAQ from "../../assets/images/faq.jpg";
import "./styles.scss";
import { Button, Form, InputNumber, Modal } from "antd";
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import PrefixSelector from "../../components/prefixSelector";
import API from "../../config/API";
import { useTranslation } from "react-i18next";

function CheckWarrantystatus() {
  const { t } = useTranslation();
  const [otpModalVisible, setOtpModalVisible] = useState(false);
  const [otp, setOtp] = useState("");
  const submitOtp = () => {
    //
  };

  const handleSendOtp = () => {
    setOtpModalVisible(true);

    // Handle form submission here if needed
    submitOtp();
  };

  const handleOtpVerification = () => {
    // Handle OTP verification logic here
    // For simplicity, just submit
    setOtpModalVisible(false);
    submitOtp();
  };
  const handleResend = () => {
    setOtp("");
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="Screen-box">
      <div>
        <SaleMessage />
      </div>
      <div className="ftscreen-fnt m-2 ">
        <Row className="m-0 mt-3">
          <div id="section1" className="CheckWarrantystatus-bg1">
            <Container>
              <div className="row p-0 justify-content-center mb-4">
                <div className="col-md-4 col-12 p-md-5  p-0 m-0 mt-5 mb-5 text-center text-white">
                  <h3 className="pt-md-5 pt-0">{t("Warranty_Portal")}</h3>
                  <h1 className="pt-1 display-6 lh-sm">
                  {t("End_to_end_warranty")}
                  </h1>
                  <a
                    className="btn btn-light text-capitalize rounded-pill ps-5 pe-5 scrolling-link"
                    href="#findmore"
                  >
                    <strong>{t("Find_out_more")}</strong>
                  </a>
                  <p className="pt-3 fs-5">
                  {t("Applicable_warranties_will")} {API.NAME}
                  {t("only_We_are_coming_soon")}
                  </p>
                </div>
                <div className="col-md-8 ">
                  <div className="CheckWarrantystatus-img1 align-items-center justify-content-center d-flex">
                    <div className="CheckWarrantystatus-loginform">
                      <Form
                        onFinish={handleSendOtp}
                        initialValues={{
                          code: "+91",
                        }}
                      >
                        <div className="text-center">
                          <img
                            src={Logo}
                            alt="Company Logo"
                            className=" mb-4 w-50 "
                          />
                        </div>
                        
                          <Form.Item
                            name="phone"
                            rules={[
                              {
                                required: true,
                                message: `${t("Input_your_phone_number")}`
                              },
                            ]}
                          >
                            <InputNumber
                              addonBefore={<PrefixSelector />}
                              formatter={(value) => `${value}`.replace(/[^0-9]/g, '')}
                              parser={(value) => `${value}`.replace(/[^0-9]/g, '')}
                            
                              style={{ width: "100%" }}
                              size="large"
                              placeholder={t("Enter_Phone_Number")}
                              type="number"
                            />
                          </Form.Item>
                        
                        <Form.Item>
                          <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            block
                          >
                            {t("Send_OTP")}
                            
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                </div>
                <Modal
                  title="Enter OTP"
                  visible={otpModalVisible}
                  footer={null}
                  onCancel={() => setOtpModalVisible(false)}
                  className="d-flex"
                >
                  <Form onFinish={handleOtpVerification}>
                    <div className="px-3">
                      <p className="mt-4">
                      {t("An_OTP_has_been_sent")}
                      </p>
                      <div>
                        <p>{t("Enter_your_Code_here")}</p>
                        <div className="d-flex">
                          <OtpInput
                            onChange={setOtp}
                            value={otp}
                            inputStyle="inputStyle"
                            numInputs={6}
                            renderSeparator={<span></span>}
                            renderInput={(props) => <input {...props} />}
                          />
                        </div>

                        <p className="mt-3">{t("Didnt_receive_the_code")}</p>
                        <p
                          className="resend"
                          onClick={handleResend}
                          style={{ cursor: "pointer" }}
                        >
                          {t("Resend")}
                        </p>
                      </div>
                      <div className="d-flex justify-content-center">
                        <Button type="primary" size="large" htmlType="submit">
                        {t("Verify")}
                        </Button>
                      </div>
                    </div>
                  </Form>
                </Modal>
              </div>
            </Container>
          </div>
        </Row>
        <Row id="findmore" className="my-4">
          <div className="container p-5 justify-content-center">
            <div className="row p-md-5 p-0">
              <div className="col-md-4 p-md-4 p-0 m-md-5 m-0 mt-5 mb-5 text-md-start text-center text-black">
                <h2 className="pt-md-5 display-6 p-2 lh-sm">
                {t("Are_you_a")} {API.NAME} {t("Customer")}
                </h2>
                <h2 className="pt-1 display-6 lh-sm mt-3">
                {t("Want_to_know_the_warranty")}
                </h2>
              </div>
              <div className="col-md-6">
                <img className="mt-md-2 mt-5 rounded w-100" src={WarrantyImg} alt=""/>
              </div>
            </div>
          </div>
        </Row>
        <div className="px-4">
          <Row className="bg-light">
            <div className="container p-5 justify-content-center">
              <div className="row p-md-5 p-0">
                <div className="col-md-6">
                  <img className="mt-2 rounded w-100" src={WarrantyCheck} alt=""/>
                </div>
                <div className="col-md-4 p-md-4 p-0 m-md-5 m-0 mt-5 mb-5 text-md-start text-center">
                  <h5 className="pt-1 pt-md-5 lh-sm">
                  {t("You_will_get_a_single")} {API.NAME} {t("purchases_can_be_listed")}
                  </h5>
                  <h4 className="pt-1 display-6 lh-sm mt-3">
                  {t("A_complete_solution_for")}
                  </h4>
                </div>
              </div>
            </div>
          </Row>
          <Row className="CheckWarrantystatus-img2">
            <div className=" container p-5 ">
              <div className="row p-md-5 p-0">
                <div className="col-md-4 col-12 p-md-4 m-md-5 p-0 m-0 mt-5 mb-5 text-md-start text-center text-white">
                  <h4 className="pt-1  lh-sm">
                  {t("It_will_also_enable")}
                  </h4>
                </div>
              </div>
            </div>
          </Row>
          <Row className="bg-light">
            <div className="container p-5 justify-content-center">
              <div className="row p-md-5 p-0">
                <div className="col-md-4 p-md-4 p-0 m-md-5 m-0 mt-5 mb-5 text-md-start text-center">
                  <h2 className="pt-1  lh-sm text-uppercase">
                  {t("Frequently_asked_questions")}
                  </h2>
                  <h6>
                  {t("Can_I_check_the")}
                  </h6>
                  <p className="fs-6">
                  {t("Yes_you_can_log_on")}{API.WEBSITE}{t("warranty_and_check")} {API.WEBSITE}
                  </p>
                  <p className="pt-1 fw-bolder fs-5 lh-sm mt-3">
                  {t("You_can_only_view_the")} {API.NAME}; {t("incase_the_purchased_product")}
                  </p>
                  <Button
                    type="primary"
                    size="large"
                    block
                    className="m-2 rounded-pill px-5"
                    href="./faq"
                    target="_self"
                  >
                    <strong>{t("View_More")}</strong>
                  </Button>
                </div>
                <div className="col-md-6">
                  <img className="mt-md-2 mt-5 rounded w-100" src={FAQ} alt="" />
                </div>
              </div>
            </div>
          </Row>
        </div>
      </div>
      <div className="mt-5">
        <SubscribeNewsletter />
      </div>
    </div>
  );
}
export default CheckWarrantystatus;
