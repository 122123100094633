import {
  useEffect,
  useState,
  forwardRef,
  Ref,
  useImperativeHandle,
} from "react";
import { Button, Form, Input, Select } from "antd";
import { Row, Col, Container } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import TextArea from "antd/es/input/TextArea";
import React from "react";
import { GET } from "../../../utils/apiCalls";
import API from "../../../config/API";
import generateRandomString from "../../../shared/helpers/generateRandomString";
import generateRandom12DigitNumber from "../../../shared/helpers/generateRandomNumber";
export type ResetType = {
  reset: () => void;
};
function Information(props: any, ref: Ref<ResetType>) {
  const [subCategories, setSubCategories] = useState<any>([]);
  const [types, setTypes] = useState<any>([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [subCategory, setSubCategory] = useState("");
  const [purity, setPurity] = useState<any[]>([]);
  const [brands, setBrands] = useState<any[]>([]);

  const [specifications, setSpecifications] = useState(
    props?.data?.specifications
  );

  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldValue("type", "");
  }, [subCategory, form]);

  const categoryChangeHandler = (value: any) => {
    const index: any = props?.categories?.findIndex(
      (item: any) => item.id == value
    );
    if (index != -1) {
      let item = props?.categories[index]?.sub_categories;
      form.setFieldValue(
        "bar_code",
        props?.categories[index]?.barcodePrefix + generateRandom12DigitNumber()
      );
      form.setFieldValue(
        "sku",
        props?.categories[index]?.skuPrefix + generateRandomString()
      );
      setSubCategories(item);
    } else {
      setSubCategories([]);
    }
  };
  const getPurities = async () => {
    const url = API.GET_PURITY_ALL;
    try {
      const response: any = await GET(url, null);
      if (response?.status) {
        if (Array.isArray(response?.data)) {
          const datas = response?.data?.map((item: any) => ({
            value: item?.purity,
            label: item?.metalType,
          }));
          setPurity(datas);
        }
      }
    } catch (err) {}
  };

  const getBrands = async () => {
    const url = API.GET_BRAND;
    try {
      const response: any = await GET(url, null);
      if (response?.status) {
        if (Array.isArray(response?.data)) {
          const datas = response?.data?.map((item: any) => ({
            value: item?.name,
            label: item?.name,
          }));
          setBrands(datas);
        }
      }
    } catch (err) {}
  };

  const typeChangeHandler = (value: any) => {
    setSubCategory(value);
    const index: any = subCategories?.findIndex(
      (item: any) => item._id == value
    );
    if (index != -1) {
      let item = subCategories[index]?.types;

      setTypes(item);
    } else {
      setTypes([]);
    }
  };
  useEffect(() => {
    getType();
    getPurities();
    getBrands();
  }, []);

  const getType = async () => {
    try {
      const url = `${API.GET_TYPE_ALL}?order=ASC&page=1&take=10`;
      const response: any = await GET(url, null);
      if (response) {
        setData(response?.data);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  // useEffect(()=>{
  //   typeChangeHandler(props?.data?.sub_categories)
  // },[props?.data?.sub_categories])

  useEffect(() => {
    categoryChangeHandler(props?.data?.category);
  }, [props?.data?.category]);
  const submit = async (values: any) => {
    try {
      let obj = values;
      obj["specifications"] = specifications;
      props.onChange(obj);
    } catch (err) {
      console.log("err", err);
    }
  };
  const reset = () => {
    form.resetFields();
  };
  useImperativeHandle(ref, () => ({
    reset,
  }));
  return (
    <Container>
      <Form
        onFinish={submit}
        initialValues={{
          bar_code: props?.data?.bar_code,
          // sku: props?.data?.sku,
          // brand: props?.data?.brand,
          // bulk_order: props?.data?.bulk_order,
          category: props?.data?.category,
          // manufacture: props?.data?.manufacture,
          purchase_rate: props?.data?.purchase_rate || 0,
          retail_rate: props?.data?.retail_rate || 0,
          status: props?.data?.status,
          subCategory: props?.data?.subCategory,
          name: props?.data?.name,
          description: props?.data?.description,
          unit: props?.data?.unit || 1,
          // units: props?.data?.units,
          metal_type: props?.data?.metal_type,
          weight: props?.data?.weight,
          // purity: props?.data?.purity,
          labour_charge: props?.data?.labour_charge,
          discount: props?.data?.discount,
          // premium: props?.data?.premium,
          price_method: props?.data?.price_method,
          vat: props?.data?.vat,
          stone_weight: props?.data?.stone_weight,
          net_weight: props?.data?.net_weight,
        }}
        form={form}
      >
        <Row>
          <Col sm={6}>
            <div className="input-form-label">Category *</div>
            <Form.Item
              name={"category"}
              rules={[{ required: true, message: "Please chose Category" }]}
            >
              <Select
                style={{ width: "100%" }}
                className="border rounded"
                allowClear
                onChange={categoryChangeHandler}
                placeholder="Select category"
              >
                {props?.categories?.map((item: any, index: number) => (
                  <Select.Option key={index} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <div className="input-form-label">Sub Category *</div>
            <Form.Item
              name={"subCategory"}
              rules={[{ required: true, message: "Please chose Subcategory" }]}
            >
              <Select
                style={{ width: "100%" }}
                className="border rounded"
                allowClear
                onChange={typeChangeHandler}
                placeholder="Select category"
              >
                {subCategories?.map((item: any, index: number) => (
                  <Select.Option key={index} value={item._id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <div className="input-form-label">Type*</div>
            <Form.Item
              rules={[{ required: true, message: "Please select a type" }]}
              name={"type"}
            >
              <Select
                style={{ width: "100%" }}
                allowClear
                placeholder=" Select Type"
              >
                {types?.map((item: any, idx: number) => (
                  <Select.Option key={idx} value={item?._id}>
                    {item?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <div className="input-form-label">Product Title *</div>
            <Form.Item
              name={"name"}
              rules={[
                { required: true, message: "Please Enter Product Name" },
                { max: 200, message: "Product name length is too much" },
              ]}
            >
              <Input placeholder="Title" />
            </Form.Item>
            <div className="input-form-label">Description *</div>
            <Form.Item
              name={"description"}
              rules={[
                { required: true, message: "Please Enter product Description" },
                { max: 250, message: "Product Description length is too much" },
              ]}
            >
              <TextArea rows={4} placeholder="Description" maxLength={250} />
            </Form.Item>
            <Row>
              <Col sm={6}>
                <div className="input-form-label">Discount*</div>
                <Form.Item
                  name={"discount"}
                  rules={[{ required: true, message: "Please Enter Discount" }]}
                >
                  <Input type="number" placeholder="Discount" />
                </Form.Item>
              </Col>
              <Col sm={6}>
                <div className="input-form-label">Making Charge(%)</div>
                <Form.Item
                  name={"labour_charge"}
                  rules={[{ required: true, message: "Please Enter charge" }]}
                >
                  <Input type="number" placeholder="Making Charge" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row>
              <Col sm={6}>
                <div className="input-form-label">Barcode</div>
                <Form.Item
                  name={"bar_code"}
                  // rules={[
                  //   { required: true, message: "Please Enter Barcode" },
                  //   {
                  //     max: 16,
                  //     message: "Product SKU length must be below 16 chars",
                  //   },
                  //   {
                  //     min: 10,
                  //     message:
                  //       "Product SKU length must be atleast 10 characters",
                  //   },
                  // ]}
                >
                  <Input placeholder="Barcode" />
                </Form.Item>
              </Col>
              <Col sm={6}>
                <div className="input-form-label">Vat</div>

                <Form.Item
                  rules={[{ required: true, message: "Please select a vat" }]}
                  name={"vat"}
                >
                  <Select
                    style={{ width: "100%" }}
                    allowClear
                    placeholder="Vat"
                  >
                    <Select.Option value="0">0%</Select.Option>
                    <Select.Option value="5">5%</Select.Option>
                    <Select.Option value="10">10%</Select.Option>
                    <Select.Option value="15">15%</Select.Option>
                    <Select.Option value="20">20%</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <div className="input-form-label">Purchase Price</div>
                <Form.Item
                  name={"purchase_rate"}
                  rules={[
                    {
                      required: true,
                      message: "Enter Purchase rate of the Product",
                    },
                  ]}
                >
                  <Input placeholder="0.00" type="number" max={1000000} />
                </Form.Item>
              </Col>
              <Col sm={6}>
                <div className="input-form-label">Retail Price</div>
                <Form.Item
                  name={"retail_rate"}
                  rules={[
                    {
                      required: true,
                      message: "Enter Reail Rate of the product",
                    },
                  ]}
                >
                  <Input placeholder="0.00" type="number" max={1000000} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <div className="input-form-label">Stock</div>
                <Form.Item
                  name={"unit"}
                  rules={[
                    {
                      required: true,
                      message: "Enter Available Units.",
                    },
                  ]}
                >
                  <Input placeholder="Unit" type="number" max={10000} />
                </Form.Item>
              </Col>
              {/* <Col sm={6}>
                <div className="input-form-label">
                  Available Stock (for bulk orders)
                </div>
                <Form.Item
                  name={"units"}
                  rules={[
                    {
                      required: true,
                      message: "Enter Available Stocks.",
                    },
                  ]}
                >
                  <Input
                    placeholder="Available Units"
                    max={10000}
                    type="number"
                    prefix={0}
                  />
                </Form.Item>
              </Col> */}
              {/* <Col sm={6}>
                <div className="input-form-label">Accept bulk orders</div>
                <Form.Item
                  name={"bulk_order"}
                  rules={[
                    {
                      required: true,
                      message:
                        "Please Select if you suppor Bulk order for this Product",
                    },
                  ]}
                >
                  <Select
                    style={{ width: "100%" }}
                    className="border rounded"
                    allowClear
                    onChange={categoryChangeHandler}
                    placeholder="Select Bulk Order"
                  >
                    <Select.Option value={"accept"}>Accept</Select.Option>
                    <Select.Option value={"notaccept"}>
                      Not Accept
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col> */}
              <Col sm={6}>
                <div className="input-form-label">Product Status</div>
                <Form.Item
                  name={"status"}
                  rules={[
                    {
                      required: true,
                      message: "Please Choose the Status of The product",
                    },
                  ]}
                >
                  <Select
                    style={{ width: "100%" }}
                    className="border rounded"
                    allowClear
                    onChange={categoryChangeHandler}
                    placeholder="Select Status"
                  >
                    <Select.Option value={"available"}>Available</Select.Option>
                    <Select.Option value={"notavailable"}>
                      Not Available
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={6}>
                <div className="input-form-label">Pricing Method*</div>
                <Form.Item name={"price_method"}>
                  <Select
                    style={{ width: "100%" }}
                    allowClear
                    placeholder="Pricing method"
                  >
                    <Select.Option value="Fixed">Fixed Rate</Select.Option>
                    <Select.Option value="Live">Live Rate</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={6}>
                <div className="input-form-label">Gender*</div>
                <Form.Item
                  rules={[
                    { required: true, message: "Please select a gender" },
                  ]}
                  name={"gender"}
                >
                  <Select
                    style={{ width: "100%" }}
                    allowClear
                    placeholder="Gender"
                  >
                    <Select.Option value="Male">Male</Select.Option>
                    <Select.Option value="Female">Female</Select.Option>
                    <Select.Option value="Child">Child</Select.Option>
                    <Select.Option value="Both">Both</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={6}>
                <div className="input-form-label">Metal Type*</div>
                <Form.Item
                  name={"metal_type"}
                  rules={[
                    { required: true, message: "Please Select Metal Type" },
                  ]}
                >
                  <Select>
                    <Select.Option value={1}>Gold 24 Karat</Select.Option>
                    <Select.Option value={2}>Gold 22 Karat</Select.Option>
                    <Select.Option value={3}>Gold 21 Karat</Select.Option>
                    <Select.Option value={4}>Gold 18 Karat</Select.Option>
                    <Select.Option value={5}>Silver</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={6}>
                <div className="input-form-label">Gross Weight*</div>
                <Form.Item
                  name={"weight"}
                  rules={[{ required: true, message: "Please Enter Weight" }]}
                >
                  <Input type="number" placeholder="Gross Weight" />
                </Form.Item>
              </Col>
              <Col sm={6}>
                <div className="input-form-label">Stone Weight</div>
                <Form.Item name={"stone_weight"}>
                  <Input type="number" placeholder="Stone Weight" />
                </Form.Item>
              </Col>
              <Col sm={6}>
                <div className="input-form-label">Net Weight</div>
                <Form.Item name={"net_weight"}>
                  <Input type="number" placeholder="Net Weight" />
                </Form.Item>
              </Col>
              <Col sm={12}>
                <Form.Item>
                  <Button size="large" block type="primary" htmlType="submit">
                    Continue
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}
export default forwardRef(Information);
