import { Form, Input, Modal, Select, notification } from "antd";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import API from "../../config/API";
import { PUT } from "../../utils/apiCalls";

function QuickEditModal(props: any) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [Notifications, contextHolder] = notification.useNotification();
  useEffect(() => {
    form.setFieldsValue({
      retail_rate: props?.data?.retail_rate || 0,
      status: props?.data?.status == true ? "available" : "notavailable",
      name: props?.data?.name,
      unit: props?.data?.unit || 1,
      weight: props?.data?.weight,
      purity: props?.data?.purity,
      premium: props?.data?.premium,
      price_method: props?.data?.price_method,
      labour_charge: props?.data?.labour_charge,
    });
  }, [props]);
  const submit = async (values: any) => {
    const url = API.PRODUCTS_UPDATE + props?.data?._id;
    setLoading(true);
    try {
      const response: any = await PUT(url, values);
      if (response?.status) {
        Notifications["success"]({
          message: "Success",
          description: "Product Details Updated Successfully",
        });
        props?.fetchProducts();
        setTimeout(() => {
          props?.close();
          form.resetFields();
        }, 100);
      } else {
        throw new Error(response?.message);
      }
    } catch (err: any) {
      Notifications["error"]({
        message: "Failed",
        description: err.message,
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      title="Quick Edit Product"
      open={props?.open}
      centered
      onCancel={() => {
        props?.close();
        form.resetFields();
      }}
      okText="Update"
      confirmLoading={loading}
      onOk={() => {
        form.submit();
      }}
    >
      {contextHolder}
      <Form onFinish={submit} form={form}>
        <Row>
          <Col md="6">
            <div className="input-form-label">Product Title *</div>
            <Form.Item
              name={"name"}
              rules={[
                { required: true, message: "Please Enter Product Name" },
                { max: 200, message: "Product name length is too much" },
              ]}
            >
              <Input placeholder="Title" />
            </Form.Item>
          </Col>
          <Col md="6">
            <div className="input-form-label">Pricing method*</div>
            <Form.Item name={"price_method"}>
              <Select
                style={{ width: "100%" }}
                allowClear
                placeholder="Pricing method"
              >
                <Select.Option value="Fixed">Fixed Rate</Select.Option>
                <Select.Option value="Live">Live Rate</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col md="6">
            <div className="input-form-label">Product Status</div>
            <Form.Item
              name={"status"}
              rules={[
                {
                  required: true,
                  message: "Please Choose the Status of The product",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                className="border rounded"
                allowClear
                //   onChange={categoryChangeHandler}
                placeholder="Select Status"
              >
                <Select.Option value={"available"}>Available</Select.Option>
                <Select.Option value={"notavailable"}>
                  Not Available
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col md="6">
            <div className="input-form-label">Stock</div>
            <Form.Item
              name={"unit"}
              rules={[
                {
                  required: true,
                  message: "Enter Available Units.",
                },
              ]}
            >
              <Input placeholder="Unit" type="number" max={10000} />
            </Form.Item>
          </Col>
          <Col md="6">
            <div className="input-form-label">Retail Price</div>
            <Form.Item
              name={"retail_rate"}
              rules={[
                {
                  required: true,
                  message: "Enter Reail Rate of the product",
                },
              ]}
            >
              <Input placeholder="0.00" type="number" max={1000000} />
            </Form.Item>
          </Col>
          <Col md="6">
            <div className="input-form-label">Gross Weight* (Gm)</div>
            <Form.Item
              name={"weight"}
              rules={[{ required: true, message: "Please Enter Weight" }]}
            >
              <Input type="number" placeholder="Weight" />
            </Form.Item>
          </Col>
          <Col md="6">
            <div className="input-form-label">Making Charge(%)</div>
            <Form.Item
              name={"labour_charge"}
              rules={[{ required: true, message: "Please Enter charge" }]}
            >
              <Input type="number" placeholder="Making Charg" />
            </Form.Item>
          </Col>
          {/* <Col md="6">
            <div className="input-form-label">Premium</div>
            <Form.Item
              name={"premium"}
              rules={[{ required: true, message: "Please Enter premium" }]}
            >
              <Input type="number" placeholder="Premium" />
            </Form.Item>
          </Col> */}
        </Row>
      </Form>
    </Modal>
  );
}

export default QuickEditModal;
