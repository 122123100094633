import SaleMessage from "../../components/saleMessage";
import SubscribeNewsletter from "../../components/subscribeNewsletter";
import PageHeader from "../../components/pageHeader";
import React, { useEffect } from "react";
import API from "../../config/API";
function ReturnandExchangePolicy() {
  return (
    <div className="Screen-box">
      <div
        style={{
          fontWeight: 700,
          fontSize: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        Ooops! We Are Under Maintanance
      </div>
    </div>
  );
}
export default ReturnandExchangePolicy;
