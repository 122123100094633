import React, { useEffect, useState } from "react";
import { notification } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import Loading from "../../components/loading";
import API from "../../config/API";
import { POST } from "../../utils/apiCalls";
import { useDispatch, useSelector } from "react-redux";
import { logout, update } from "../../redux/slices/userSlice";
import { Container, Row } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import "./styles.scss";
import { clearCart } from "../../redux/slices/cartSlice";
import { clearSettings } from "../../redux/slices/settingsSlice";
import { clearLocation } from "../../redux/slices/locationSlice";
import { clearStore } from "../../redux/slices/storeSlice";
import { logoutChannel } from "../../logout";

function AccountDeactivation() {
  const { token } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [deactivated, setDeactivated] = useState(false);
  const [notificationApi, contextHolder] = notification.useNotification();
  const User = useSelector((state: any) => state.User.user);
  const Auth = useSelector((state: any) => state.User);
  const dispatch = useDispatch();

  const deactivateAccount = async () => {
    if (token) {
      const url = API.USER_DEACTIVATE_EMAIL;
      try {
        const response: any = await POST(url, {
          token: token,
        });
        if (response.status === true) {
          notificationApi.success({message:response?.message});
          if (Auth.auth && User?.data?._id === response?.data?._id) {
            dispatch(update(response?.data));
          }
          setTimeout(() => {
            logoutChannel.postMessage("Logout");
            dispatch(logout(null));
            dispatch(clearCart(null));
            dispatch(clearStore(null));
            navigate("/");
          }, 2000);
        } else {
          notificationApi.error({message:response?.message});
        }
      } catch (error) {
        notificationApi.error({message:`Failed to deactivate your account. Please try again.`});
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setDeactivated(true);
    }, 3000);
    deactivateAccount();
  }, []);

  return (
    <div className="Screen-box">
      {contextHolder}
      <Container className="d-flex justify-content-center">
        <Row className="deactivateAccount-card m-5">
          <MdDelete size={100} color={API.COLOR} />
          <h1 className="deactivateAccount-text1">
            {deactivated ? "Account Deactivated!" : "Deactivating..."}
          </h1>
          <p className="deactivateAccount-text2">
            {deactivated
              ? "Your account has been successfully deactivated."
              : "Please wait while we deactivate your account."}
          </p>
        </Row>
      </Container>
    </div>
  );
}

export default AccountDeactivation;
