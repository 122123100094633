import { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import "./styles.scss";

import API from "../../config/API";
import { GET } from "../../utils/apiCalls";

import Loader from "./components/laoder";
import Banners from "./components/banners";
import Offers from "./components/offers";
import PopularItems from "./components/popularItems";
import Loading from "../../components/loading";
import SubCategoryList from "./components/subCategoryList";
import SEO from "../../components/seo";
import React from "react";
import TopSellingStore from "./components/TopSellingStore";
import { LocationType } from "../../shared/types/types";
import { useTranslation } from "react-i18next";
// import BlogsAndNews from "./components/blogsAndNews";
import GoldPrice from "./components/goldPrice";

function HomeSCreen() {
  const { t } = useTranslation();
  const Category = useSelector((state: any) => state.Category.categries);
  const Offerses = useSelector((state: any) => state.Category.offers);
  const [subCategories, setSubcategories] = useState<any[]>([]);
  const Settings = useSelector((state: any) => state.Settings.Settings);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const Location = useSelector((state: any) => state.Location.location);
  const lattitude = Settings?.isLocation === true ? Location.latitude : "";
  const longitude = Settings?.isLocation === true ? Location.longitude : "";
  const LocationDetails: LocationType = useSelector(
    (state: any) => state.Location.location
  );

  const [Banner, setBanners] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const getRecommendations = async () => {
    const url =
      API.PRODUCT_RECOMMENDATIONS +
      `order=ASC&price=ASC&lat=${lattitude}&long=${longitude}&take=20&radius=${Settings?.radius}`;
    try {
      setLoading(true);
      const response: any = await GET(url, null);
      if (response.status) {
        setProducts(response.data);
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getRecommendations();
    getBanners();
    getBlogs();
    window.scrollTo(0, 0);
  }, [lattitude]);

  const getAllSubcategories = async () => {
    const subcategories: any[] = [];
    const category_subcategory: string[] = [];
    Category?.forEach((item: any) => {
      category_subcategory.push(item.name);
      item?.sub_categories?.forEach((item: any) => {
        subcategories.push(item);
        category_subcategory.push(item.name);
      });
    });
    setSubcategories(subcategories);
  };

  const getBanners = async () => {
    const url = API.BANNER_ALL + `?order=ASC&page=1&take=10`;
    try {
      const banners: any = await GET(url, null);
      if (banners.status) {
        setBanners(banners.data);
      }
    } catch (err) {
      console.log("failed to get banneers");
    }
  };

  useMemo(() => {
    getAllSubcategories();
  }, [Category]);
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const getBlogs = async () => {
    try {
      let url = API.GET_LAST_3_BLOGS;
      let response: any = await GET(url, null);
      if (response.status) {
        setBlogs(response?.data);
      }
    } catch (err) {
      console.log("failed to get blogs");
    }
  };
  return (
    <div>
       {/* <div className="GoldPrice-main">
    </div> */}
      <SEO
        title={API.NAME}
        description={`${API.NAME}! The leading powerhouse retailer of UAE`}
      />
      {Banner?.length ? <Banners data={Banner.slice(0, 3)} /> : null}
      <GoldPrice />
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="HomeSCreen-space" />
          <div className="HomeSCreen-space" />
          {subCategories?.length ? (
            <div className="category-cover">
              <SubCategoryList data={subCategories} />
            </div>
          ) : null}
          <div className="HomeSCreen-space" />
          <div className="HomeSCreen-space" />
          {Offerses?.length >= 2 ? (
            <Offers data={Offerses?.slice(0, 2)} />
          ) : null}
          <div className="HomeSCreen-space" />
          {LocationDetails.latitude &&
          LocationDetails.longitude &&
          Settings?.isLocation &&
          Settings?.type === "multi" ? (
            <>
              <TopSellingStore />
            </>
          ) : null}
          {loading ? (
            <Loading />
          ) : products.length > 11 ? (
            <>
              <PopularItems
                type={2}
                data={products?.slice(11, 20)}
                title={t("top_products")}
              />
            </>
          ) : null}
          {loading ? (
            <Loading />
          ) : products.length ? (
            <>
              <div className="paddings">
                <PopularItems
                  type={2}
                  data={products?.slice(0, 10)}
                  title={t("top_trending")}
                />
              </div>
            </>
          ) : null}
          {loading ? (
            <Loading />
          ) : (
            <div className="paddings">
              {/* <BlogsAndNews blogs={blogs} /> */}
            </div>
          )}
          <br />
        </>
      )}
      <br />
    </div>
  );
}
export default HomeSCreen;
