import PageHeader from "../components/PageHeader";
import { useEffect, useMemo, useState } from "react";
import AdminLoading from "../components/AdminLoading";
import API from "../../config/API";
import DataTable from "./DataTable";
import { Button, Select, notification } from "antd";
import { useNavigate } from "react-router-dom";
import React from "react";
import { useSelector } from "react-redux";
import { GET } from "../../utils/apiCalls";
import Search from "antd/es/input/Search";
import { GrPowerReset } from "react-icons/gr";
import { Container } from "react-bootstrap";
import QuickEditModal from "./quickEdit";

function Products() {
  const navigate = useNavigate();
  type ModalState = "add" | "update";
  const [type, setType] = useState<ModalState>("add");
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const User = useSelector((state: any) => state.User.user);
  const [products, setProducts] = useState([]);
  const [meta, setMeta] = useState<any>({});
  const [searching, setSearching] = useState(false);
  const [reset, setReset] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [Notifications, contextHolder] = notification.useNotification();
  const [take, setTake] = useState(40);
  const [openQuickModal, setQuickModal] = useState(false);
  const [selected, setSelected] = useState<any>({});
  const Category = useSelector((state: any) => state?.Category?.categries);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState("");
  const categories = useMemo(() => {
    if (Array.isArray(Category)) {
      return [
        ...Category?.map((item: any) => ({
          value: item?.id,
          label: item?.name,
        })),
        { value: "", label: "All Categories" },
      ];
    }
    return [];
  }, [Category]);
  const subcategories = useMemo(() => {
    const subc: any[] = [];
    if (Array.isArray(Category)) {
      for (const item of Category) {
        if (Array.isArray(item?.sub_categories)) {
          for (const sub of item?.sub_categories) {
            subc.push({ value: sub?._id, label: sub?.name });
          }
        }
      }
      subc.push({ value: "", label: "All Subcategories" });
    }
    return subc;
  }, [Category]);
  const fetchProducts = async (currentP: number = 1, take: number = 40) => {
    setIsLoading(true);
    const url = `${API.PRODUCTS_BYSTORE}?order=ASC&page=${currentP}&take=${take}&name=${search}&category=${selectedCategory}&subCategory=${selectedSubcategory}`;
    try {
      const response: any = await GET(url, null);
      if (response?.status) {
        setProducts(response?.data);
        setMeta(response?.meta);
      } else {
        Notifications["error"]({
          message: response.message ?? "",
        });
      }
    } catch (err) {
      Notifications["error"]({
        message: "Something went wrong.",
      });
    } finally {
      setIsLoading(false);
      setSearching(false);
    }
  };

  const changePage = async (page: number, take: number) => {
    setPage(page);
    setTake(take);
    await fetchProducts(page);
  };
  const onSearch = async (value: string) => {
    if (value) {
      setPage(1);
      setSearch(value);
      setReset(true);
      setSearching(true);
      // await fetchProducts(1, value);
    }
  };
  useEffect(() => {
    fetchProducts();
  }, [search, selectedCategory, selectedSubcategory]);

  return (
    <div>
      {contextHolder}
      <PageHeader
        title={`Products ${!isLoading ? `(${meta?.itemCount ?? "0"})` : ""}`}
      >
        <Select
          defaultValue="All Category"
          style={{ width: 200 }}
          options={categories}
          onChange={(v) => {
            setSelectedCategory(v);
          }}
        />
        <Select
          defaultValue="All Subcategory"
          style={{ width: 200 }}
          options={subcategories}
          onChange={(v) => {
            setSelectedSubcategory(v);
          }}
        />
        <Search
          placeholder="Search Product"
          allowClear
          enterButton="Search"
          size="middle"
          onSearch={onSearch}
          loading={searching}
        />
        {reset ? (
          <Button
            type="primary"
            icon={<GrPowerReset />}
            size={"middle"}
            onClick={() => {
              fetchProducts();
              setReset(false);
              setPage(1);
              setSearch("");
            }}
          />
        ) : null}
        <Button onClick={() => navigate("/auth/add-products")} type="primary">
          Add Product +
        </Button>
      </PageHeader>
      <Container>
        {isLoading ? (
          <AdminLoading />
        ) : (
          <DataTable
            data={products}
            getProducts={fetchProducts}
            changeType={() => setType("update")}
            page={page}
            pageSize={pageSize}
            take={take}
            changePage={changePage}
            meta={meta}
            setQuickModal={() => setQuickModal(true)}
            setSelected={setSelected}
          />
        )}
      </Container>
      <QuickEditModal
        open={openQuickModal}
        close={() => {
          setQuickModal(false);
          setSelected({});
        }}
        fetchProducts={() => fetchProducts(page)}
        data={selected}
      />
    </div>
  );
}
export default Products;
