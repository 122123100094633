import { Avatar, Card, Tag } from "antd";
import Meta from "antd/es/card/Meta";
import moment from "moment";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

function ProductItems(props: any) {
  const Settings = useSelector((state: any) => state.Settings.Settings);

  const getVariantData = (data: any) => {
    let variantss = "";
    if (Array.isArray(data?.combination) == true) {
      data?.combination.map((item: any) => {
        variantss += `${item.value} `;
      });
    }
    return variantss;
  };
  return (
    <div>
      {Array.isArray(props?.data?.orderItems) == true
        ? props?.data?.orderItems.map((item: any) => (
            <Card bordered={false} className="mt-2">
              <Row>
                <Col md="6">
                  <Meta
                    avatar={
                      <Avatar src={item.image} size={80} shape="square" />
                    }
                    title={
                      <div className="text-capitalize">{`${item.name} `}</div> //${getVariantData(item?.variantDetails)}
                    }
                    description={
                      <div className="text-dark">
                        <div>
                          Seller: {props?.data?.storeDetails?.store_name}
                        </div>

                        <div>
                          Ordered on:{" "}
                          {moment(item.createdAt).format("DD/MM/YYYY")}
                        </div>
                      </div>
                    }
                  />
                </Col>
                <Col md="6">
                  <div>Quantity: {item?.quantity}</div>
                  <div>
                    Each:
                    {Number(item?.price)?.toFixed(2)} {Settings?.currency}
                  </div>
                  <h6 className="text-dark fw-bold my-0">
                    Total:
                    {Number(item?.totalPrice)?.toFixed(2)} {Settings?.currency}
                  </h6>
                </Col>
              </Row>
              {item?.variantId ? (
                <div className="mt-2">
                  {Array.isArray(item?.variantDetails?.combination) == true ? (
                    <div>
                      {item?.variantDetails?.combination?.map((item: any,key:number) => {
                        return (
                          <Tag bordered={false} key={key}>
                            <span>{`${item.variant}: ${item?.value} `}</span>
                          </Tag>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              ) : null}
            </Card>
          ))
        : null}
    </div>
  );
}

export default ProductItems;
