import "./styles.scss";
import { Col, Container, Row } from "react-bootstrap";
import PageSider from "./components/pageSider";
import PageHeader from "./components/pageHeader";
import ProductItem from "../../components/Product_Item";
import API from "../../config/API";
import { GET } from "../../utils/apiCalls";
import { useEffect, useState } from "react";
import Loading from "../../components/loading";
import NoData from "../../components/noData";
import { Pagination, Select, Space, Tag, notification } from "antd";
import { useLocation, useSearchParams } from "react-router-dom";
import useDidUpdateEffect from "../../shared/hook/useDidUpdate";
import React from "react";
import { useSelector } from "react-redux";
import MultiSearchProductList from "../../components/multiSearchCard/productSlider";
import { useTranslation } from "react-i18next";
const options = [
  { value: "gold24", label: "999 Gold" },
  { value: "gold22", label: "916 Gold" },
  { value: "gold21", label: "875 Gold" },
  { value: "gold18", label: "750 Gold" },
  { value: "silver", label: "Silver" },
  { value: "", label: "All Type" },
];
const metalTypes: any = {
  gold24: "999 Gold",
  gold22: "916 Gold",
  gold21: "875 Gold",
  gold18: "750 Gold",
  silver: "Silver",
};
const getCategoryId = (cid: any): string => {
  try {
    return window.atob(String(cid));
  } catch (err) {
    return "0";
  }
};

const ProductByCategory = (props: any) => {
  const { t } = useTranslation();
  const pageSize = 48;
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [Notifications, contextHolder] = notification.useNotification();
  const [searchParams, setSearchParams] = useSearchParams();
  const [meta, setMeta] = useState<any>({});
  const Settings = useSelector((state: any) => state.Settings.Settings);
  const Location = useSelector((state: any) => state.Location.location);
  const lattitude = Settings?.isLocation === true ? Location?.latitude : "";
  const longitude = Settings?.isLocation === true ? Location?.longitude : "";
  const location = useLocation();
  const types = location?.state?.val;
  const [metalType, setMetalType] = useState(
    searchParams.get("metalType") ?? ""
  );
  const [page, setPage] = useState(
    Number(searchParams?.get("page")) > 0 ? Number(searchParams.get("page")) : 1
  );
  const categoryId = getCategoryId(searchParams.get("id"));
  const typeId = searchParams?.get("tid") ?? 0;
  const [initial, setInitial] = useState(true);
  const initialValues = [
    {
      status: searchParams.get("order") === "DESC" ? true : false,
      value:
        searchParams.get("order") === "ASC" ||
        searchParams.get("order") === "DESC"
          ? searchParams.get("order")
          : "ASC",
      title: `${t("New")}`,
    },
    {
      status:
        searchParams.get("price") === "DESC" &&
        searchParams.get("order") === "ASC"
          ? true
          : false,
      value: "ASC",
      title: `${t("Price_High_to_Low")}`,
    },
    {
      status:
        searchParams.get("price") === "ASC" &&
        searchParams.get("order") === "ASC"
          ? true
          : false,
      value: "ASC",
      title: `${t("Price_Low_to_High")}`,
    },
  ];
  const [selectedTags, setSelectedTags] = useState<any>(initialValues);

  const getProductsBySubCategory = async (page: number) => {
    const price =
      selectedTags[1].status === true
        ? "DESC"
        : selectedTags[2].status === true
        ? "ASC"
        : "RAND";
    const order = selectedTags[0].value;
    const url =
      Settings?.type === "multi"
        ? API.STORE_SEARCH_BYSUBCATEGORY +
          `?category=${categoryId}&order=${order}&price=${price}&page=${page}&take=${pageSize}&lat=${lattitude}&long=${longitude}&radius=${Settings?.radius}`
        : API.PRODUCT_SEARCH +
          `bysubcategory?id=${categoryId}&order=${order}&page=${page}&take=${pageSize}&price=${price}&metalType=${metalType}&tid=${typeId}`;
    if (categoryId) {
      try {
        setLoading(true);
        const response: any = await GET(url, null);
        if (response?.status) {
          setProducts(response?.data);
          setMeta(response?.meta);
        } else {
          setProducts([]);
          setMeta({});
        }
      } catch (err: any) {
        Notifications["error"]({
          message: `${"Something_went_wrong"}`,
          description: err.message,
        });
        setProducts([]);
      } finally {
        setLoading(false);
        setInitial(false);
      }
    } else {
      Notifications["error"]({
        message: `${"Something_went_wrong"}`,
      });
    }
  };

  const handleChange = (index: number) => {
    const array = [...selectedTags];
    const findex = array.findIndex((item: any) => item.status === true);
    if (findex !== -1 && findex !== index) {
      array[findex].status = false;
      array[findex].value = "ASC";
    }
    array[index].status = !array[index].status;
    array[index].value = array[index].status ? "DESC" : "ASC";
    setSelectedTags(array);
    const price =
      array[1].status === true
        ? "DESC"
        : array[2].status === true
        ? "ASC"
        : "RAND";
    setSearchParams((searchParams) => {
      searchParams.set("order", array[0].value);
      searchParams.set("price", price);
      return searchParams;
    });
  };

  const changePage = async (page: number, take: number) => {
    await getProductsBySubCategory(page);
    setPage(page);
    searchParams.set("page", String(page));
    setSearchParams(searchParams);
    window.scrollTo(0, 0);
  };

  useDidUpdateEffect(() => {
    getProductsBySubCategory(page);
    window.scrollTo(0, 0);
  }, [selectedTags, metalType,typeId]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getProductsBySubCategory(1);
    setPage(1);
  }, [categoryId]);

  return (
    <div className="Screen-box">
      {contextHolder}
      <Container fluid>
        <Row>
          <Col sm={2} xs={12} style={{ margin: 0, padding: 0 }}>
            <div className="productByCat-PageSider">
              <PageSider  categoryId={categoryId}/>
            </div>
          </Col>
          <Col sm={10} xs={12} style={{ margin: 0 }}>
            <PageHeader
              title={searchParams.get("type")}
              page={page}
              pageSize={pageSize}
              meta={meta}
              initial={initial}
              type={Settings?.type}
              count={products?.length}
              types={types}
              categoryId={categoryId}
            >
              <Space wrap>
                <Select
                  defaultValue={
                    metalTypes[searchParams.get("metalType") ?? ""] ??
                    "Select Type "
                  }
                  style={{ width: 120, marginRight: "10px" }}
                  size="small"
                  options={options}
                  onChange={(e) => {
                    setMetalType(() => e);
                    setSearchParams((searchParams) => {
                      searchParams.set("metalType", e);
                      return searchParams;
                    });
                  }}
                />
              </Space>

              <Space wrap>
                {selectedTags.map((tag: any, i: number) => (
                  <Tag
                    color={selectedTags[i].status ? API.COLOR : ""}
                    style={{ cursor: "pointer" }}
                    key={i}
                    onClick={() => handleChange(i)}
                  >
                    {tag.title}
                  </Tag>
                ))}
              </Space>
            </PageHeader>
            {loading ? (
              <Loading />
            ) : products?.length ? (
              Settings.type === "multi" ? (
                products.map((item: any) => {
                  return (
                    <MultiSearchProductList
                      data={item}
                      type="category"
                      cid={categoryId}
                      cname={searchParams.get("type")}
                    />
                  );
                })
              ) : (
                <Row>
                  {products?.map((item: any, i: number) => (
                    <Col sm={3} xs={6} key={i} style={{ marginBottom: 20 }}>
                      <ProductItem item={item} />
                    </Col>
                  ))}
                </Row>
              )
            ) : (
              <NoData
                header={"No Results"}
                text1={`No Results for "${searchParams.get("type")}"`}
              />
            )}
            <br />
            <Pagination
              current={page}
              pageSize={pageSize}
              total={meta?.itemCount || 0}
              defaultCurrent={1}
              responsive={true}
              defaultPageSize={pageSize}
              disabled={false}
              hideOnSinglePage={true}
              onChange={(current: any, size: any) => changePage(current, size)}
            />
            <br />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProductByCategory;
