import React, { useEffect, useState } from "react";
import DataTable from "./dataTable";
import { CorporateRegisterType } from "../../shared/types/types";
import { GET } from "../../utils/apiCalls";
import { Button, notification } from "antd";
import { useNavigate } from "react-router-dom";
import PageHeader from "../components/PageHeader";
import API from "../../config/API";
import AdminLoading from "../components/AdminLoading";
import NoData from "../../components/noData";
import Search from "antd/es/input/Search";
import { GrPowerReset } from "react-icons/gr";

function Sellers() {
  const [store, setStore] = useState<CorporateRegisterType[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [Notifications, contextHolder] = notification.useNotification();
  const [meta, setMeta] = useState<any>({});
  const pageSize = 12;
  const [page, setPage] = useState<number>(1);
  const [searching, setSearching] = useState(false);
  const [reset, setReset] = useState(false);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const getSellerDetails = async (
    currentPage: number = 1,
    search: string = ""
  ) => {
    setLoading(true);
    try {
      const url =
        API.CORPORATE_STORE_GETALL +
        `/all?order=DESC&page=${currentPage}&take=${pageSize}&name=${search}`;
      const data: any = await GET(url, null);
      if (data?.status) {
        setStore(data.data);
        setMeta(data?.meta);
      } else {
        Notifications["error"]({
          message: data.message ?? "",
        });
      }
    } catch (err: any) {
      Notifications["error"]({
        message: "Something went wrong",
        description: err.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const viewDetailsPage = (record: any) => {
    navigate(`/auth/seller/${record.id}`);
  };

  const changePage = async (page: number) => {
    await getSellerDetails(page,search);
    setPage(page);
  };
  const onSearch = async (value: string) => {
    if (value) {
      setSearch(value);
      setReset(true);
      setSearching(true);
      await getSellerDetails(1, value);
      setPage(1);
      setSearching(false);
    }
  };
  useEffect(() => {
    getSellerDetails();
  }, []);

  return (
    <>
      {contextHolder}
      <PageHeader
        title={`Sellers ${isLoading ? "" : `(${meta?.itemCount ?? 0})`}`}
      >
        <Search
          placeholder="Search Store Name/Phone/Email"
          allowClear
          enterButton="Search"
          size="middle"
          onSearch={onSearch}
          loading={searching}
        />
        {reset ? (
          <Button
            type="primary"
            icon={<GrPowerReset />}
            size={"middle"}
            onClick={() => {
              getSellerDetails();
              setReset(false);
              setPage(1);
              setSearch("");
            }}
          />
        ) : null}
      </PageHeader>
      {isLoading ? (
        <AdminLoading />
      ) : store.length ? (
        <DataTable
          data={store}
          changePage={changePage}
          meta={meta}
          pageSize={pageSize}
          viewDetailsPage={viewDetailsPage}
          page={page}
        />
      ) : (
        <NoData header={"No Sellers Found"} />
      )}
    </>
  );
}

export default Sellers;
