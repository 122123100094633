import React, { useEffect, useRef, useState } from "react";
import { DatePicker, InputNumber, Modal } from "antd";
import { Button, Form, Input, notification } from "antd";
import { COMPRESS_IMAGE, POST, PUT } from "../../utils/apiCalls";
import API from "../../config/API";

import ImagePicker from "../components/ImagePicker";
import { useSelector } from "react-redux";
import moment from "moment";
import Cropper, { ReactCropperElement } from "react-cropper";


const update = "update";
const OffersModal = (props: any) => {
  const [form] = Form.useForm();
  const [Notifications, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const cropperRef = useRef<ReactCropperElement>(null);
  const [aspectRatio, setAspectRatio] = useState(0);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const fileInputRef = useRef(null);
  const type = props?.type;
  const [image, setImage] = useState<any>({});
  const User = useSelector((state: any) => state.User.user);
  useEffect(() => {
    const date = new Date(props?.data?.startDate).toLocaleDateString();
    if (type == update) {
      form.setFieldsValue({
        title: props?.data?.title,
        percentage: props?.data?.percentage,
        amount: props?.data?.amount,
        startDate: moment(props?.data?.startDate),
        endDate: moment(props?.data?.endDate),
        image: props?.data?.image,
      });
    } else {
      form.resetFields();
    }
  }, [props.data, type]);

  const formSubmitHandler = async (values: any) => {
      const url = type === "update" ? API.OFFERS + props?.data?.id : API.OFFERS;
      setIsLoading(true);
  
      try {
          let imageUrl: any = "";
          let response: any;
  
          if (croppedImage) {
              const ImageBlob = await fetch(croppedImage).then((r) => r.blob());
              let name = moment(new Date()).unix();
              let file = new File([ImageBlob], name + "N.jpg");
              imageUrl = await COMPRESS_IMAGE(file);
  
              const obj = {
                  ...values,
                  image: imageUrl?.url,
              };
  
              response = type === "update" ? await PUT(url, obj) : await POST(url, obj);
  
              if (response.status) {
                  Notifications["success"]({
                      message: "Success",
                      description: `Successfully ${type === "update" ? "updated" : "added"} the item`,
                  });
  
                  form.resetFields();
                  props?.modalClose();
                  props?.getOffers(props?.page);
                  setCroppedImage(null);
                  setImage({});
              } else {
                  throw new Error(`Failed to ${type === "update" ? "update" : "add new item"}`);
              }
          } else {
              throw new Error("No cropped image available");
          }
      } catch (err: any) {
          Notifications["error"]({
              message: `Failed to ${type === "update" ? "update" : "add new item"}`,
              description: err.message || "An error occurred",
          });
      }
      setIsLoading(false);
  };
  

  
  const handleCrop = async () => {
    if (cropperRef.current) {
      const canvas: HTMLCanvasElement | null =
        cropperRef.current.cropper.getCroppedCanvas({
          fillColor: "#fff",
        });
      if (canvas) {
        const croppedData = canvas.toDataURL("image/jpeg");
        setCroppedImage(croppedData);
        setCropModalOpen(false);
        try {
          // const values = await form.validateFields();
          // formSubmitHandler(values);
        } catch (error) {
          console.error("Form validation failed:", error);
        }
      }
    }
  };
  return (
    <Modal
      title={`${type == update ? "Update" : "Add New"} Offer`}
      open={props?.open}
      onOk={form.submit}
      onCancel={() => {
        props?.modalClose();
        setImage({});
        setCropModalOpen(false);

      }}
      okText="Update"
      centered
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
    >
      {contextHolder}
      <Form
        form={form}
        style={{ maxWidth: 600 }}
        layout="vertical"
        onFinish={formSubmitHandler}
      >
        <>
        {cropModalOpen ? (
          <div className="mt-2">
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(e) => {
                const selectedFile = e.target.files?.[0];
                if (selectedFile) {
                  setImage({
                    file: selectedFile,
                    url: URL.createObjectURL(selectedFile),
                  });
                  setCropModalOpen(true);
                }
              }}
            />
            <Cropper
              ref={cropperRef as React.RefObject<ReactCropperElement>}
              src={image?.url}
              style={{ height: 400, width: "100%" }}
              aspectRatio={aspectRatio}
              guides={true}
            />
            <div style={{ textAlign: "center", marginTop: 16 }}>
              <Button type="primary" onClick={handleCrop}>
                Crop Image
              </Button>
              <Button
                style={{ marginLeft: 16 }}
                onClick={() => {
                  if (fileInputRef.current) {
                    (fileInputRef.current as any).click();
                  }
                }}
              >
                Choose Another Image
              </Button>
            </div>
          </div>
        ) : (
        <Form.Item
          name={"image"}
          label="Image"
          rules={[
            {
              required: true,
              message: "Please Select Image",
            },
          ]}
        >
          <ImagePicker
            onChange={(file: any) => {
              setImage(file);
              setCropModalOpen(true);
            }}
            fileURL={
              image?.url
                ? image.url
                : type == update
                ? props?.data?.image
                : null
            }
          />
        </Form.Item>
         )}
        <Form.Item
          label="Title"
          name="title"
          rules={[
            {
              required: true,
              message: "Please Enter Title",
            },
          ]}
        >
          <Input placeholder="Title" />
        </Form.Item>
        <Form.Item
          label="Percentage"
          name="percentage"
          rules={[
            {
              required: true,
              message: "Please Enter Percentage",
              type: "number",
            },
          ]}
        >
          <InputNumber className="w-100" />
        </Form.Item>
        <Form.Item
          label="Amount"
          name="amount"
          rules={[
            {
              required: true,
              message: "Please Enter Amount",
            },
          ]}
        >
          <InputNumber className="w-100" />
        </Form.Item>
        <Form.Item
          label="Start Date"
          name="startDate"
          rules={[
            {
              required: true,
              message: "Please Select Start Date",
            },
          ]}
        >
          <DatePicker
            className="w-100"
            placeholder="DD/MM/YYYY"
            allowClear={false}
          />
        </Form.Item>
        <Form.Item
          label="End Date"
          name="endDate"
          rules={[
            {
              required: true,
              message: "Please Select End Date",
            },
          ]}
        >
          <DatePicker
            className="w-100"
            placeholder="DD/MM/YYYY"
            allowClear={false}
          />
        </Form.Item>
        <div className="d-flex gap-2 justify-content-end">
          <Button
            onClick={() => {
              props?.modalClose();
              setImage({});
            }}
          >
            Cancel
          </Button>
          <Button type="primary" loading={isLoading} onClick={form.submit}>
            {type === update ? "Update" : "Add"}
          </Button>
        </div>
      </>
      </Form>
    </Modal>
  );
};

export default OffersModal;
