import SaleMessage from "../../components/saleMessage";
import SubscribeNewsletter from "../../components/subscribeNewsletter";
import easypaymentimg from "../../assets/images/shopandenjoy.webp";
import bankfabimg from "../../assets/images/bankfab.com.png";
import abudabicommercialimg from "../../assets/images/Abu Dhabi Commercial Bank.png";
import dubaiislamicimg from "../../assets/images/dlf.pt-islam-png-5583678.png";
import commercialbankimg from "../../assets/images/pngfind.com-may-png-2277529.png";
import abudhabiislamicimg from "../../assets/images/pngkey.com-booking-com-logo-png-2805484 (1).png";
import ajmanbankimg from "../../assets/images/208396.svg";
import CBIimg from "../../assets/images/Daco_2121546.png";
import Cityimg from "../../assets/images/citibank-png-logo-brands-4776.png";
import dubaifestimg from "../../assets/images/dubaiimage.png";
import EmiratesIslamicimg from "../../assets/images/emiratesimage.png";
import emiresnboimg from "../../assets/images/emiratesnbd.jpg";
import financehouseimg from "../../assets/images/financeimage.png";
import nbfimg from "../../assets/images/nbfimagejpeg.jpeg";
import rakbakimage from "../../assets/images/rakbankimage.jpg";
import standardchrtrimg from "../../assets/images/Standard_Chartered.png";
import hbfcimg from "../../assets/images/hsbcimage.jpg";
import mashreqimg from "../../assets/images/mashrekimage.png";
import najmimg from "../../assets/images/najmimage.jpg";
import { Col, Row } from "react-bootstrap";
import "./styles.scss";
import React from "react";
import { useTranslation } from "react-i18next";

function EasyPaymentPlan() {
  const { t } = useTranslation();
  const data = [
    {
      bankName: `${t("Abu_Dhabi_Commercial_Bank")}`,
      duration: `${t("3_months")}`,
      minPurchase: `${t("Otp_has_been_Expired")}`,
      note: `${t("Not_valid_on")}`,
      imageUrl: abudabicommercialimg,
    },
    {
      bankName: `${t("Abu_Dhabi_Islamic_Bank")}`,
      duration: `${t("3_6_months")}`,
      minPurchase: `${t("Min_Purchase_AED_1000")}`,
      imageUrl: abudhabiislamicimg,
    },
    {
      bankName: `${t("FAB")}`,
      duration: `${t("3_6_months")}`,
      minPurchase: `${t("Min_Purchase_AED_1000")}`,
      note: `${t("Charges_and_terms")}`,
      imageUrl: bankfabimg,
    },
    {
      bankName: `${t("Ajman_Bank")}`,
      duration: `${t("3_6_months")}`,
      minPurchase: `${t("Min_Purchase_AED_1000")}`,
      imageUrl: ajmanbankimg,
    },
    {
      bankName: `${t("Commercial_Bank_International")}`,
      duration: `${t("3_6_12_months")}`,
      minPurchase: `${t("Min_Purchase_AED_500")}`,
      note: `${t("Processing_fee_of")}`,
      imageUrl: CBIimg,
    },`${t("Otp_has_been_Expired")}`,
    {
      bankName: `${t("Citibank")}`,
      duration: `${t("3_months")}`,
      minPurchase: `${t("Min_Purchase_AED_1000")}`,
      note: `${t("To_apply_submit_the")}`,
      Linktxt: `${t("www_citibank_aezeropercent")}`,
      note2: `${t("In_store_only")}`,
      imageUrl: Cityimg,
    },
    {
      bankName: `${t("Commercial_Bank_of_Dubai")}`,
      duration: `${t("3_6_12_months")}`,
      minPurchase: `${t("Min_Purchase_AED_500")}`,
      imageUrl: commercialbankimg,
      note3: `${t("Processing_fee_Months")}`,
      note4: `${t("Processing_fee_2_Months")}`,
      note5: `${t("Processing_fee_3_Months")}`,
    },
    {
      bankName: `${t("Dubai_First")}`,
      duration: `${t("3_6_9_12_months")}`,
      minPurchase: `${t("Min_Purchase_AED_500")}`,
      note: `${t("Processing_fee_of_1_on_3_6_months")}`,
      note6: `${t("Processing_fee_of_2_on_9_months")}`,

      imageUrl: dubaifestimg,
    },
    {
      bankName: `${t("Dubai_Islamic_bank")}`,
      duration: `${t("6_months")}`,
      minPurchase: `${t("Min_Purchase_AED_1000")}`,
      note: `${t("SMS_EPP_to")}`,
      imageUrl: dubaiislamicimg,
    },
    {
      bankName: `${t("Emirates_Islamic")}`,
      duration: `${t("3_6_months")}`,
      minPurchase: `${t("Min_Purchase_AED_1000")}`,
      note6: `${t("Processing_fee_of_2")}`,
      imageUrl: EmiratesIslamicimg,
    },
    {
      bankName: `${t("Emirates_NBD")}`,
      duration: `${t("6_12_months")}`,
      minPurchase: `${t("Min_Purchase_AED_500")}`,
      Linktxt: `${t("Visit_banks_website")}`,
      note6: `${t("AED_49_processing_fee_applies")}`,
      imageUrl: emiresnboimg,
    },
    {
      bankName: `${t("Finance_House")}`,
      duration: `${t("6_months")}`,
      minPurchase: `${t("Min_Purchase_AED_1000")}`,
      imageUrl: financehouseimg,
    },

    {
      bankName: `${t("HSB")}`,
      duration: `${t("6_months")}`,
      minPurchase: `${t("Min_Purchase_AED_1000")}`,
      note6: `${t("0_Processing_fee_0_Early_Settlement_Fees")}`,
      imageUrl: hbfcimg,
    },
    {
      bankName: `${t("Mashreq")}`,
      duration: `${t("6_9_12_months")}`,
      minPurchase: `${t("Min_Purchase_AED_500")}`,
      note6:
        `${t("Processing_fee")}`,
      imageUrl: mashreqimg,
    },
    {
      bankName: `${t("Najm")}`,
      duration: `${t("3_6_months")}`,
      minPurchase: `${t("Min_Purchase_AED_1000")}`,
      imageUrl: najmimg,
    },
    {
      bankName: `${t("National_Bank_of_Fujairah")}`,
      duration: `${t("3_months")}`,
      minPurchase: `${t("Min_Purchase_AED_500")}`,
      imageUrl: nbfimg,
    },
    {
      bankName: `${t("Rakbank")}`,
      duration: `${t("3_6_9_12_months")}`,
      minPurchase: `${t("Min_Purchase_AED_1000")}`,
      imageUrl: rakbakimage,
      note6: `${t("Processing_fees_of_AED_49_on_3_6_months")}`,
    },
    {
      bankName: `${t("Standard_Chartered")}`,
      duration: `${t("6_months")}`,
      minPurchase: `${t("Min_Purchase_AED_1000")}`,
      imageUrl: standardchrtrimg,
    },
  ];

  return (
    <div className="Screen-box">
      <div>
        <SaleMessage />
      </div>
      <div className="easyPaymentPlan-Box1">
        <img className="easyPaymentPlanimg-1" src={easypaymentimg} />
      </div>
      <div className="easyPaymentPlan-Box2">
        <div className="easyPaymentPlan-Box3">
          <p className="easyPaymentPlan-Txt1">{t("Disclaimer")}</p>
          <p className="easyPaymentPlan-Txt2">
          {t("Easy_Payment_Plans")}
          </p>
        </div>
      </div>
      <div className="easyPaymentPlan-Box4">
        <Row className="g-0">
          {data.map((item:any, index) => (
            <Col key={index} md={4}>
              <div className="easyPaymentPlan-Box5">
                <div className="easyPaymentPlan-Box666">
                  <img className="easyPaymentPlan-img2" src={item.imageUrl} />
                </div>
                <div className="easyPaymentPlan-Box6">
                  <div className="easyPaymentPlan-Txt3">{item.bankName}</div>
                  <div className="easyPaymentPlan-Txt4">{item.duration}</div>
                  <div className="easyPaymentPlan-Txt4">{item.minPurchase}</div>
                  <div className="easyPaymentPlan-Txt5">{item.note}</div>
                  <div className="easyPaymentPlan-Txt5">
                    <a href="">{item.Linktxt}</a>
                  </div>
                  <div className="easyPaymentPlan-Txt6">{item.note2}</div>
                  <div className="easyPaymentPlan-Txt5">{item.note3}</div>
                  <div className="easyPaymentPlan-Txt5">{item.note4}</div>
                  <div className="easyPaymentPlan-Txt5">{item.note5}</div>
                  <div className="easyPaymentPlan-Txt5">{item.note6}</div>
                </div>
              </div>
              <br />
            </Col>
          ))}
        </Row>
      </div>

      <SubscribeNewsletter />
    </div>
  );
}
export default EasyPaymentPlan;
