import { Row, Col } from "react-bootstrap";
import SaleMessage from "../../components/saleMessage";
import brandPromise from "../../assets/images/brandpromise.png";
import SubscribeNewsletter from "../../components/subscribeNewsletter";
import { Collapse } from "antd";
import { TiMinusOutline, TiPlusOutline } from "react-icons/ti";
import React, { useEffect, useState } from "react";
import "./styles.scss";
import PageHeader from "../../components/pageHeader";
import API from "../../config/API";
import { useTranslation } from "react-i18next";

const { Panel } = Collapse;
function BrandPromise() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const toggleTerms = () => {
    setOpen(!open);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="Screen-box">
      <div>
        <SaleMessage />
      </div>
      <Row className="mx-2 ftscreen-fnt">
        <PageHeader text={t("Brand_Promise_Any")} />

        <Col md={12}>
          <img
            className="img-responsive mt-3  w-100"
            src={brandPromise}
            alt="BrandPromise on any Product"
          />
        </Col>
        
          <Col md={2} />
          <Col md={8}>
            <div className="d-flex p-2 mt-5" style={{ textAlign: "justify" }}>
            {t("For_over_a_decade")} {API.NAME} {t("has_been_known_as")}
            </div>
            <div className="d-flex p-2 mt-1" style={{ textAlign: "justify" }}>
            {t("Put_it_simply")} {API.NAME}{t("we’ll_have_it_for.")}
            </div>
            <div
              className="d-flex p-2 mt-1 mb-5"
              style={{ textAlign: "justify" }}
            >
              {t("With_over_5000")}
            </div>
            <div>
              <Collapse defaultActiveKey={[]} onChange={() => {}}>
                <Panel
                  showArrow={false}
                  header={
                    <button className="terms-header" onClick={toggleTerms} >
                      {open ? <TiMinusOutline /> : <TiPlusOutline />} {t("Click_here_to_view")}
                    </button>
                  }
                  key="1"
                >
                  <h5>{t("TERMS_&_CONDITIONS")}</h5>
                  <ul>
                    <li>
                      {t("Product_Brand_should_have")}
                    </li>
                    <li>
                    {t("Color_of_product")}
                    </li>
                    <li>
                    {t("This_scheme_does")} {API.NAME} {t("website_discontinued")}
                    </li>
                    <li>
                    {t("This_scheme_is_valid")}
                    </li>
                    <li>
                    {t("Deadlines_will")}
                    </li>
                    <li>
                    {t("24_hours_refers_to")}
                    </li>
                    <li>
                    {t("A_maximum_of.")}
                    </li>
                    <li>
                      {t("If_the_product")}
                    </li>
                    <li>
                    {t("If_the_desired")}
                      <ul>
                        <li>
                        {t("The_sales_staff")}
                        </li>
                        <li>
                        {t("Customer_will_need")}
                        </li>
                        <li>
                        {t("If_the_customer")} {API.NAME} {t("cannot_be_held")}
                        </li>
                      </ul>
                    </li>
                    <li>
                    {t("Free_delivery_in")}
                    </li>
                    <li>
                    {t("In_case_the_product")}
                      {API.NAME}{t("gift_vouchers")}
                    </li>
                    <li>
                    {t("Standard")} {API.NAME} {t("Exchange_Refund")}
                    </li>
                    <li>
                    {t("This_scheme")} {API.NAME} {t("outlets_across_UAE")}
                    </li>
                    <li>
                    {t("By_accepting_this")} {API.NAME}
                    {t("reserves_the_right_to")}
                    </li>
                  </ul>
                </Panel>
              </Collapse>
            </div>
            <div className="text-center mt-2" style={{ fontSize: "13px" }}>
            {t("For_enquiries")}
            </div>
            <div className="text-center mt-3 fw-bold">{t("Call")} 6238780530 </div>
          </Col>
          <Col md={2} />
        
      </Row>
      <div className="col-12 mt-5">
        <SubscribeNewsletter />
      </div>
    </div>
  );
}
export default BrandPromise;
