import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { VscError } from "react-icons/vsc";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";

import API from "../../config/API";
import { GET, POST } from "../../utils/apiCalls";
import { clearCheckout } from "../../redux/slices/checkoutSlice";

import { Col, Container, Row } from "react-bootstrap";
import { Avatar, Button, List, Spin, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { storeCart } from "../../redux/slices/cartSlice";
const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
function Checkout() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const params: any = useParams();
  const [searchParams] = useSearchParams();
  const Checkout = useSelector((state: any) => state?.Checkout?.order);
  const Settings = useSelector((state: any) => state.Settings.Settings);
  const [isLoading, setIsLoading] = useState<any>(true);
  const [paymentStatus, setPaymentStatus] = useState<any>();
  const [orderStatus, setOrderStatus] = useState<any>();
  const [Notifications, contextHolder] = notification.useNotification();
  const User = useSelector((state: any) => state.User.user);
  const [orderItems, setOrderItems] = useState<any[]>([]);
  const [responseData, setResponseData] = useState<any>({});

  useEffect(() => {
    window.scrollTo(0, 0);
    if (params?.type == 1) {
      PlaceOrder({});
    } else {
      checkPaymentStatus();
    }
  }, []);

  const getOrderItems = (response: any[]) => {
    const array: any[] = [];
    if (Array.isArray(response)) {
      response.forEach((items: any) => {
        if (Array.isArray(items?.orderItems)) {
          items?.orderItems.forEach((item2: any) => {
            array.push(item2);
          });
        }
      });
    }
    setOrderItems(array);
  };

  const checkPaymentStatus = async () => {
    try {
      const response: any = await GET(API.PAYMENT_GATEWAY_GETTOKEN, null);
      if (response.access_token) {
        var ref = searchParams.get("ref");
        let obj = {
          token: response.access_token,
          ref: ref,
        };
        const details: any = await POST(API.PAYMENT_GATEWAY_DETAILS, obj);
        if (details?._id) {
          let data = details?._embedded?.payment[0];
          if (data?.authResponse?.resultCode === "00") {
            let successObj = data?.paymentMethod;
            successObj["ref"] = ref;
            successObj["currencyCode"] = data?.amount?.currencyCode;
            successObj["value"] = data?.amount?.value;
            setPaymentStatus(true);
            PlaceOrder(successObj);
          } else {
            setPaymentStatus(false);
            setIsLoading(false);
            window.history.replaceState({}, "","/");
          }
        } else {
          setPaymentStatus(false);
          setIsLoading(false);
          window.history.replaceState({}, "","/");
          Notifications["error"]({
            message: "Something went wrong with Payment. Please try later",
            description: "",
          });
        }
      } else {
        setPaymentStatus(false);
        setIsLoading(false);
        Notifications["error"]({
          message: "Something went wrong with Payment. Please try later",
          description: "",
        });
      }
    } catch (err) {
      setPaymentStatus(false);
      setIsLoading(false);
      Notifications["error"]({
        message: "Something went wrong with Payment. Please try later",
        description: "",
      });
    }
  };

  const PlaceOrder = async (payment: any) => {
    try {
      let paymentObj = payment;
      paymentObj["type"] = Checkout?.payment;
      var obj = {
        user: Checkout?.user,
        payment: paymentObj,
        cart: Checkout?.cart,
        address: Checkout?.address,
        charges: Checkout?.charges,
      };
      if (Checkout?.cart?.length) {
        const response: any = await POST(API.ORDER, obj);
        if (response?.status) {
          getOrderItems(response?.data);
          setResponseData(response?.data);
          setOrderStatus(true);
          dispatch(clearCheckout());
          loadCartItems();
          setPaymentStatus(true);
          window.history.replaceState({}, "","/");
        } else {
          Notifications["error"]({
            message: response?.message ?? "",
            description: "",
          });
          setPaymentStatus(true);
          setOrderStatus(false);
        }
        setIsLoading(false);
      } else {
        window.history.replaceState({}, "","/");
        navigation("/profile/orders");
      }
    } catch (err) {
      setPaymentStatus(true);
      setOrderStatus(false);
      setIsLoading(false);
      window.history.replaceState({}, "","/");
      Notifications["error"]({
        message: "oops Something went wrong..",
        description: "",
      });
    }
  };

  const loadCartItems = async () => {
    try {
      if (User.data?._id) {
        const cartItems: any = await GET(API.CART_GET_ALL, null);
        if (cartItems.status) {
          dispatch(storeCart(cartItems.data));
          return;
        } else {
        }
      }
    } catch (err) {
      return;
    }
  };
  return (
    <div className="Screen-box">
      {contextHolder}
      <br />
      <Container fluid style={{ minHeight: "80vh" }}>
        {isLoading ? (
          <div className="checkout-box">
            <div className="checkout-txt1">Your Order Processing</div>
            <div className="checkout-txt2">Please do not click back button</div>
            <br />
            <Spin indicator={antIcon} />
          </div>
        ) : paymentStatus ? (
          orderStatus ? (
            <Row>
              <Col sm={8} xs={12}>
                <div className="checkout-box2">
                  <div>
                    <div>
                      <IoIosCheckmarkCircleOutline size={60} color="#15ad4c" />
                    </div>
                    <div className="checkout-txt2" style={{ color: "#15ad4c" }}>
                      Thank You
                    </div>
                    <div className="checkout-txt1">
                      Your Order is Placed Successfully
                    </div>
                    <div className="checkout-txt2">
                      We will be send you an email confirmation to your
                      registered email shortly
                    </div>
                    <br/>
                    <br/>
                    <Button
                      type="link"
                      onClick={() =>
                        navigation("/profile/orders", { replace: true })
                      }
                    >
                      View my Orders.
                    </Button>
                  </div>
                </div>
                <br />
              </Col>
              <Col sm={4} xs={12}>
                <div className="checkout-box3">
                  <div>
                    <div>
                      <div className="checkout-txt3">
                        <div>Order Status : </div>
                        <div style={{color:"orange"}}>{responseData?.[0]?.orderPayment?.status}</div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="checkout-txt3">DELIVERY ADDRESS</div>

                    <div className="checkout-txt4">
                      {responseData?.[0]?.address?.fullAddress ?? ""},
                      {responseData?.[0]?.address?.pin_code ?? ""},
                      {responseData?.[0]?.address?.state ?? ""},
                      {responseData?.[0]?.address?.street ?? ""},<br />
                      {responseData?.[0]?.address?.alt_phone ?? ""}
                    </div>
                  </div>
                  <div>
                    <div className="checkout-txt3">PAYMENT DETAILS</div>
                    <div className="checkout-txt4">
                      Payment Type:{" "}
                      {responseData?.[0]?.orderPayment?.paymentType ?? ""}{" "}
                      Amount: {Settings?.currency}{" "}
                      {responseData?.[0]?.orderPayment?.amount ?? ""}
                    </div>
                  </div>
                  <div className="checkout-txt3">ORDER SUMMARY</div>
                  <div style={{ margin: 10 }}>
                    <List
                      itemLayout="horizontal"
                      dataSource={orderItems}
                      renderItem={(item, index) => (
                        <List.Item key={index}>
                          <List.Item.Meta
                            avatar={
                              <Avatar
                                src={item?.image}
                                size={40}
                                shape="square"
                              />
                            }
                            title={item?.name ?? ""}
                            description={<div>Total: {item?.totalPrice}</div>}
                          />
                        </List.Item>
                      )}
                    />
                    <br />
                    <div className="checkout-row">
                      <div>Total Product Price</div>
                      <div>
                        {Settings?.currency}{" "}
                        {Number(responseData?.[0]?.newOrder?.total).toFixed(2)}
                      </div>
                    </div>
                    <div className="checkout-row">
                      <div>Discount</div>
                      <div>
                        {Settings?.currency}{" "}
                        {Number(responseData?.[0]?.newOrder?.discount).toFixed(
                          2
                        )}
                      </div>
                    </div>
                    <div className="checkout-row">
                      <div>Tax</div>
                      <div>
                        {Settings?.currency}{" "}
                        {Number(responseData?.[0]?.newOrder?.tax).toFixed(2)}
                      </div>
                    </div>
                    <div className="checkout-row">
                      <div>Delivery Charges</div>
                      <div>
                        {Settings?.currency}{" "}
                        {Number(
                          responseData?.[0]?.newOrder?.deliveryCharge
                        ).toFixed(2)}
                      </div>
                    </div>
                    <hr />
                    <div className="checkout-row">
                      <div>Total</div>
                      <div>
                        {Settings?.currency}{" "}
                        {Number(
                          responseData?.[0]?.newOrder?.grandTotal
                        ).toFixed(2)}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          ) : (
            <div className="checkout-box4">
              <div className="checkout-txt1">Order Faild.</div>
              <div className="checkout-txt2">
                We are unable to complete your order. Please try again
              </div>
              <div className="checkout-txt2" style={{ color: "red" }}>
                Any Amount debited from your account will be refunded within 24
                hours
              </div>
              <br />
              <VscError size={50} color="red" />
              <br />
              <Button onClick={() => navigation("/cart", { replace: true })}>
                GO BACK
              </Button>
            </div>
          )
        ) : (
          <div className="checkout-box4">
            <div className="checkout-txt1">Payment Faild.</div>
            <div className="checkout-txt2">
              We are unable to complete your order due to payment failure.
              Please try again
            </div>
            <div className="checkout-txt2" style={{ color: "red" }}>
              Any Amount debited from your account will be refunded within 24
              hours
            </div>
            <br />
            <VscError size={50} color="red" />
            <br />
            <Button onClick={() => navigation("/cart", { replace: true })}>
              GO BACK
            </Button>
          </div>
        )}
      </Container>

      <br />
      <br />
    </div>
  );
}
export default Checkout;
