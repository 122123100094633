import { Button, Col, Divider, Row } from "antd";
import React, { useContext } from "react";
import CartCard from "../cartCard";
import VariantTypes from "../variants";
// import { RiFlag2Fill } from "react-icons/ri";
import { SingleProductContext } from "../../singleProductContext";
const ProductDescription = (data: any) => {
  const context = useContext(SingleProductContext);
  const productInfo = context?.data;
  const metalType: any = {
    1: "Gold 24",
    2: "Gold 22",
    3: "Gold 21",
    4: "Gold 18",
    5: "Silver",
  };
  return (
    <>
      <div className="productDetails-flex productDetails-flex-row">
        <h3 className="productDetails-text2 fw-bold">
          <span className="productDetails-text2 text-secondary fw-medium">
            Category:
          </span>{" "}
          {data?.data?.categoryName?.name}/{data?.data?.subCategoryName?.name}
        </h3>
      </div>
      {/* <div className="productDetails-flex productDetails-flex-row">
        <h3 className="productDetails-text2 fw-bold">
          <span className="productDetails-text2 text-secondary fw-medium">
            Purity:
          </span>{" "}
          {data?.data?.purity}
        </h3>
      </div> */}
      <div className="productDetails-flex productDetails-flex-row">
        <h3 className="productDetails-text2 fw-bold">
          <span className="productDetails-text2 text-secondary fw-medium">
            Metal Type:
          </span>{" "}
          {metalType[data?.data?.metal_type]}
        </h3>
      </div>
      <div className="productDetails-flex productDetails-flex-row">
        <h3 className="productDetails-text2 fw-bold">
          <span className="productDetails-text2 text-secondary fw-medium">
            Weight:
          </span>{" "}
          {data?.data?.weight} Gram
        </h3>
      </div>
      {/* <div className="productDetails-flex productDetails-flex-row">
        <h3 className="productDetails-text2 fw-bold">
          <span className="productDetails-text2 text-secondary fw-medium">
            Making Charge:
          </span>{" "}
            {data?.data?.labour_charge}%
        </h3>
      </div> */}
      <Row className="mx-0">
        <Col className="col-12 col-md-8">
          <CartCard />
        </Col>
        <Col className="col-12">
          <VariantTypes />
        </Col>
        <Col md="6"></Col>
      </Row>
      {/* <p className="mb-0 fw-medium">
        <span className="text-secondary">ProductCode:</span> {productInfo?.sku}
      </p> */}
    </>
  );
};
export default ProductDescription;
