import React from "react";
import "./styles.scss";
import Logo from "../../assets/images/logo1.png";
import { Col, Container, Row } from "react-bootstrap";
import API from "../../config/API";
import { useTranslation } from "react-i18next";
import { FiArrowUpRight } from "react-icons/fi";
import  WhatsAppicon  from "../../assets/images/whatsappicon.svg";
import  Facebookicon  from "../../assets/images/facebookicon.svg";
import  Instagramicon  from "../../assets/images/instaicon.svg";
import  Xicon  from "../../assets/images/twittericon.svg";
import  SnapChaticon  from "../../assets/images/snapicon.svg";
import  TikTokicon  from "../../assets/images/tiktokicon.svg";
import  LinkedInicon  from "../../assets/images/linkedinicon.svg";
import  Locationicon  from "../../assets/images/locationicon.svg";

function ShareScreen() {
  const { t } = useTranslation();
  const handleLinkClick = (link: string) => {
    window.open(link, "_blank");
  };

  let item: any = [
    {
      icon: <img src={WhatsAppicon}/>,
      text: "WhatsApp",
      link: `${API.CONTACT_NUMBER}`,
      fullLink: `https://wa.me/${API.CONTACT_NUMBER}`,
    },
    {
      icon: <img src={Facebookicon}/>,
      text: "Facebook",
      link: "https://www.facebook.com/ziyagold",
      fullLink: "https://www.facebook.com/ZiyaGoldUAE?mibextid=ZbWKwL",
    },
    {
      icon: <img src={Instagramicon}/>,
      text: "Instagram",
      link: "https://www.instagram.com/ziyagold",
      fullLink: "https://www.instagram.com/ziyagold_?igsh=aTVyY2Jlem4xeGtr",
    },

    {
      icon: <img src={Xicon}/>,
      text: "X",
      link: "https://twitter.com/ziyagold",
      fullLink: "https://twitter.com/ziyagold",
    },
    // {
    //   icon: <img src={SnapChaticon}/>,
    //   text: "SnapChat",
    //   link: "snapchat.com/V6PsM13H",
    //   fullLink: "https://t.snapchat.com/V6PsM13H",
    // },
    {
      icon: <img src={TikTokicon}/>,
      text: "TikTok",
      link: "https://www.tiktok.com/ziyagold",
      fullLink: "https://www.tiktok.com/@ziyagolduae?_t=8lUmHAJQvz8&_r=1",
    },
    // {
    //   icon: <img src={LinkedInicon}/>,
    //   text: "LinkedIn",
    //   link: "lsg-traiding-4a60602b4/",
    //   fullLink: "https://www.linkedin.com/in/lsg-traiding-4a60602b4/",
    // },
    {
      icon: <img src={Locationicon}/>,
      text: "Location",
      link: "https://ziyagold.com/",
      fullLink: "https://ziyagoldanddiamonds.com/",
    },
  ];

  return (
    <div className="ShareScreen">
      <Container>
        <Row>
          <Col sm={12} md={12} xs={12}>
            <br />
            <div className="ShareScreen-titile">
              <div className="ShareScreen-logo">
                <img src={Logo} />
              </div>
              <div className="ShareScreen-logoBorder" />
              <div className="ShareScreen-txt2">
                {t("We_believe_that_the_best")}
                <br className="d-none d-sm-block" />
                {t("We_believe_that_the_best1")}
              </div>
            </div>

            <div style={{ margin: 20 }} />
          </Col>
          <Col md={1} xs={12} />
          <Col sm={10} md={10} xs={12}>
            <Row>
              {item?.map((item: any) => {
                return (
                  <Col sm={6} xs={12}>
                    <div
                      className="ShareScreen-box2"
                      onClick={() => handleLinkClick(item?.fullLink)}
                    >
                      <div className="ShareScreen-box3">{item?.icon}</div>
                      <div style={{ flexGrow: 1 }}>
                        <div className="ShareScreen-txt3">{item?.text}</div>
                        <div className="ShareScreen-txt4">{item?.link}</div>
                      </div>
                      <div>
                        <FiArrowUpRight color={"#fff"} size={20} />
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Col>
          <Col md={1} xs={12}></Col>
        </Row>
      </Container>
      <br />
    </div>
  );
}

export default ShareScreen;
