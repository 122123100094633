import { Alert, Button, Modal, Spin } from "antd";
import { useEffect, useRef, useState } from "react";
import MapPicker from "react-google-map-picker";
import API from "../../../config/API";
import { Col, Row } from "react-bootstrap";
import React from "react";
import AutoCompleteLocationInput from "../../../components/header/components/AutoCompleteLocationInput";
import {
  clearLocation,
  storeLocation,
} from "../../../redux/slices/locationSlice";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { FaLocationCrosshairs } from "react-icons/fa6";
import { LocationType } from "../../../shared/types/types";

const DefaultZoom = 10;
function LocationPicker(props: any) {
  const dispatch = useDispatch();
  const [location, setLocation] = useState({
    lat: props?.location?.lat || 25.204849,
    lng: props?.location?.lng || 55.270782,
  });
  const [zoom, setZoom] = useState(DefaultZoom);
  const inputRef: any = useRef(null);
  const LocationDetails: LocationType = useSelector(
    (state: any) => state.Location.location
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [defaultLocation, setDefaultLocation] = useState({
    lat: props?.location?.lat || 25.204849,
    lng: props?.location?.lng || 55.270782,
  });

  function handleChangeLocation(lat: any, lng: any) {
    setLocation({ lat: lat, lng: lng });
  }

  function handleChangeZoom(newZoom: any) {
    setZoom(newZoom);
  }

  const generateAddress = async () => {
    props.onChange(location);
    props?.onCancel();
  };

  function handleLocationClick() {
    try {
      setIsLoading(true);
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          getLocation(latitude, longitude);
        }, handleError);
      } else {
        handleError({ message: "not supported" });
      }
    } catch (err) {
      handleError({ message: "not supported" });
    }
  }

  const getLocation = (lat: number, long: number) => {
    try {
      setError(null);
      const locationObj: any = {};
      locationObj.latitude = lat;
      locationObj.longitude = long;
      setLocation({
        lat: lat,
        lng: long,
      });
      setDefaultLocation({
        lat: lat,
        lng: long,
      });
      const url =
        API.GET_LOCATION + `?latlng=${lat},${long}&key=${API.GGL_TOKEN}`;
      fetch(url, {
        method: "get",
        headers: {
          Accept: "application/json",
        },
      })
        .then((response) => {
          return response.json();
        })
        .catch((err) => {
          handleError({ message: "Failed to get your location" });
          return;
        })
        .then((response) => {
          if (response?.status == "OK") {
            response.results?.map((item: any) => {
              if (item.types?.includes("country")) {
                locationObj.country = item.formatted_address;
              }
              if (item.types?.includes("postal_code")) {
                locationObj.postal_code = item.formatted_address;
              }
              if (item.types?.includes("administrative_area_level_1")) {
                locationObj.state = item.formatted_address;
              }
              if (item.types?.includes("administrative_area_level_3")) {
                locationObj.district = item.formatted_address;
              }
              if (item.types?.includes("administrative_area_level_4")) {
                locationObj.taluk = item.formatted_address;
              }
              if (item.types?.includes("plus_code")) {
                locationObj.plus_code = item.formatted_address;
              }
              if (item.types?.includes("street_address")) {
                locationObj.street_address = item.formatted_address;
              }
              if (item.types?.includes("route")) {
                locationObj.route = item.formatted_address;
              }
            });
            setIsLoading(false);
            props?.modalClose(false);
          } else {
            handleError({
              message: `Request failed ,reason:${response.status}`,
            });
          }
        })
        .catch((err) => {
          handleError({ message: `Failed to get Location.` });
        });
    } catch (err) {
      handleError({ message: `Failed to get Location.` });
    }
  };

  const handleError = (error: any) => {
    setError(`Unable to get your Location. ${error.message}`);
    setIsLoading(false);
  };

  const focusInput = () => {
    setTimeout(() => {
      if (props?.open == true) {
        inputRef?.current?.focus();
      }
    }, 10);
  };

  useEffect(() => {
    focusInput();
  }, [props?.open]);

  return (
    <Modal
      title={<h4>Point Store Location</h4>}
      visible={props?.visible}
      footer={null}
      onCancel={() => props?.onCancel()}
      centered
    >
      <br />
      <MapPicker
        zoom={zoom}
        style={{ height: "400px" }}
        onChangeLocation={handleChangeLocation}
        onChangeZoom={handleChangeZoom}
        apiKey={API.GGL_TOKEN}
        defaultLocation={defaultLocation}
      />
      <br />
      <div className="current-location-box" onClick={handleLocationClick}>
        <div>
          {isLoading ? (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          ) : (
            <FaLocationCrosshairs size={24} />
          )}
        </div>
        <div className="current-location-box2">
          <div className="current-location-txt2">Use My Current Location</div>
        </div>
      </div>
      <br />
      {/* {LocationDetails.latitude ? (
        <div className="current-location-box">
          <div>
            <div className="current-location-txt1">
              {LocationDetails?.country}
            </div>
            <div className="current-location-txt3">
              {LocationDetails.latitude
                ? LocationDetails.full_address
                  ? LocationDetails?.full_address
                  : LocationDetails.postal_code
                : null}
            </div>
          </div>
        </div>
      ) : null}
      <br /> */}
      {/* {error ? (
        <Alert
          style={{ marginTop: 20 }}
          type="error"
          message={<div style={{ color: "red", fontSize: 16 }}>{error}</div>}
        />
      ) : null} */}
      <Row>
        <Col sm={6} xs={12}></Col>
        <Col sm={2} xs={6}>
          <Button block size="large" danger onClick={() => props?.onCancel()}>
            Close
          </Button>
        </Col>
        <Col sm={4} xs={6}>
          <Button
            size="large"
            block
            type="primary"
            onClick={() => generateAddress()}
          >
            Done
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}

export default LocationPicker;
