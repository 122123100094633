import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, notification } from "antd";
import { useForm } from "antd/es/form/Form";
import { useTranslation } from "react-i18next";
import { InputOTP } from "antd-input-otp";
import { BiErrorCircle } from "react-icons/bi";
import SummaryCard from "./summaryCard";
import GmailLogin from "../loginScreens/gmailLogin";
import SuccessModal from "../../components/successModal";
import PrefixSelector from "../../components/prefixSelector";
import "./styles.scss";
import API from "../../config/API";
import { POST } from "../../utils/apiCalls";
import { login } from "../../redux/slices/userSlice";

function SignupScreen() {
  const [form] = useForm();
  const [form2] = useForm();
  const { t } = useTranslation();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [phoneTaken, setPhoneTaken] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailId, setEmailId] = useState("");
  const [emailTaken, setEmailTaken] = useState(false);
  const [successmodal, setSuccessmodal] = useState(false);
  const [verification, setverification] = useState(false);
  const [token, setToken] = useState();
  const [formData, setFormData] = useState<any>({});

  const requestOTP = async (data: any) => {
    try {
      setIsLoading(true);
      let datas2 = form2.getFieldsValue();
      setFormData(datas2);
      let reqObj = {
        code: data?.code,
        phoneNumber: data?.phone,
        type: "signup",
      };
      let url = API.REQUESTOTP;
      let res: any = await POST(url, reqObj);
      if (res.status) {
        setToken(res.token);
        setverification(true);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };
  const verifyOtp = async (data: any) => {
    try {
      const url = API.VERIFYOTP;
      let reqBody = {
        otp: data?.otp?.join(""),
        token: token,
        phoneNumber: form2.getFieldValue("phone"),
      };
      let res: any = await POST(url, reqBody);
      if (res.status) {
        Signup();
      } else {
        notification.error({
          message: res.message,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const Signup = async () => {
    try {
      setIsLoading(true);
      let url = API.SIGNUP;
      let body = {
        email: formData.email,
        username: formData.username,
        password: formData.password,
        first_name: formData?.firstname,
        last_name: formData?.lastname,
        phoneNumber: phoneNumber,
        countrycode: formData?.code,
      };
      var signupRes: any = await POST(url, body);
      if (signupRes.status) {
        setSuccessmodal(true);
        setTimeout(() => {
          dispatch(login(signupRes));
        }, 2000);
      } else {
        setError(signupRes.message);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="Screen-box">
      <br /> <br />
      <h2 className="signupScreen-txt1">{t("Create_your_account")}</h2>
      <div className="signupScreen-txt2">{t("singup_desc1")}</div>
      <Container>
        <Row>
          <Col sm={4} xs={12}></Col>
          <Col sm={4} xs={12}>
            <div className="LoginScreen-box1">
              {verification ? (
                <>
                  <SummaryCard data={formData} />
                  <div className="signupScreen-txt2">{t("Enter_OTP")}</div>
                  <Form form={form} onFinish={verifyOtp}>
                    <Form.Item
                      name="otp"
                      rules={[
                        {
                          required: true,
                          message: `${t("Input_6_digit")}`,
                        },
                      ]}
                    >
                      <InputOTP autoFocus inputType="numeric" length={6} />
                    </Form.Item>
                    {error ? (
                      <div className="signupScreen-errortxt">
                        <BiErrorCircle /> &nbsp;
                        {error}
                      </div>
                    ) : null}
                    <Button
                      block
                      type="primary"
                      size="large"
                      htmlType="submit"
                      loading={isLoading}
                      style={{ height: 45 }}
                    >
                      {t("Verify")}
                    </Button>
                  </Form>
                </>
              ) : (
                <>
                  <div className="signupScreen-txt2">{t("singup_desc2")}</div>
                  <br />
                  <Form
                    form={form2}
                    onFinish={requestOTP}
                    initialValues={{ code: "+91" }}
                    layout="vertical"
                  >
                    <Row>
                      <Col sm={6} xs={6}>
                        <Form.Item
                          name={"firstname"}
                          rules={[
                            {
                              required: true,
                              message: `${t("Please_enter_firstname")}`,
                            },
                            {
                              max: 30,
                              message: `${t("Firstname_is_too_long")}`,
                            },
                          ]}
                        >
                          <Input placeholder={t("First_Name")} size="large" />
                        </Form.Item>
                      </Col>
                      <Col sm={6} xs={6}>
                        <Form.Item
                          name={"lastname"}
                          rules={[
                            {
                              required: true,
                              message: `${t("Please_enter_lastname")}`,
                            },
                            {
                              max: 30,
                              message: `${t("Lastname_is_too_long")}`,
                            },
                          ]}
                        >
                          <Input placeholder={t("Last_Name")} size="large" />
                        </Form.Item>
                      </Col>
                    </Row>
                    {phoneTaken ? (
                      <p className="text-danger my-0 py-0">
                        {t("This_Phone_number")}
                      </p>
                    ) : null}
                    <Form.Item
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: `${t("Please_input_your_phone_number")}`,
                        },
                        {
                          max: 16,
                          message: `${t("Phone_Number_is_Invalid")}`,
                        },
                        () => ({
                          validator(_, value) {
                            if (phoneTaken) {
                              return Promise.reject(new Error(""));
                            }
                            return Promise.resolve(
                              `${t("Phone_Number_available")}`
                            );
                          },
                        }),
                      ]}
                    >
                      <Input
                        addonBefore={<PrefixSelector />}
                        style={{ width: "100%" }}
                        size="large"
                        placeholder={`${t("Enter_Phone_Number")}`}
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        onKeyPress={(e) => {
                          const isNumber = /^[0-9]*$/;
                          if (!isNumber.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(ph) => setPhoneNumber(ph.target.value)}
                      />
                    </Form.Item>

                    {emailTaken ? (
                      <p className="text-danger my-0 py-0">
                        {t("This_Email_Id")}
                      </p>
                    ) : null}
                    <Form.Item
                      name={"email"}
                      rules={[
                        {
                          required: true,
                          message: `${t("Please_enter_your_email_id")}`,
                        },
                        {
                          type: "email",
                          message: `${t("Please_enter_valid_email_id")}`,
                        },
                        {
                          max: 60,
                          message: `${t("Email_id_is_Invalid")}`,
                        },
                        () => ({
                          validator(_, value) {
                            if (emailTaken) {
                              return Promise.reject(new Error(""));
                            }
                            return Promise.resolve(`${t("EmailId_available")}`);
                          },
                        }),
                      ]}
                    >
                      <Input
                        placeholder={t("Enter_Email_Address")}
                        size="large"
                        onChange={(em) => setEmailId(em?.target?.value)}
                      />
                    </Form.Item>
                    <Form.Item
                      name={"username"}
                      rules={[
                        {
                          required: true,
                          message: `${t("Please_enter_username")}`,
                        },
                        {
                          max: 30,
                          message: `${t("User_name_is_too_long")}`,
                        },
                      ]}
                    >
                      <Input placeholder={t("User_name")} size="large" />
                    </Form.Item>

                    <Form.Item
                      name={"password"}
                      rules={[
                        {
                          required: true,
                          message: `${t("Please_input_your_password")}`,
                        },
                        {
                          min: 8,
                          message: `${t(
                            "Password_must_be_minimum_8_characters"
                          )}`,
                        },
                      ]}
                      hasFeedback
                    >
                      <Input.Password
                        size="large"
                        placeholder={t("Enter_Password")}
                      />
                    </Form.Item>
                    <Form.Item
                      name={"confirm"}
                      rules={[
                        {
                          required: true,
                          message: `${t("Please_confirm_your_password")}`,
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(`${t("The_new_password_not_match")}`)
                            );
                          },
                        }),
                      ]}
                      dependencies={["password"]}
                      hasFeedback
                    >
                      <Input.Password
                        size="large"
                        placeholder={t("Confir_Password")}
                      />
                    </Form.Item>
                    {error ? (
                      <div className="signupScreen-errortxt">
                        <BiErrorCircle /> &nbsp;
                        {error}
                      </div>
                    ) : null}
                    <Button
                      block
                      type="primary"
                      size="large"
                      htmlType="submit"
                      loading={isLoading}
                      style={{ height: 45 }}
                    >
                      {t("Create_Account")}
                    </Button>
                  </Form>
                </>
              )}
              <br />
              <GmailLogin
                closeModal={() => setSuccessmodal(false)}
                openModal={() => setSuccessmodal(true)}
              />
              <br />
              <div
                className="signupScreen-txt4"
                onClick={() => navigation("/login")}
              >
                {t("Already_have_an_account")}{" "}
                <span className="signupScreen-txt5">{t("Login")}</span>
              </div>
            </div>
          </Col>
          <Col sm={4} xs={12}></Col>
        </Row>
      </Container>
      <br />
      <br />
      {successmodal ? (
        <SuccessModal
          visible={successmodal}
          onCancel={() => setSuccessmodal(false)}
          title="Success"
          body="Congrats on creating your account! 🎉"
          onButtonClick={() => setSuccessmodal(false)}
          buttonText="Start Purchasing"
        />
      ) : null}
    </div>
  );
}

export default SignupScreen;
