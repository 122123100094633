import { useState } from "react";
import { useDispatch } from "react-redux";
import { FcGoogle } from "react-icons/fc";
import { Auth, GoogleProvide } from "../../config/firebase";
import { signInWithPopup } from "firebase/auth";
import { notification, Modal, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import API from "../../config/API";
import { POST } from "../../utils/apiCalls";
import { login } from "../../redux/slices/userSlice";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const antIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;

function GmailLogin(props: any) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [notificationApi, contextHolder] = notification.useNotification();

  const loginGmail = async () => {
    try {
      const info = await signInWithPopup(Auth, GoogleProvide);
      const token = await info?.user?.getIdToken();
      let obj = {
        idToken: token,
      };
      setIsLoading(true);
      await LoginEmail(obj);
    } catch (err) {
      notificationApi.error({
        message: `${t("Something_went_wrong_Unable")}`,
      });
    }
  };

  const LoginEmail = async (values: any) => {
    try {
      let url = API.LOGIN_GMAIL;
      var loginRes: any = await POST(url, values);
      if (loginRes.status) {
        if (loginRes.newAccount == true) {
          if (typeof props?.openModal == "function") {
            props?.openModal();
          }
          setTimeout(() => {
            if (typeof props?.closeModal == "function") {
              props?.closeModal();
            }
            dispatch(login(loginRes));
          }, 2000);
        } else {
          notificationApi.success({ message: `${t("Login_Successful")}`});
          dispatch(login(loginRes));
        }
      } else {
        notificationApi.error({ message: loginRes.message });
      }
    } catch (err) {
      notificationApi.error({ message: loginRes.message ?? "" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {contextHolder}
      <div className="GmailLogin" onClick={() => loginGmail()}>
        <div className="GmailLogin-row">
          <div>
            <FcGoogle size={30} />
          </div>
          <div>&nbsp;{t("Continue_With")}Google</div>
        </div>
      </div>
      <Modal
        width={250}
        centered
        open={isLoading}
        footer={false}
        closable={false}
      >
        <div className="GmailLogin-txt1">
          <Spin indicator={antIcon} />
          &nbsp; &nbsp; &nbsp;{t("Checking_Account")}
        </div>
      </Modal>
    </>
  );
}
export default GmailLogin;
