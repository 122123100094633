import { useEffect } from "react";
import SaleMessage from "../../components/saleMessage";
import SubscribeNewsletter from "../../components/subscribeNewsletter";
import { Col, Container, Row } from "react-bootstrap";
import TellusMore from "../../assets/images/Tell-us-more.png";
import { Button, Checkbox, DatePicker, Form, Input, Select } from "antd";
import PageHeader from "../../components/pageHeader";
import countriesList from "../../config/countryCode.json";
import React from "react";
import { useTranslation } from "react-i18next";
import API from "../../config/API";

function TellUsMore() {
  const { t } = useTranslation();
  const { Option } = Select;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="Screen-box">
      <div>
        <SaleMessage />
        <div className="ftscreen-fnt m-2">
          <PageHeader text={t("Know_You")} />
          <Container>
            <Row className="mt-5">
              <Col md={4} xs={12}>
                <img src={TellusMore} alt="" className="w-100" />
              </Col>

              <Col md={6} xs={12} className="p-5 ">
                <Form>
                  <h3 className="d-flex justify-content-center pb-4">
                    {t("Know_You")}
                  </h3>
                  <Row>
                    <Col md={6}>
                      <Form.Item
                        name="firstName"
                        // label="First Name"
                        rules={[
                          {
                            required: true,
                            message: `${t("Please_enter_your_first_name")}`,
                          },
                        ]}
                      >
                        <Input placeholder={t("First_Name")} />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        name="lastName"
                        // label="Last Name"
                        rules={[
                          {
                            required: true,
                            message: `${t("Please_enter_your_last_name")}`,
                          },
                        ]}
                      >
                        <Input placeholder={t("Last_Name")} />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        name="email"
                        // label="Email"
                        rules={[
                          {
                            required: true,
                            type: "email",
                            message: `${t("Please_enter_a_valid_email")}`,
                          },
                        ]}
                      >
                        <Input placeholder={t("Email")} />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        name="mobile"
                        // label="Mobile"
                        rules={[
                          {
                            required: true,
                            message: `${t("Please_enter_your_mobile_number")}`,
                          },
                        ]}
                      >
                        <Input placeholder={t("Mobile")} />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        name="gender"
                        // label="Gender"
                        rules={[
                          {
                            required: true,
                            message: `${t("Please_select_your_gender")}`,
                          },
                        ]}
                      >
                        <Select placeholder={t("Select_Gender")}>
                          <Option value="male">{t("Male")}</Option>
                          <Option value="female">{t("Female")}</Option>
                          <Option value="notToSaygender">
                            {t("Prefer_not_to_say")}
                          </Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        name="birthdate"
                        // label="Birthdate"
                        rules={[
                          {
                            required: true,
                            message: `${t("Please_select_your_birthdate")}`,
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          placeholder={t("Select_Birthdate")}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        name="maritalStatus"
                        // label="Marital Status"
                        rules={[
                          {
                            required: true,
                            message: `${t("Please_select_your_marital")}`,
                          },
                        ]}
                      >
                        <Select placeholder={t("Select_Marital_Status")}>
                          <Option value="single">{t("Single")}</Option>
                          <Option value="married">{t("Married")}</Option>
                          <Option value="divorced">
                            {t("Subscribe_to_our_newsletter")}
                          </Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        name="employment"
                        // label="Employment"
                        rules={[
                          {
                            required: true,
                            message: `${t("Please_select_your_employment")}`,
                          },
                        ]}
                      >
                        <Select placeholder={t("Select_Employment")}>
                          <Option value="student">{t("Student")}</Option>
                          <Option value="professional">
                            {t("Salaried_Professional")}
                          </Option>
                          <Option value="entrepreneur">
                            {t("Entrepreneur")}
                          </Option>
                          <Option value="notToSay">
                            {t("Prefer_not_to_say")}
                          </Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        name="preferredLanguage"
                        // label="Preferred Language"
                        rules={[
                          {
                            required: true,
                            message: `${t(
                              "Please_select_your_preferred_language"
                            )}`,
                          },
                        ]}
                      >
                        <Select placeholder={t("Select_Preferred_Language")}>
                          <Option value="english">English</Option>
                          <Option value="spanish">Arabic</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        name="country"
                        // label="Country"
                        rules={[
                          {
                            required: true,
                            message: `${t("Please_select_your_country")}`,
                          },
                        ]}
                      >
                        <Select placeholder={t("Subscribe_to_our_newsletter")}>
                          {countriesList.map((country) => (
                            <Option key={country.code} value={country.code}>
                              {country.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        name="state"
                        // label="State"
                        rules={[
                          {
                            required: true,
                            message: `${t("Please_nter_your_state")}`,
                          },
                        ]}
                      >
                        <Input placeholder={t("State")} />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        name="accommodation"
                        // label="Accommodation"
                        rules={[
                          {
                            required: true,
                            message: `${t(
                              "Please_select_your_accommodation_preference"
                            )}`,
                          },
                        ]}
                      >
                        <Select placeholder={t("Select_Accommodation")}>
                          <Option value="villa">{t("Villa")}</Option>
                          <Option value="apartment">{t("Apartment")}</Option>
                          <Option value="shared">
                            {t("Shared_Accommodation")}
                          </Option>
                          <Option value="notToSayAccommodation">
                            {t("Prefer_not_to_say")}
                          </Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        name="shoppingPreference"
                        // label="Shopping Preference"
                        rules={[
                          {
                            required: true,
                            message: `${t("Please_select_your_shopping")}`,
                          },
                        ]}
                      >
                        <Select placeholder={t("Select_Shopping_Preference")}>
                          <Option value="online">{t("Online")}</Option>
                          <Option value="offline">{t("Offline")}</Option>
                          <Option value="notToSayShopping">
                            {t("Prefer_not_to_say")}
                          </Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={6} />
                    <Col md={12}>
                      <Form>
                        <Form.Item
                          name="feedback"
                          rules={[
                            {
                              required: true,
                              message: `${t(
                                "Please_enter_your_feedback_about"
                              )} ${API.NAME}${t("website")}`,
                            },
                          ]}
                        >
                          <Input.TextArea
                            placeholder={`${t("Your_feedback_about")} ${
                              API.NAME
                            } ${t("website")}`}
                            rows={4}
                          />
                        </Form.Item>
                        <Form.Item
                          name="agreement"
                          valuePropName="checked"
                          rules={[
                            {
                              validator: (_, value) =>
                                value
                                  ? Promise.resolve()
                                  : Promise.reject(
                                      `${t("Please_agree_to_the_terms_and")}`
                                    ),
                            },
                          ]}
                        >
                          <Checkbox>
                            {t("By_submitting_the_form_agree_to")}
                            {API.NAME}’s
                            {t("standard")}{" "}
                            <a href="/terms-and-conditions" target="_blank">
                              {t("terms_and_conditions_1")}
                            </a>
                          </Checkbox>
                        </Form.Item>
                        <Form.Item>
                          <Button htmlType="submit" block className="w-100">
                            {t("Submit")}
                          </Button>
                        </Form.Item>
                      </Form>
                    </Col>
                  </Row>
                </Form>
              </Col>
              <Col md={2} xs={12}></Col>
            </Row>
          </Container>  
        </div>
      </div>
      <div className="mt-5">
        <SubscribeNewsletter />
      </div>
    </div>
  );
}
export default TellUsMore;
