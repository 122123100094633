interface responseType {
  eligibleItems: any[];
  nonEligibleItems: any[];
  status: boolean;
}
export const checkoutCartItems = async (data: any[]) => {
  try {
    if (Array.isArray(data) === true) {
      const checkoutItems: responseType = data.reduce(
        (items: responseType, item: any) => {
          const product = {
            ...item,
            buyPrice: Number(item?.price),
            name: item?.productDetails?.name,
            totalPrice: Number(
              item?.price * item.quantity
            ),
          };
          if (item.variantId) {
            //if product variant is selected
            if (
              item.productDetails?.status === true && //no status for variant that's why
              Number(item.variantDetails?.units >= Number(item?.quantity)) //later change this to variantDetails
            ) {
              items.eligibleItems?.push(product);
            } else {
              items.status = false;
              items.nonEligibleItems.push(product);
            }
          } else {
            if (
              item.productDetails?.status === true &&
              Number(item.productDetails?.unit >= Number(item?.quantity))
            ) {
              items.eligibleItems?.push(product);
            } else {
              items.nonEligibleItems.push(product);
              items.status = false;
            }
          }
          return items;
        },
        { eligibleItems: [], nonEligibleItems: [], status: true }
      );
      return checkoutItems;
    }
    return { eligibleItems: [], nonEligibleItems: [], status: false };
  } catch (err) {
    return { eligibleItems: [], nonEligibleItems: [], status: false };
  }
};
