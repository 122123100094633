import React, { useEffect, useState } from "react";

import { Col, Container, Row } from "react-bootstrap";
import ProductsOverView from "./components/products";
import NewProductsOverView from "./components/newProducts";
import OverViewGraph from "./components/graph";
import "./styles.scss";
import Piechart from "./components/piechart";
import API from "../../config/API";
import { GET } from "../../utils/apiCalls";
import { useSelector } from "react-redux";
import Loading from "../../components/loading";
import { message } from "antd";
function OverView() {
  const [loading, setLoading] = useState(true);
  const Auth = useSelector((state: any) => state.User?.user?.data);
  const Settings = useSelector((state: any) => state.Settings.Settings);
  const [orders, setOrders] = useState([]);
  const [totalOrders, setTotalOrder] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);
  const [orderTotal, setorderTotal] = useState(0);
  const [messageApi, contextHolder] = message.useMessage();
  const [orders7, setOrders7] = useState<any[]>([]);
  const getOrders = async () => {
    const url =
      API.CORPORATE_STORE_DASHBOARD +
      Auth?.store_id +
      `?order=DESC&page=1&take=12`;
    setLoading(true);
    try {
      const response: any = await GET(url, null);
      if (response?.status) {
        setOrders(response?.data);
        setTotalOrder(response?.totalOrders);
        setTotalProducts(response?.totalProducts);
        setorderTotal(response?.orderTotal);
        setOrders7(response?.orders);
      } else {
        messageApi.error(response.message ?? "");
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getOrders();
  }, []);
  return (
    <Container>
      {contextHolder}
      <br />
      {loading ? (
        <Loading />
      ) : (
        <Row>
          <Col md={12}>
            <div className="overView-Box1">
              <ProductsOverView
                totalOrders={totalOrders}
                totalProducts={totalProducts}
                orderTotal={orderTotal}
                currency={Settings?.currency}
              />
            </div>
          </Col>
          <Col md={8}>
            <h5 className="DashboardPurschaseGrph-Tx1">{`Today's Orders (${orders.length})`}</h5>
            <NewProductsOverView data={orders} currency={Settings?.currency} />
          </Col>
          <Col md={4}>
            <div className="overView-Box3">
              <OverViewGraph orders={orders7} />
            </div>
            <br />
            <br />
            {/* <div className="overView-Box4">
            <Piechart />
          </div> */}
          </Col>
        </Row>
      )}

      <br />
    </Container>
  );
}
export default OverView;
