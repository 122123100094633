import React, { useEffect, useState } from "react";
import PageHeader from "../components/PageHeader";
import DataTableEnquiry from "./dataTable";
import API from "../../config/API";
import { GET } from "../../utils/apiCalls";

const Enquiry = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [meta, setMeta] = useState<any>({});
  const [currentPage, setCurrentPage] = useState<number>(1);
  const pageSize = 12;
  const getEnquiry = async (page: number = 1) => {
    let url = API.ENQUIRY_GET + `?order=DESC&page=${page}&take=${pageSize}`;
    try {
      setLoading(true);
      const response: any = await GET(url, null);
      if (response.status) {
        setData(response.data);
        setMeta(response?.meta);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const handlePageChange = async (page: number) => {
    await getEnquiry(page);
    setCurrentPage(page);
  };

  useEffect(() => {
    getEnquiry();
  }, []);

  return (
    <div>
      <PageHeader title={`Enquiry ${!loading ? `(${meta?.itemCount ?? "0"})` : ""}`} />
      <div style={{ padding: "20px" }}>
        <DataTableEnquiry
          pageSize={pageSize}
          meta={meta}
          currentPage={currentPage}
          data={data}
          loading={loading}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default Enquiry;
