import React from "react";
import SubscribeNewsletter from "../../components/subscribeNewsletter";
import { Flex } from "antd";

function About() {
  return (
    <div className="Screen-box">
      <div
        style={{
          fontWeight: 700,
          fontSize: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        Ooops! We Are Under Maintanance
      </div>
    </div>
  );
}
export default About;
