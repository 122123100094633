const API = {
  // BASE_URL: "http://localhost:8021/",
  BASE_URL: "https://ziya-gold-server.bairuhatech.com/",
  WEBSITE: "ziyagold.com",
  NAME: "ZIYA GOLD & DIAMONDS",
  COLOR: "#5F052B", //#b8821f 
  CONTACT_MAIL: "sales@ziyagold.com",
  CONTACT_NUMBER: "+971565055539",
  GGL_TOKEN: "AIzaSyBS17KiJVZWcPh0dLI9KKqVTcnUYwYp3PQ",

  //GOOGLE API
  GOOGLE_API: "https://maps.googleapis.com/maps/api/",
  GET_LOCATION: "https://maps.googleapis.com/maps/api/geocode/json",
  GET_AUTOCOMPLETE:
    "https://maps.googleapis.com/maps/api/place/autocomplete/json",
  SEARCH_PLACE: "",

  //APIS
  IMAGE_COMPRESS: "img_compress/compress",
  FILE_UPLOAD: "img_compress/file",

  LOGIN_EMAIL: "auth/login", //POST
  LOGIN_PHONE: "auth/phone-login", //POSTdfsd
  LOGIN_GMAIL: "auth/google-login", //POST
  SIGNUP: "auth/signup", //POST

  VERIFY_MAIL: "Auth/verify/",
  RESEND_MAIL: "Auth/resend_verify/",
  REQUEST_RESET: "Auth/reset-password",

  // HOME SCREEN , CATEGORIES, SUB-CATEGORIES, BANNERS, OFFER-BANNERS
  GET_HOMESCREEN: "landing?", // GET

  // PRODUCT SEARCH
  PRODUCT_SEARCH: "product_search/",
  PRODUCT_RECOMMENDATIONS: "store_search/all/products/recommend?",
  PRODUCT_TRENDING: "",
  PRODUCT_BEST_SELL: "",
  PRODUCT_BYCATEGORY: "",
  PRODUCT_DETAILS: "",
  PRODUCT_REVIEWS: "productsReviews/review",
  PRODUCT_SEARCH_MULTI: "product_search/search?type=multi&", //product search for multi
  PRODUCT_SEARCH_SINGLE: "product_search/search?type=single&", //product search for single

  // STORE SEARCH
  STORE_SEARCH_SEARCH: "store_search/search", //product search inside a single store
  STORE_SEARCH_GETINFO: "store_search/info/", //to get all details and subcategories of a store.
  STORE_SEARCH_GETALL: "store_search/all/", //to get all products in a store grouped by subcategory
  STORE_SEARCH_BYCATEGORY: "store_search/store/subcategory", //to get all products in a store for a single subcategory
  STORE_SEARCH_BYSUBCATEGORY: "store_search/subcategory", //get all products for a subc grouped by store
  STORE_SEARCH_BANNERS: "store_search/banner/", // to get all the banner of a single store
  STORE_SEARCH_PRODUCTS_ALL: "store_search/store/all/subcategory?", //to get all products in a store =======
  // STORE PRODUCT BANNERS API
  BANNER_ALL: "banner/all/", //GET  page=page&take=pageSize
  BANNER: "banner/", //POST,PUT,DELETE
  BANNER_STATUS_UPDATE: "banner/status/", //uupdate the status show or hide banner on home
  BANNER_POSITION_UPDATE: "banner/position/", //update the banner position //put

  // BUSINESS TYPE API
  BUSINESS_TYPE: "businesstype/", //POST,PUT,DELETE

  //STATES API
  STATES: "states/", //POST,PUT,DELETE

  //DELIVERY_CHARGE
  DELIVERY_CHARGE: "deliverycharge/", //POST,PUT,DELETE
  DISTANCE_CHARGE: "distancecharge/", //POST,PUT,DELETE
  WEIGHT_CHARGE: "weightcharge/", //POST,PUT,DELETE
  LBH_CHARGE: "lbhcharge/", //POST,PUT,DELETE

  // STORE PRODUCT CATEGORY API
  CATEGORY_ALL: "category/all", // GET all
  CATEGORY: "category/", //delete,put,post
  CATEGORY_UPDATE_POSITION: "category/position/", //put. update the category position
  SUB_CATEGORY: "subCategory/all", // GET all
  SUB_CATEGORY_EDIT: "subCategory/",
  SUB_CATEGORY_UPDATE_POSITION: "subCategory/position/", //UPDATE SUBACTEGORY POSITION PUT
  OFFERS: "offers/",

  // PROFILE SETTINGS
  USER_CONFIG_USERS: "user", //
  USER_COINFIG_UPDATE: "user/role/update/",
  USER_DEACTIVATE: "user/deactivate/", //deactivate user's account
  USER_REACTIVATE: "user/reactivate/", //reactivate user's account
  USER_CHECK_IFEXIS: "user/check_user/validate", //post// check if a user exist with an email or phone
  USER_DEACTIVATE_EMAIL: "auth/deactivate-account/", //deactivate user's account using mail
  USER_CHANGE_PASSWORD: "user/update-password/", //to change user password
  USER_ADDNEW_PASSWORD: "user/add-password/", //to ADD NEW user password
  USER_PHOTO_UPDATE: "user/update-photo/", //to add new photo
  USER_DETAILS: "user/details/", //to get all user info
  USER_CONFIG_INVITE_MAIL: "menus/sendInvite",
  USER_VERIFY_EMAIL: "auth/verify-email", //POST verifies the token
  USER_FORGOT_PASSWORD: "auth/forgot-password", //post forgot password request
  USER_RESET_PASSWORD: "auth/reset-password", //POST verifies the token
  USER_CHECK_PHONE: "auth/checkphone/", //check phone number exist for signup
  USER_CHECK_EMAIL: "auth/checkEmail/", //check phone number exist for signup

  CART_GET_ALL: "cart/all",
  CART: "cart/", //post,put,delete
  // CART_GET: "cart/user/", //get byuserID
  CART_CLEAR_ALL: "cart/clear-all/", //delete all items in cart
  ADDRESS: "address/", //post,put,delete
  ADDRESS_GET: "address/all/", //get all address for a user
  ADDRESS_SET_DEFAULT: "address/setdefault/", //SET DEFAULT ADDRESS
  WISHLIST: "wishlist/", //post,delete
  WISHLIST_GETALL: "wishlist/all", //get,post,put,
  PRODUCT_REVIEW: "productsReviews/",

  //SETTINGS,
  SETTINGS: "settings/",

  CORPORATE_STORE_CREATE: "coorporate_store/create", //post
  CORPORATE_STORE_CREATESELLER: "coorporate_store/createexist", //post to become a seller for an existing user
  CORPORATE_STORE_GETALL: "coorporate_store/pgn", //get
  CORPORATE_STORE_GETSELLERINFO: "coorporate_store/details/", //get store details only for store owners
  CORPORATE_STORE_GETSTOREINFO_ADMIN: "coorporate_store/sellerdetails/", //get store details only for store owners
  CORPORATE_STORE_CHECKSTORE: "coorporate_store/store_check/", //getbyid
  CORPORATE_STORE_UPDATE_STATUS: "coorporate_store/status/", //put
  CORPORATE_STORE_REQUEST_DOCUMENT: "coorporate_store/send_mail", //post
  CORPORATE_STORE_GET_TOP_STORE: "coorporate_store/topstore", //get gets the top selling store in the location
  CORPORATE_SELLER_UPDATE: "coorporate_store/update", // PUT
  CORPORATE_STORE_DASHBOARD: "coorporate_store/dashboard/", // get dashboard info for store
  STORE_ADMIN_DASHBOARD: "coorporate_store/dashboard/admin", //getdasboard info for only admin

  INDIVIDUAL_STORE_CREATE: "individual_seller/create", //post
  INDIVIDUAL_STORE_GETALL: "individual_seller/pgn", //get
  INDIVIDUAL_STORE_GETBYID: "individual_seller/", //getbyid
  INDIVIDUAL_STORE_UPDATE_STATUS: "individual_seller/status/", //put
  INDIVIDUAL_STORE_REQUEST_DOCUMENT: "individual_seller/send_mail", //post
  STORE_CREATE: "store/create",

  // user--------------------------
  USER_EMAIL_UPDATE: "user/update-email", // update email/
  USER_PHONENUMBER_UPDATE: "user/update-Phone", //  update phone==
  USER_NAME_UPDATE: "user/update-name", // update name===
  USER_REFRESH: "user/refresh-user/", //get to refresh user details
  // get user----------------
  USER_EMAIL_VERIFY: "auth/email-verify", // verify email/
  USER_REQUEST_DEACTIVATE: "auth/request-deactivate", //post request deacitate via mail

  //orders
  ORDER: "order/", //post
  ORDER_GET: "order/all", //get all orders for a user and admin
  // ORDER_GETONE: "order/get_one/", //get one
  ORDER_GET_BYSTORE: "order/store/",
  ORDER_GET_USER: "order/user/", //to get all orders for a user only for admin
  ORDER_ITEMS_GET: "orderItems/all/",
  ORDER_STATUS_GET: "orderStatus/all/",
  ORDER_STATUS_UPDATE: "order/update_status/", //put
  ORDER_CANCEL: "order/cancel_order/", //put cancel order for user
  ORDER_GETONE_SELLER: "order/get_one/seller/", //get details of an order for seller.
  ORDER_GETONE_USER: "order/get_one/user/", //get details of an order for user.
  ORDER_GETONE_ADMIN: "order/get_one/admin/", //get details of an order for user.

  PAYMENT_GET: "payments/all/",
  //PRODUCTS
  PRODUCTS_BYSTORE: "products/bystore", // GET all for a store
  PRODUCTS_CREATE: "products/create", //create
  PRODUCTS_GETONE: "products/item/",
  PRODUCTS_GETONE_LOGIN: "products/items/", //to access product details only after login
  PRODUCTS_GETONE_STORE: "products/seller/",
  PRODUCTS_DELETE: "products/delete", //delete
  PRODUCTS_UPDATE: "products/update/", //update
  PROUCTS_IMAGE_UPDATE: "products/update_image/", //update images
  PROUCTS_COVERIMG_UPDATE: "products/update_cover_img/", //update images
  PRODUCT_STATUS_UPDATE: "products/update_status/", //update status
  PRODUCT_UPDATE_VARIANTS: "products/update/variant/",
  PRODUCT_VARIANT_ADD: "productvariant/add_variants", //add variants[]
  PRODUCT_VARIANT_DELETE: "productvariant/delete/", //delete variant
  // ENQUIRY ------------
  ENQUIRY_CREATE: "Enquiry/post", // Post
  ENQUIRY_GET: "Enquiry/get",

  //ROLES AND MENUS
  ROLES: "roles", //GET,POST,PUT,DELETE
  MENUS: "menus", //GET,POST,PUT,DELETE

  //INVOICES
  INVOICE_GET_ONE: "invoice/get/", //get one invoice with token
  INVOICE_GET: "invoice/", //get one invoice
  INVOICE: "invoice/create", // post create a new invoice
  INVOICE_UPDATE: "invoice/",
  INVOICE_GET_ALL: "invoice/all", // get all invoices

  //PAYMENT GATEWAY
  PAYMENT_GATEWAY_GETTOKEN: "payment_gateway/token", //GET to get the token
  PAYMENT_GATEWAY_ORDER: "payment_gateway/order/", //GET
  PAYMENT_GATEWAY_DETAILS: "payment_gateway/details", //GET

  CALCULATE_DELIVERY_CHARGE: "calculate_delivery", //POST to get delivery charge of total

  SETTLEMENT_DETAILS: "settlements/details/", //get total orders, total charges..etc of a store  get
  SETTLEMENT_DETAILS_HISTORY: "settlements/history/", //to get settlement history of a store    get
  SETTLEMENT_DETAILS_CREATE: "settlements/create", //to add a settlement amount for admin //post
  SETTLEMENT_DETAILS_STORE: "settlements/store/", //get settlemet details,order details.. onnly for admin
  SETTLEMENT_DETAILS_UPDATE: "settlements/update/", //to update settlement sttus

  //LIVE RATE

  LIVERATE_GET: "liverate",
  LIVERATE_UPDATE: "liverate/",

  //BLOGS AND NEWS
  GET_LAST_3_BLOGS: "newsandblogs/last_three/",
  GET_ALL_BLOGS: "newsandblogs/findAll", // pagination
  ADD_BLOG: "newsandblogs/add_blogs/", // post
  DELETE_BLOG: "newsandblogs/delete/", // delete
  UPDATE_BLOG: "newsandblogs/update_blog/", // put
  FIND_ONE_BLOG: "newsandblogs/find_one/",

  ////TYPE////////

  CREATE_TYPE: "type", //POST , DELETE , GET by id

  UPDATE_TYPE: "type/update/",

  GET_TYPE_ALL: "type/all",
  TYPE_UPDATE_POSITION: "type/position/",

  GET_GOLD_LIVERATE: "gold_liverate/get", //get to get live gold rate and upate it in db

  GET_PURITY_ALL: "purity/all", //get to get all purity
  DELETE_PURITY: "purity/delete/", //to remove one purity item.
  ADD_PURITY: "purity/create", //to add new purity//post


  ADD_BRAND: "brand/create", //to add new brand//post
  GET_BRAND: "brand/all/", //to add new brand//getall
  DELETE_BRAND: "brand/delete/", //to remove brand//delete
  SELLUS: "sellus",
  VERIFYOTP:"sms/verify-otp",
  REQUESTOTP:"sms/request-otp"
};
export default API;
