import React from "react";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaStar } from "react-icons/fa6";

function ProductItem(props: any) {
  const navigate = useNavigate();
  const Settings = useSelector((state: any) => state.Settings.Settings);
  const liverate = useSelector((state: any) => state.Liverate.liverate);

  // const calculatePrice = () => {
  //   try {
  //     let method = props?.item?.price_method;
  //     let metal_type = props?.item?.metal_type;
  //     let weight = Number(props?.item?.weight);
  //     let vat = Number(props?.item?.vat);
  //     let labour_charge = Number(props?.item?.labour_charge);
  //     if (method === "Live") {
  //       const rate = liverate.length
  //         ? liverate.filter((i: any) => i.metal_type === metal_type)
  //         : [];
  //       if (rate?.length) {
  //         let price = Number(rate[0].ask);
  //         let amount = Number(price) * Number(weight);
  //         const labourCharge = Number(amount / 100) * labour_charge;
  //         let grand = Number(amount) + Number(labourCharge);
  //         let vatAmount = (grand * vat) / 100;
  //         let totalAmount = Number(grand) + Number(vatAmount);
  //         return totalAmount.toFixed(2);
  //       } else {
  //         return 0;
  //       }
  //     } else {
  //       return props?.item?.purchase_rate;
  //     }
  //   } catch (err) {
  //     return 0;
  //   }
  // };

  return (
    <div
      className={`ProductItem`}
      onClick={() => {
        navigate(
          `/product/details?pid=${props?.item?.pid}&name=${props?.item?.name}`
        );
      }}
    >
      <div className="ProductItem-Box1">
        <img
          src={props?.item?.image}
          className="ProductItem-img"
          alt={props?.item?.name}
        />
      </div>

      <div className="ProductItem-Box2">
        <div className="ProductItem-txt1 ">{props?.item?.name}</div>
        {props?.item?.unit <= 0 ? (
          <div className="ProductItem-txt3 text-danger">Out of stock</div>
        ) : (
          <>
            <div className="ProductItem-txt3">
              {props?.item?.grandTotal?.toFixed(2)} {Settings?.currency}
            </div>
            {props?.item?.discount > 0 ? (
              <>
                <span className="productDetails-cutPrice">
                  {" "}
                  {props?.item?.discountAmt?.toFixed(2)} {Settings?.currency}
                </span>
                <span className="productDetails-off">
                  {props?.item?.discount}% off{" "}
                </span>
              </>
            ) : null}
            <div className="ProductItem-txt2 text-danger fw-medium">
              {props?.item?.unit <= 5 ? ` only ${props?.item?.unit} left` : ""}
            </div>
          </>
        )}
        {props?.item?.averageRating ? (
          <span
            className=" rounded px-1"
            style={{ color: "black", fontWeight: "400" }}
          >
            <span style={{ fontSize: "12px" }}>
              {Number(props?.item?.averageRating)?.toFixed(1)}
            </span>{" "}
            <FaStar size={12} color="#f5da42" />{" "}
            <span className="ProductItem-txt5">
              {props?.item?.totalReviews
                ? `(${props?.item?.totalReviews})`
                : ""}
            </span>
          </span>
        ) : null}
      </div>
    </div>
  );
}
export default ProductItem;
