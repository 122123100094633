import React from "react";
import { Col, Row } from "react-bootstrap";
import { FcConferenceCall } from "react-icons/fc";
import { FaCircleUser, FaCartShopping } from "react-icons/fa6";
import { MdComputer } from "react-icons/md";
import API from "../../../config/API";

const DashboardProduct = (props: any) => {
  const cardData = [
    {
      title: "TOTAL USERS",
      amount: props?.totalUsers ?? 0,
      icon: <FaCircleUser size={40} color={API.COLOR} />,
      month: <>Total Active Users</>,
    },
    {
      title: "TOTAL SELLERS",
      amount: props?.totalSellers ?? 0,
      icon: <FcConferenceCall size={50} />,
      month: <>Total Approved Sellers</>,
    },
    {
      title: "TOTAL PRODUCTS",
      amount: props?.totalProducts ?? 0,
      icon: <MdComputer size={50} color="orange" />,
      month: <>Total Active Products</>,
    },
    {
      title: "TOTAL ORDERS",
      amount: props?.totalOrders ?? 0,
      icon: <FaCartShopping size={50} color="green" />,
      month: <>Total Orders On {API.NAME}</>,
    },
  ];
  return (
    <div className="dashboardProduct-Box1">
      <Row>
        {cardData.map((item: any) => (
          <Col key={item} md={3} className="mb-2">
            <div className="dashboardProduct-Box2">
              <div>
                <div className="dashboardProduct-Txt1">{item.title}</div>
                <div className="dashboardProduct-Txt2">{item.amount}</div>
                <div className="dashboardProduct-Txt3">{item.month}</div>
              </div>
              <div>{item.icon}</div>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default DashboardProduct;
