import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyBBWrz2rs4bFbdn_4kJiqFpBwZNOw556ok",
  authDomain: "lsg-ecommerce.firebaseapp.com",
  projectId: "lsg-ecommerce",
  storageBucket: "lsg-ecommerce.appspot.com",
  messagingSenderId: "351641623976",
  appId: "1:351641623976:web:5b546806a8c96831e8d19d",
  measurementId: "G-BR5FCEB0JQ"
};
const app = initializeApp(firebaseConfig);
const Auth = getAuth(app);
const GoogleProvide = new GoogleAuthProvider();
export { Auth, GoogleProvide };
