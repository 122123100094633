import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Form, Input, notification } from "antd";
import { BiErrorCircle } from "react-icons/bi";
import { InputOTP } from "antd-input-otp";
import { useTranslation } from "react-i18next";
import API from "../../config/API";
import { POST } from "../../utils/apiCalls";
import { login } from "../../redux/slices/userSlice";
import PrefixSelector from "../../components/prefixSelector";
import SuccessModal from "../../components/successModal";

function PhoneLogin() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [verification, setverification] = useState(false);
  const [error, setError] = useState<any>(null);
  const [notificationApi, contextHolder] = notification.useNotification();
  const [token, setToken] = useState();
  const [number, setNumber] = useState();
  const [code, setCode] = useState();
  const [successmodal, setSuccessmodal] = useState(false);
  const [userData, setUserData] = useState();

  const requestOTP = async (data: any) => {
    try {
      setIsLoading(true);
      let reqObj = {
        code: data?.code,
        phoneNumber: data?.phone,
        type: "login",
      };
      setNumber(data?.phone);
      setCode(data?.code);
      let url = API.REQUESTOTP;
      let res: any = await POST(url, reqObj);
      if (res.status) {
        setUserData(res);
        setToken(res.token);
        setverification(true);
      } else {
        notification.error({
          message: res.message,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };
  const verifyOtp = async (data: any) => {
    try {
      setIsLoading(true);
      const url = API.VERIFYOTP;
      let reqBody = {
        otp: data?.otp?.join(""),
        token: token,
        phoneNumber: number,
      };
      let res: any = await POST(url, reqBody);
      if (res.status) {
        await PhoneLogin(number);
      } else {
        notification.error({
          message: res.message,
        });
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const PhoneLogin = async (number: any) => {
    try {
      let url = API.LOGIN_PHONE;
      let body = {
        code: code,
        phoneNumber: number,
        token: token,
      };
      let loginRes: any = await POST(url, body);
      if (loginRes.status) {
        setSuccessmodal(true);
        setTimeout(() => {
          dispatch(login(loginRes));
        }, 2000);
      }
    } catch (error) {}
  };
  return (
    <div className="PhoneLogin">
      {contextHolder}
      <div>
        <div className="LoginScreen-txt2">
          {verification
            ? `${t("Enter_OTP")} ${number}`
            : `${t("sign_in_desc2")}`}
        </div>
        <br />
        <Form
          onFinish={verification ? verifyOtp : requestOTP}
          initialValues={{ code: "+91" }}
        >
          {verification ? (
            <Form.Item
              name="otp"
              rules={[
                {
                  required: true,
                  message: `${t("Input_6_digit")}`,
                },
              ]}
            >
              <InputOTP autoFocus inputType="numeric" length={6} />
            </Form.Item>
          ) : (
            <>
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: `${t("Input_your_phone_number")}`,
                  },
                ]}
              >
                <Input
                  addonBefore={<PrefixSelector />}
                  style={{ width: "100%" }}
                  size="large"
                  placeholder={t("Enter_Phone_Number")}
                  type="number"
                />
              </Form.Item>
            </>
          )}
          {error ? (
            <div className="LoginScreen-errortxt">
              <BiErrorCircle />
              &nbsp;
              {error} {t("Try_antoher_way")}
            </div>
          ) : null}
          <Form.Item>
            <Button
              loading={isLoading}
              block
              size="large"
              type="primary"
              htmlType="submit"
              style={{ height: 45 }}
            >
              {verification ? `${t("Login")}` : `${t("Get_OTP")}`}
            </Button>
            {error ? (
              <Button
                className="mt-2"
                loading={isLoading}
                block
                size="large"
                style={{ height: 40 }}
                htmlType="submit"
                onClick={requestOTP}
              >
                {`${t("Resend_OTP")}`}
              </Button>
            ) : null}
          </Form.Item>
        </Form>
        <hr />
      </div>
      {successmodal ? (
        <SuccessModal
          visible={successmodal}
          onCancel={() => setSuccessmodal(false)}
          title="Success"
          body="Logged in successfully! Welcome back!"
          onButtonClick={() => setSuccessmodal(false)}
          buttonText="Start Purchasing"
        />
      ) : null}
    </div>
  );
}
export default PhoneLogin;
