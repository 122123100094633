import React from "react";
import "./styles.scss";
import { Row, Col, Container } from "react-bootstrap";
import {
  FaCcVisa,
  FaCcMastercard,
  FaCcPaypal,
  FaCcApplePay,
} from "react-icons/fa";
import { RiFacebookCircleLine } from "react-icons/ri";
import { BiLogoInstagram } from "react-icons/bi";
import { FaXTwitter } from "react-icons/fa6";
import { PiYoutubeLogoLight } from "react-icons/pi";
import { LiaWhatsapp } from "react-icons/lia";
import Logo from "../../config/logo.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import API from "../../config/API";
import { FaSquarePhone } from "react-icons/fa6";

const Footer = () => {
  const { t } = useTranslation();
  const handleLinkClick = (link: string) => {
    window.open(link, "_blank");
  };
  return (
    <div className="footer">
      <br />
      <Container>
        <main className="footer-container">
          <Row>
            <Col lg={3} md={6}>
              <div className="list-container">
                <div className="list-heading">{t("company")}</div>
                <ul>
                  <li>
                    <Link to="/about" className="custom-link">
                      {t("about")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/careers" className="custom-link">
                      {t("careers")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/terms-and-conditions" className="custom-link">
                      {t("terms_and_conditions")}
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className="list-container">
                <div className="list-heading">{t("assistance")}</div>
                <ul>
                  <li>
                    <Link to="/return-policy" className="custom-link">
                      {t("return_policy")}
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className="list-container">
                <div className="list-heading">{t("publications")}</div>
                <ul>
                  <li>
                    <Link to="/catalogue" className="custom-link">
                      {t("catelogue")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/newsletter" className="custom-link">
                      {t("newsletter")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/blog" className="custom-link">
                      {t("blog")}
                    </Link>
                  </li>
                  <li
                    onClick={() =>
                      window.open(`https://wa.me/${API.CONTACT_NUMBER}`)
                    }
                  >
                    {t("whatsapp_us")}
                  </li>
                  <li
                    onClick={() =>
                      (window.location.href = `tel:${API.CONTACT_NUMBER}`)
                    }
                  >
                    {API.CONTACT_NUMBER}
                  </li>
                  <li>
                    <Link to="/tell-more" className="custom-link">
                      {t("tell_more")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/share" className="custom-link">
                      {t("Share")}
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className="list-container ">
                <div className="list-heading">{t("payment_methrd")}</div>
                <div className="Payment-cards">
                  <FaCcVisa size={40} color="#fafafa" />
                  <FaCcMastercard size={40} color="#fafafa" />
                  <FaCcPaypal size={40} color="#fafafa" />
                  <FaCcApplePay size={40} color="#fafafa" />
                </div>
              </div>
              <div className="logoContainer">
                <img className="listFooter-logo" src={Logo} />
                <div className="Payment-cards">
                  <RiFacebookCircleLine
                    onClick={() =>
                      handleLinkClick(
                        "https://www.facebook.com/ZiyaGoldUAE?mibextid=ZbWKwL"
                      )
                    }
                    size={25}
                    color="#fafafa"
                  />
                  <BiLogoInstagram
                    onClick={() =>
                      handleLinkClick(
                        "https://www.instagram.com/ziyagold_?igsh=aTVyY2Jlem4xeGtr"
                      )
                    }
                    size={25}
                    color="#fafafa"
                  />
                  <FaXTwitter
                    onClick={() =>
                      handleLinkClick(
                        "https://www.tiktok.com/@ziyagolduae?_t=8lUmHAJQvz8&_r=1"
                      )
                    }
                    size={22}
                    color="#fafafa"
                  />
                  <PiYoutubeLogoLight size={24} color="#fafafa" />
                  <LiaWhatsapp
                    onClick={() =>
                      handleLinkClick("https://wa.me/+971565055539")
                    }
                    size={24}
                    color="#fafafa"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </main>
      </Container>
      <div className="final-footer">
        <Container>
          <div className="final-footer-inner">
            <span className="copyright-txt">
              COPYRIGHT © 2023 ZIYA GOLD TRADING L.L.C ALL RIGHTS RESERVED
            </span>
            <span className="copyright-txt-2">{t("terms_desc")}</span>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Footer;
