import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IoBagOutline, IoCallOutline, IoPersonOutline } from "react-icons/io5";
import Logo from "../../assets/images/logo1.png";
import { useTranslation } from "react-i18next";
import { Badge, Popover, Tooltip } from "antd";
import LanguageSelector from "./components/languageSelector";
import SearchBar from "./searchBar";
import ProfileMenu from "./components/profileMenu";
import useToggle from "../../shared/hook/useToggle";
import React, { useEffect, useState } from "react";
import { LocationType } from "../../shared/types/types";
import API from "../../config/API";
import SellusModal from "../sellusModal";
import { MdOutlineSell } from "react-icons/md";
import { BsShopWindow } from "react-icons/bs";
import { Col, Row } from "react-bootstrap";
import { FiPhoneCall } from "react-icons/fi";

function DeskTop() {
  const [openForm, setOpenForm] = useState(false);
  const Sell = <span>Sell to Ziya</span>;
  const CartText = <span>Cart</span>;
  const Contact = <span>Contact</span>;
  const Store = <span>Manage Store</span>;
  const Login = <span>Login</span>;
  // const [searchOpen, setsearchOpen] = useState(false);
  const Auth = useSelector((state: any) => state.User);
  const User = useSelector((state: any) => state.User.user);
  const Cart = useSelector((state: any) => state.Cart);
  const [openLocation, toggleLocation] = useToggle(false);
  const Settings = useSelector((state: any) => state.Settings.Settings);
  const navigation = useNavigate();
  const LocationDetails: LocationType = useSelector(
    (state: any) => state.Location.location
  );
  const Category = useSelector((state: any) => state?.Category?.categries);
  const [openStates, setOpenStates] = useState(
    Array.isArray(Category) ? Category.map((item: any) => false) : [false]
  );
  const { t } = useTranslation();
  const [issharepopovervisible, setissharepopovervisible] = useState(false);
  const OpenLink = (link: any) => {
    if (Auth.auth) {
      navigation(link);
    } else {
      navigation("/login");
    }
  };
  const handlepopovervisiblechange = () => {
    setissharepopovervisible(!issharepopovervisible);
  };
  useEffect(() => {
    if (
      !LocationDetails.full_address &&
      !LocationDetails.postal_code &&
      Settings?.isLocation
    ) {
      toggleLocation(true);
    }
  }, [openLocation]);
  const OpenLink1 = () => {
    if (Auth?.auth) {
      switch (User?.data?.type) {
        case "user":
          navigation("/seller");
          break;
        default:
          navigation(`/auth/check_store`);
          break;
      }
    } else {
      navigation("/seller");
    }
  };

  return (
    <>
      <div className="Header">
        <img
          className="Header-logo"
          onClick={() => navigation("/")}
          src={Logo}
        />
        <div className="Header-container">
          <div>
            <SearchBar />
          </div>
          <div className="Header-deskcat-2">
            {Category && Category.length
              ? Category.map((item: any, index: number) => {
                  return (
                    <Popover
                      key={index}
                      arrow={false}
                      placement="bottomRight"
                      open={openStates?.[index]}
                      onOpenChange={() => {
                        setOpenStates((state: boolean[]) => {
                          return state?.map((value, i) =>
                            i === index ? !value : value
                          );
                        });
                      }}
                      content={
                        <div style={{ width: "50vw" }}>
                          <Row>
                            <Col
                              style={{ borderLeft: "solid 1px gray" }}
                              sm={8}
                            >
                              <div className="Header-deskCatItemtxt1">
                                {item?.name}
                              </div>
                              <div className="Header-deskCatItemline" />
                              <Row>
                                {item?.sub_categories?.length &&
                                  item.sub_categories.map(
                                    (sub: any, key: number) => (
                                      <Col key={key} sm={6}>
                                        <div
                                          className="Header-deskCatItem3"
                                          onClick={() => {
                                            navigation(
                                              `/products/category?id=${window.btoa(
                                                sub._id
                                              )}&type=${sub.name}`,
                                              {
                                                state: {
                                                  sub,
                                                },
                                              }
                                            );

                                            setOpenStates((state: boolean[]) =>
                                              state?.map(() => false)
                                            );
                                          }}
                                        >
                                          {sub.name}
                                        </div>
                                        {sub?.types?.map(
                                          (val: any, index: any) => (
                                            <div
                                              onClick={() => {
                                                navigation(
                                                  `/products/category?id=${window.btoa(
                                                    sub._id
                                                  )}&tid=${val?._id}&type=${
                                                    sub?.name
                                                  }`,
                                                  {
                                                    state: {
                                                      val,
                                                    },
                                                  }
                                                );

                                                setOpenStates(
                                                  (state: boolean[]) =>
                                                    state?.map(() => false)
                                                );
                                              }}
                                              key={index}
                                              className="Header-deskTypeitem"
                                            >
                                              <img
                                                src={val?.image}
                                                className="Header-deskTypeimg"
                                              />
                                              {val?.name}
                                            </div>
                                          )
                                        )}
                                      </Col>
                                    )
                                  )}
                              </Row>
                            </Col>
                            <Col sm={4}>
                              <div className="Header-deskCatItemBox">
                                <img
                                  src={item?.image}
                                  className="Header-deskCatItemImg"
                                  alt=""
                                />
                                <br />
                                <div className="Header-deskCatItemtxt2">
                                  {item?.description}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      }
                    >
                      <div className="Header-deskCatItem">
                        {item && item.name}
                      </div>
                    </Popover>
                  );
                })
              : null}
          </div>
        </div>
        <div style={{ display: "flex", columnGap: 30 }}>
          <div className="Header-iconContainer">
            <div className="Header-lastIcons">
              <Tooltip title={Sell} className="custom-tooltip">
                <MdOutlineSell
                  className="HeadeIcon-cursor"
                  onClick={() => setOpenForm(true)}
                  size={20}
                  color="#FCDDEC"
                />
              </Tooltip>
              <Tooltip title={CartText} className="custom-tooltip">
                <Badge count={Cart.items.length} color={API.COLOR}>
                  <IoBagOutline
                    className="HeadeIcon-cursor"
                    onClick={() => OpenLink("/cart")}
                    size={20}
                    color="#FCDDEC"
                  />
                </Badge>
              </Tooltip>
              <LanguageSelector />
              <Tooltip title={Contact} className="custom-tooltip">
                <FiPhoneCall
                  className="HeadeIcon-cursor"
                  onClick={() =>
                    window.open(`https://wa.me/${API.CONTACT_NUMBER}`, "_blank")
                  }
                  size={20}
                  color="#FCDDEC"
                />
              </Tooltip>
              {Settings?.type === "multi" || User?.data?.type === "admin" ? (
                <Tooltip title={Store} className="custom-tooltip">
                  <BsShopWindow
                    className="HeadeIcon-cursor"
                    onClick={() => OpenLink1()}
                    color="#FCDDEC"
                    size={20}
                  />
                </Tooltip>
              ) : null}
            </div>
          </div>
          <Popover
            placement="bottomRight"
            trigger="click"
            content={<ProfileMenu close={handlepopovervisiblechange} />}
            arrow={false}
            visible={issharepopovervisible}
            onVisibleChange={handlepopovervisiblechange}
          >
            {User?.data?.image ? (
              <img
                src={User?.data?.image}
                className="Header-ProfileImag"
                alt="profile"
              />
            ) : (
              <Tooltip title={Login} className="custom-tooltip">
                <IoPersonOutline
                  className="HeadeIcon-cursor"
                  color="#FCDDEC"
                  size={20}
                />
              </Tooltip>
            )}
          </Popover>
        </div>
      </div>
      {openForm ? (
        <SellusModal onClose={() => setOpenForm(false)} visible={openForm} />
      ) : null}
      {/* <div className="Header">
        <img onClick={() => navigation("/")} src={Logo} />
        <div className="Header-icons">
        <div className="Header-IconCursor">
          <SearchBar />
          </div>
          <div className="Header-IconCursor">
            <Badge count={Cart.items.length} color={API.COLOR}>
              <IoBagOutline
                onClick={() => OpenLink("/cart")}
                size={20}
                color="#B1004B"
              />
            </Badge>
          </div>
            <LanguageSelector />
          <div className="Header-IconCursor">
          {Settings?.type === "multi" || User?.data?.type === "admin" ? (
            <BsShopWindow
              onClick={() => OpenLink1()}
              color="#B1004B"
              size={20}
            />
          ) : null}
          </div>
        </div>
        <div className="Header-menu">
          <span onClick={() => setOpenForm(true)}>
            <MdOutlineSell size={20} color="#B1004B" /> 
            &nbsp; Sell to US
          </span>
          <span
            onClick={() =>
              window.open(`https://wa.me/${API.CONTACT_NUMBER}`, "_blank")
            }
          >
            <IoCallOutline size={20} color="#B1004B" />
            &nbsp;
            {API.CONTACT_NUMBER}
          </span>
          <Popover
            placement="bottomRight"
            trigger="click"
            content={<ProfileMenu close={handlepopovervisiblechange} />}
            arrow={false}
            visible={issharepopovervisible}
            onVisibleChange={handlepopovervisiblechange}
          >
            {User?.data?.image ? (
              <img
                style={{ marginTop: -4, marginBottom: -4, marginLeft: -10 }}
                src={User?.data?.image}
                className="Header-ProfileImag"
                alt="profile"
              />
            ) : (
              <IoPersonOutline color="#B1004B" size={20} />
            )}
          </Popover>
        </div>
      </div>
      {openForm ? (
        <SellusModal onClose={() => setOpenForm(false)} visible={openForm} />
      ) : null} */}
    </>
    // <div className="Header-deskBox">
    //   <div onClick={() => navigation("/")}>
    //     <img src={Logo} className="Header-deskLogo" alt="logo" />
    //   </div>
    //   <div style={{ margin: 20 }} />
    //   <div className="Header-desk-menu2" onClick={()=>navigation("/liveRates")}>
    //     <div>
    //       <div className="Header-txt2">{t("Live_Rate")}</div>
    //       <div>{t("TODAYS_PRICE")}</div>
    //     </div>
    //   </div>
    //   <div style={{ margin: 30 }} />
    //   <SearchBar />
    //   <div style={{ margin: 20 }} />
    //   {Settings?.isLocation == true ? (
    //     <div
    //       className="Header-desk-menu Header-deskactive"
    //       onClick={() => toggleLocation(true)}
    //     >
    //       <div>
    //         <IoLocationOutline size={25} />
    //       </div>
    //       <div className="Header-location">
    //         {LocationDetails.latitude
    //           ? LocationDetails.full_address
    //             ? LocationDetails?.full_address
    //             : LocationDetails.postal_code
    //           : "Select Location"}
    //       </div>
    //     </div>
    //   ) : (
    //   <>
    //   </>
    //   )}
    //   <div style={{ margin: 10 }} />
    //   <div className="Header-desk-menu" onClick={() => OpenLink("/cart")}>
    //     <div className="DesktopHeader">
    //       <Badge count={Cart.items.length} color={API.COLOR}>
    //         <IoBagOutline size={28} color="#fafafa"/>
    //       </Badge>
    //     </div>
    //   </div>
    // <Popover
    //   placement="bottomRight"
    //   trigger="click"
    //   content={<ProfileMenu close={handlepopovervisiblechange} />}
    //   arrow={false}
    //   visible={issharepopovervisible}
    //   onVisibleChange={handlepopovervisiblechange}
    // >
    //   <div
    //     className={
    //       Auth.auth
    //         ? "Header-desk-menu Header-deskactive"
    //         : "Header-desk-menu"
    //     }
    //   >
    // {User?.data?.image ? (
    //   <img
    //     style={{ marginTop: -4, marginBottom: -4 ,marginLeft:-10}}
    //     src={User?.data?.image}
    //     className="Header-ProfileImag"
    //     alt="profile"
    //   />
    // ) : (
    //   <IoPersonOutline size={28} />
    // )}
    //   </div>
    // </Popover>

    //   <LanguageSelector />
    //   {openLocation ? (
    //     <ChooseLocationModal open={openLocation} modalClose={toggleLocation} />
    //   ) : null}
    // </div>
  );
}
export default DeskTop;
