import React from "react";
import "./styles.scss";

import { Tabs } from "antd";
import { Container } from "react-bootstrap";
import { BiSolidUser } from "react-icons/bi";
import { MdOutlineLockPerson } from "react-icons/md";

import PageHeader from "../components/PageHeader";
import UserRoles from "./UserRoles";
import MenuRoles from "./MenuRoles";

const MenuConfig = () => {
  return (
    <div>
      <PageHeader title="Menu Configuration" />
      <Tabs
        defaultActiveKey="1"
        size="small"
        indicatorSize={(origin) => origin + 16}
        tabBarStyle={{ paddingLeft: 10, paddingRight: 10, textAlign: "center" }}
      >
        <Tabs.TabPane
          key={"1"}
          tab={
            <span>
              <BiSolidUser
                style={{ marginTop: -2, marginRight: 10 }}
                size={15}
              />
              Users & Roles
            </span>
          }
        >
          <Container fluid style={{ marginTop: -15 }}>
            <UserRoles />
          </Container>
        </Tabs.TabPane>
        <Tabs.TabPane
          key={"2"}
          tab={
            <span>
              <MdOutlineLockPerson
                style={{ marginTop: -2, marginRight: 10 }}
                size={15}
              />
              &nbsp;Permissions{" "}
            </span>
          }
        >
          <Container fluid style={{ marginTop: -15 }}>
            <MenuRoles />
          </Container>
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default MenuConfig;
