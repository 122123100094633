import React, { useState } from "react";
import Slider from "react-slick";
function Banners(props: any) {
  const [current, setCurrentSlideNumber] = useState(0);
  const Slidesettings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    appendDots: (dots:any) => (
      <div  style={{
        backgroundColor: "#FFF",
        padding: "10px",
      }}>
        <ul style={{ margin: "0px" }}>{dots}</ul>
      </div>
    ),
    customPaging: (i:any) => (
      <div className={i === current?"square active":"square"}></div>
    )
  };

  return (
    <div className="HomeScreen-BannerBox" style={{backgroundColor:"#f4edf0"}}>
      {props?.data?.length ? (
        <Slider {...Slidesettings} beforeChange={(currentSlide: number, nextSlide: number) => setCurrentSlideNumber(nextSlide)}>
          {props.data.map((bann: any) => {
            return (
              <div key={bann.id}>
                <div
                  key={bann.id}
                  className="HomeScreen-Banners"
                  style={{ backgroundImage: `url(${bann.img_desk})` }}
                >
                  <div className="HomeScreen-BannersBox">
                    <div className="HomeScreen-BannersBox2">
                    <div className="HomeScreen-Bannertxt2">{bann.title}</div>
                    <div className="HomeScreen-Bannertxt3">
                      {bann.description}
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      ) : null}
    </div>
  );
}
export default Banners;
