import "./styles.scss";
import { Col, Container, Row } from "react-bootstrap";
import Sidebar from "./components/sidebar";
import MainArea from "./components/mainArea";
import React from "react";


function ProfileScreen() {
  return (
    <div className="Screen-box">
      <Container fluid={true}>
        <Row>
          <Col
            sm={2}
            xs={12}
            style={{ margin: 0, padding: 0 }}
          >
            
            <div className="profile-sideBar">
              <Sidebar />
            </div>
          </Col>
          <Col sm={10} xs={12}>
            <div className="ProfileScreen-box">
              <MainArea />
            </div>
      <br/>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default ProfileScreen;
