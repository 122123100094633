import React, { useEffect, useState, useRef } from "react";
import { Button, Tabs, TabsProps } from "antd";
import PageHeader from "../components/PageHeader";
import SellerDetailsPage from "./tabs/sellerDetails";
import OrderDetails from "./tabs/orderDetails";
import SettlementDetails from "./tabs/settlements";
import { useParams } from "react-router-dom";
import Orders, { OrderPageAdminRefType } from "./tabs/orders";
import Search from "antd/es/input/Search";

function SellerDetails() {
  const params = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [key, setKey] = useState("informations");
  const [search, setSearch] = useState("");
  const ref = useRef<OrderPageAdminRefType>(null);
  const [searching, setSearching] = useState(false);
  const items: TabsProps["items"] = [
    {
      key: "informations",
      label: "Informations",
      children: <SellerDetailsPage storeId={params?.id} />,
    },
    {
      key: "orders",
      label: "Order Details",
      children: <OrderDetails storeId={params?.id} />,
    },
    {
      key: "settlements",
      label: "Settlement Details",
      children: (
        <SettlementDetails
          storeId={params?.id}
          closeModal={() => setOpenModal(false)}
          openModal={openModal}
        />
      ),
    },
    {
      key: "orderlist",
      label: "Orders",
      children: (
        <Orders
          storeId={params?.id}
          search={search}
          ref={ref}
          setSearching={setSearching}
        />
      ),
    },
  ];

  const onChange = (key: string) => {
    setKey(key);
  };
  const onSearch = async (value: string) => {
    if (value) {
      setSearch(value);
    }
  };
  return (
    <>
      <PageHeader title="Seller Info">
        {" "}
        {key == "settlements" ? (
          <Button type="primary" onClick={() => setOpenModal(true)}>
            Settle Payments
          </Button>
        ) : null}
        {key == "orderlist" ? (
          <Search
            placeholder="Search Order ID"
            allowClear
            enterButton="Search"
            size="middle"
            style={{ width: "300px" }}
            onSearch={(value: string) => {
              ref?.current?.search(value);
            }}
            type="number"
            loading={searching}
          />
        ) : null}
      </PageHeader>
      <Tabs defaultActiveKey={key} items={items} onChange={onChange} />
    </>
  );
}

export default SellerDetails;
