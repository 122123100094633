import { Col, Row } from "react-bootstrap";
import SaleMessage from "../../components/saleMessage";
import SubscribeNewsletter from "../../components/subscribeNewsletter";
import PageHeader from "../../components/pageHeader";
import "./styles.scss";
import { useEffect } from "react";
import React from "react";
import API from "../../config/API";
import { useTranslation } from "react-i18next";

function LatestCatelogue() {
  return (
    <div className="Screen-box">
      <div
        style={{
          fontWeight: 700,
          fontSize: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        Ooops! We Are Under Maintanance
      </div>
    </div>
  );
}
export default LatestCatelogue;
