import { Avatar, List, Popconfirm, Popover } from "antd";
import React from "react";
import "./style.scss";

export default function AddressDataList(props: any) {
  return (
    <List
      className="profilescreen-address-lists"
      itemLayout="horizontal"
      dataSource={props.data}
      pagination={
        props?.data.length > props?.pageSize && { pageSize: props?.pageSize }
      }
      renderItem={(item: any, index) => (
        <List.Item>
          <div className="profilescreen-address-card">
            <span className="profilescreen-address-type text-uppercase">{item.type}</span>

            <div className="profilescreen-address-name-phoneno">
              <span> {item.userDetails.name}</span>
              <span className="profilescreen-address-phoneno">
                
                {item.code??''}{" "}{item.alt_phone}
              </span>
            </div>
            <div className="profilescreen-address-fulladdress">
              {item.fullAddress}
            </div>
            <div className="profilescreen-address-formaddress">
              {item.flat}, {item.street}, {item.city}, {item.state} ,
              {item.pin_code}
            </div>
            <div className="profilescreen-address-editdelete">
              <span>
                <a
                  className="profilescreen-address-edit-btn"
                  onClick={() => {
                    props?.setType();
                    props?.toggleModal(true);
                    props?.getSelected(item);
                  }}
                >
                  Edit
                </a>
                <Popconfirm
                  placement="topRight"
                  title={"Are you sure to delete?"}
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => props?.delete(item)}
                >
                  <a className="profilescreen-address-delete-btn">Delete</a>
                </Popconfirm>
              </span>
            </div>
          </div>
        </List.Item>
      )}
    />
  );
}
