import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import API from "../../../config/API";
import { GET } from "../../../utils/apiCalls";
import "../styles.scss";
import AdminLoading from "../../components/AdminLoading";
import NoData from "../../../components/noData";
type props = {
  storeId: number | string | undefined;
};
function OrderDetails({ storeId }: props) {
  const [details, setDetails] = useState<any>({});
  const [loading, setLoading] = useState(true);

  const getSettlementDetails = async () => {
    const url = API.SETTLEMENT_DETAILS + storeId;
    if (storeId) {
      try {
        const response: any = await GET(url, null);
        if (response?.status) {
          setDetails(response?.data);
        }
      } catch (err) {
      } finally {
        setLoading(false);
      }
    }
  };
  const totalsData = [
    {
      title: "Total Orders",
      value: details?.totalOrders ?? 0,
    },
    {
      title: "Total Units Sold",
      value: details?.totalUnits ?? 0,
    },
    {
      title: "Total Pending Orders",
      value: details?.pendingOrders ?? 0,
    },
    {
      title: "Total Delivered Orders",
      value: details?.deliveredOrders ?? 0,
    },
    {
      title: "Total Rejected Orders",
      value: details?.rejectedOrders ?? 0,
    },
    {
      title: "Total Shipped Orders",
      value: details?.shippedOrders ?? 0,
    },
    {
      title: "Total Packed Orders",
      value: details?.packedOrders ?? 0,
    },
    {
      title: "Total Out for Delivery Orders",
      value: details?.outforDeliveryOrders ?? 0,
    },
    {
      title: "Total Cancelled Orders",
      value: details?.cancelledOrders ?? 0,
    },
  ];

  useEffect(() => {
    getSettlementDetails();
  }, []);
  return (
    <>
      {loading ? (
        <AdminLoading />
      ) : details?.totalOrders ? (
        <div>
          <Row className="TotalscardContainer g-3">
            {totalsData.map((data, index) => (
              <Col md={3} key={index}>
                <div className="Totalscard">
                  <div className="title">
                    <p className="title-text">{data.title}</p>
                  </div>
                  <div className="data">
                    <p>{data.value}</p>
                    <div className="range">
                      {/* <div className="fill"></div> */}
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      ) : (
        <NoData />
      )}
    </>
  );
}

export default OrderDetails;
