import { Form, Input, InputNumber, Modal, Select, notification } from "antd";
import React, { useEffect, useState } from "react";
import API from "../../../config/API";
import { POST } from "../../../utils/apiCalls";
type props = {
  openModal: boolean;
  storeId: number | string | undefined;
  closeModal: Function;
  getSettlements: Function;
  limit: number;
};
function SettlementModal({
  openModal,
  storeId,
  closeModal,
  getSettlements,
  limit,
}: props) {
  const [amount, setAmount] = useState<number | null>(0);
  const [form] = Form.useForm();
  const [Notifications, contextHolder] = notification.useNotification();
  const [loading2, setLoading2] = useState(false);
  const { Option } = Select;
  const formSubmitHandler = async (values: any) => {
    const url = API.SETTLEMENT_DETAILS_CREATE;
    try {
      const body = {
        ...values,
        storeId: storeId,
      };

      setLoading2(true);
      const response: any = await POST(url, body);
      if (response.status) {
        Notifications["success"]({
          message: "Settlement Amount Updated Successfully.",
          description: "",
        });
        form.resetFields();
        closeModal();
        getSettlements();
      } else {
        Notifications["error"]({
          message: response?.message ?? "",
          description: "",
        });
      }
    } catch (err) {
      Notifications["error"]({
        message: "Something went wrong.",
        description: "",
      });
    } finally {
      setLoading2(false);
    }
  };
  useEffect(() => {
    if (amount != null && amount > limit) {
      Notifications["error"]({
        message: "Settlement Amount Must be less than Balance",
        description: "",
      });
    }
  }, [amount]);
  return (
    <Modal
      title="Settlement Amount"
      open={openModal}
      onOk={() => {
        if (limit == 0) {
          return;
        }
        form.submit();
      }}
      confirmLoading={loading2}
      onCancel={() => closeModal()}
      centered
      okText="Submit"
    >
      {contextHolder}
      {limit == 0 ? (
        <div className="text-danger">There is no balance to settle</div>
      ) : null}
      <Form form={form} layout="vertical" onFinish={formSubmitHandler}>
        <Form.Item
          label={
            <>
              {`Enter Amount. `}{" "}
              <span className="fw-bold">{` (Amount to Settle:${limit})`}</span>
            </>
          }
          name={"paid"}
          rules={[
            {
              required: true,
              message: "Please Enter Amount",
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          label="Payment Type"
          name="payment_type"
          rules={[
            {
              required: true,
              message: "Please Select Payment Type",
            },
          ]}
        >
          <Select
            bordered={false}
            style={{ width: "100%" }}
            className="border rounded"
            allowClear
          >
            <Option value={"online"}>Online Payment</Option>
            <Option value={"cash in hand"}>Cash In hand</Option>
            <Option value={"cash in hand"}>Bank Transfer</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Payment Status"
          name="status"
          rules={[
            {
              required: true,
              message: "Please Select Payment Status",
            },
          ]}
        >
          <Select
            bordered={false}
            style={{ width: "100%" }}
            className="border rounded"
            allowClear
          >
            <Option value={"requested"}>Requested</Option>
            <Option value={"pending"}>Pending</Option>
            <Option value={"success"}>Completed</Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default SettlementModal;
