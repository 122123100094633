import React, { useEffect, useState } from "react";
import API from "../../config/API";
import { GET } from "../../utils/apiCalls";
import PageHeader from "../components/PageHeader";
import { useNavigate } from "react-router-dom";
import NoData from "../../components/noData";
import AdminLoading from "../components/AdminLoading";
import SellusDataTable from "./dataTable";
import { Button, notification } from "antd";
import Search from "antd/es/input/Search";
import { GrPowerReset } from "react-icons/gr";

function SellUs() {
  const [sellus, setSellus] = useState([]);
  const [loading, setLoading] = useState(true);
  const pageSize = 12;
  const [page, setPage] = useState<number>(1);
  const [meta, setMeta] = useState<any>({});
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [notificationApi, contextHolder] = notification.useNotification();
  const getSellusData = async (currentPage: number = 1, name: string = "") => {
    setLoading(true);
    try {
      let url = API.SELLUS + `?order=DESC&page=${currentPage}&take=${pageSize}`;
      const response: any = await GET(url, null);
      if (response.status) {
        const { data, meta } = response;
        setSellus(data);
        setMeta(meta);
      } else {
        notificationApi.error({ message: response?.message ?? "" });
      }
    } catch (err) {
      notificationApi.error({ message: `Something went wrong` });
    } finally {
      setLoading(false);
    }
  };

  const changePage = async (page: number) => {
    await getSellusData(page, search);
    setPage(page);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    getSellusData();
  }, []);

  return (
    <>
      <PageHeader
        title={`Sell Us ${loading ? "" : `(${meta?.itemCount ?? 0})`}`}
      >
        {/* <Search
          placeholder="Search Name/Phone/Email"
          allowClear
          enterButton="Search"
          size="middle"
          onSearch={onSearch}
          loading={searching}
        /> */}
        {/* {reset ? (
          <Button
            type="primary"
            icon={<GrPowerReset />}
            size={"middle"}
            onClick={() => {
              getUsersData();
              setReset(false);
              setPage(1);
              setSearch("");
            }}
          />
        ) : null} */}
      </PageHeader>
      {contextHolder}
      {loading && loading ? (
        <AdminLoading />
      ) : sellus.length ? (
        <SellusDataTable
          data={sellus}
          changePage={changePage}
          getUsers={getSellusData}
          meta={meta}
          page={page}
          pageSize={pageSize}
        />
      ) : (
        <NoData header={"No data Found"} />
      )}
    </>
  );
}
export default SellUs;
