import React, { useEffect, useState } from "react";
import { Modal, Select, Spin, Tooltip } from "antd";
import { Button, Form, Input, Radio, notification } from "antd";
import { CURRENT_LOCATION, POST, PUT } from "../../../utils/apiCalls";
import API from "../../../config/API";
import Country from "../../../config/countryCode.json";
import { useSelector } from "react-redux";
import TextArea from "antd/es/input/TextArea";
import { currentLoctionType } from "../../../shared/types/types";
import "./style.scss";
import { Col, Row } from "react-bootstrap";
import AutoCompleteLocation from "./autoCompleteLocation";
import PrefixSelector from "../../../components/prefixSelector";
const update = "update";
const AddressModal = (props: any) => {
  const [form] = Form.useForm();
  const [Notifications, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const User = useSelector((state: any) => state.User.user);
  const [locationLoading, setLocationLoading] = useState(false);
  const [latLong, setLatLong] = useState<{ lat: number; long: number }>();
  const type = props?.type;
  // const [messageApi, contextHolder2] = message.useMessage();
  useEffect(() => {
    if (type == update) {
      form.setFieldsValue({
        type: props?.selected?.type,
        flat: props?.selected?.flat,
        pincode: props?.selected?.pin_code,
        state: props?.selected?.state,
        street: props?.selected?.street,
        city: props?.selected?.city,
        code: props?.selected?.code,
        phone: props?.selected?.alt_phone,
        location: props?.selected?.geo_location,
        address: props?.selected?.fullAddress,
      });
    } else {
      form.resetFields();
    }
  }, [type, props?.selected]);
  const formSubmitHandler = async (values: any) => {
    const url =
      type == update ? API.ADDRESS + props?.selected?.id : API.ADDRESS;
    const obj = {
      userId: User.data._id,
      flat: values?.flat,
      pin_code: values?.pincode,
      state: values?.state,
      city: values?.city,
      street: values?.street,
      code: values?.code,
      alt_phone: values?.phone,
      geo_location: values?.location,
      fullAddress: values?.address,
      type: values?.type,
      lat: latLong?.lat,
      long: latLong?.long,
    };
    setIsLoading(true);
    try {
      const response: any =
        type == update ? await PUT(url, obj) : await POST(url, obj);
      if (response?.status) {
        Notifications["success"]({
          message: "Success",
          description: `Successfully ${
            type == update ? "updated" : "Added"
          } the Address`,
        });
        form.resetFields();
        props?.modalClose();
        props?.fetchAddress();
      } else {
        Notifications["error"]({
          message: `Failed to ${type == update ? "Update" : "Add New Address"}`,
          description: response.message,
        });
      }
    } catch (err: any) {
      Notifications["error"]({
        message: `Failed to ${type == update ? "Update" : "Add New Address"}`,
        description: err.message,
      });
    }
    setIsLoading(false);
  };
  //========================================================================= getcurrentlocation api
  function handleLocationClick() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(handleLocation, handleError);
    } else {
      Notifications.error({ message: `GeoLocation not supported` });
    }
  }
  const handleLocation = (position: any) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    getCurrentLocation(latitude, longitude);
  };
  const handleError = (error: any) => {
    Notifications.error({
      message: `Unable to get your Location. reason:${error.message}`,
    });
  };
  const getCurrentLocation = async (lat: number, long: number) => {
    setLatLong({ lat: lat, long: long });
    setLocationLoading(true);
    try {
      const locationInfo: any = await CURRENT_LOCATION(lat, long);
      if (locationInfo.status) {
        const locationData: currentLoctionType = locationInfo.data;
        const countryCode = Country.find(
          (item) => item.name === locationData?.country
        );
        form.setFieldsValue({
          code: countryCode?.dial_code,
          pincode: locationData?.postal_code,
          state: locationData?.state,
          street: locationData?.subLocality,
          city: locationData?.taluk,
          address: locationData.street_address ?? locationData.premise,
          location: form.getFieldValue("location") ?? locationData?.subLocality,
        });
        Notifications.success({
          message: `Address set to Your Current Location`,
        });
      }
    } catch (err) {
      Notifications.error({ message: `Unable to get your Location.` });
    } finally {
      setLocationLoading(false);
    }
  };
  //===================================================================================locationautoapi
  //=====================================================================autocomplete
  return (
    <Modal
      title={`${type == "add" ? "Add New" : "Edit"} Address `}
      open={props?.open}
      onCancel={() => {
        props?.modalClose();
        form.resetFields()
      }}
      okText="Update"
      centered
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
    >
      {contextHolder}
      <Form
        form={form}
        style={{ maxWidth: 600 }}
        layout="vertical"
        onFinish={formSubmitHandler}
        initialValues={{ code: "+91" }}
      >
        <Form.Item
          label="Type"
          name={"type"}
          rules={[
            {
              required: true,
              message: "Please Select a type of address",
            },
          ]}
        >
          <Select>
            <Select.Option key="home" value="home">
              Home
            </Select.Option>
            <Select.Option key="business" value="business">
              Business
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Flat"
          name={"flat"}
          rules={[
            {
              required: true,
              message: "Please Enter Flat Number",
            },
          ]}
        >
          <Input placeholder="Flat" />
        </Form.Item>
        <Form.Item
          label="Address"
          name="address"
          rules={[
            {
              required: true,
              message: "Please Enter Address",
            },
          ]}
        >
          <TextArea rows={3} placeholder="Address" maxLength={200} />
        </Form.Item>
        <AutoCompleteLocation
          label={false}
          setCurrentLocation={(value: any) => {
            form.setFieldsValue({
              code: value?.code,
              pincode: value?.pincode,
              state: value?.state,
              street: value?.street,
              city: value?.city??value?.location,
              address: value?.address,
              location: form.getFieldValue("location") ?? value?.street,
            });
          }}
        />
        <Row>
          <Col md="6">
            <Form.Item
              label="PinCode"
              name="pincode"
              rules={[
                {
                  required: true,
                  message: "Please Enter PinCode",
                },
              ]}
            >
              <Input placeholder="Pincode" />
            </Form.Item>
          </Col>
          <Col md="6">
            <Form.Item
              label="State"
              name="state"
              rules={[
                {
                  required: true,
                  message: "Please Enter State",
                },
              ]}
            >
              <Input placeholder="State" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Street"
          name="street"
          rules={[
            {
              required: true,
              message: "Please Enter Street",
            },
          ]}
        >
          <Input placeholder="Street" />
        </Form.Item>
        <Form.Item
          label="City"
          name="city"
          rules={[
            {
              required: true,
              message: "Please Enter City",
            },
          ]}
        >
          <Input placeholder="City" />
        </Form.Item>
        <Form.Item
          label="Alternate Phone"
          name="phone"
          rules={[
            {
              required: true,
              message: "Please Enter Phone Number",
            },
          ]}
        >
          <Input
            addonBefore={<PrefixSelector />}
            style={{ width: "100%" }}
            size="large"
            type="number"
            placeholder="Phone Number"
          />
        </Form.Item>
        <div className="d-flex gap-2 justify-content-end">
          <Button onClick={props?.modalClose}>Cancel</Button>
          <Button type="primary" loading={isLoading} onClick={form.submit}>
            {type == update ? "Update" : "Add"}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
export default AddressModal;